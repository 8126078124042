import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-appsettings',
  templateUrl: './appsettings.component.html',
  styleUrls: ['./appsettings.component.css']
})
export class AppsettingsComponent implements OnInit {
  appsettingsform: FormGroup
  constructor(private fb: FormBuilder) {

  }

  ngOnInit() {
    this.createForm();
  }
  createForm() {
    this.appsettingsform = this.fb.group({
      exposeAuthor: [''],
      showMeOnline: [''],
      turnOffNotification: [''],
      reportControl: [''],
      allowMail: ['']
    });
  }
}
