import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AccountService } from '../service/account.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../service/global.service';
import { FilterserviceService } from '../service/filterservice.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isLoginError: boolean = false;
  returnUrl: string;
  test: any;
  ErrorMessage: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private toastr: ToastrService,
    private globalService: GlobalService,
    private filterService: FilterserviceService
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit(username, password) {
    if (this.loginForm.invalid) {
      return;
    }
    this.accountService.userAuthentication(username, password).subscribe((data: any) => {
      //this.globalService.globalUnitId = "";
      //this.globalService.globalRegionId = "";
      localStorage.setItem('userToken', data.access_token);
      this.router.navigateByUrl('/dashboard');
      this.filterService.clearFilters();
      this.filterService.clearDSNFilters();
    },
      (err: HttpErrorResponse) => {
        
        if (err.status == 400) {
          this.ErrorMessage = 'Invalid Username/Password.';
        }
        else if (err.status == 500) {
          this.ErrorMessage = 'User has been deactivated.';
        }
        else {
          this.ErrorMessage = 'Error Occured.';
        }
        this.isLoginError = true;
       
      });
  }

  //, { fragment: '' }
}
