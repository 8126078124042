import { Component, OnInit } from '@angular/core';
import { MenuService } from '../service/menu.service';
import { GlobalService } from '../service/global.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountService } from '../service/account.service';
import { CurrencyService } from '../service/currency.service';
import { ToastrService } from 'ngx-toastr';
declare var $;
@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.css']
})
export class UnitComponent implements OnInit {
  model: any = {};
  subjectarea: any = {};
  userRoles: any;
  showunit: boolean = true;
  showunitalert: boolean = false;
  regionsList: any;
  unitsList: any;
  buttonName: string = 'Save';
  UnitNumberDisable: boolean = false;
  unitSubjectAreaList: any = [];
  AssociateSubjectAreasList: any = [];
  AvailableSubjectAreasList: any = [];
  leftArrow: boolean = true;
  rightArrow: boolean = true;
  constructor(
    private menuService: MenuService,
    private globalService: GlobalService,
    private accountService: AccountService,
    private currencyService: CurrencyService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.menuService.HideUnitMenu(true);
    this.menuService.HideChart(false);
    this.menuService.ShowRegionMenu(true);
    this.globalService.UserClaims().subscribe((data: any) => {
      if (data != undefined) {
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globalunitmaintenancepage);
        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {
          this.LoadUnits();
          this.LoadRegions();
          this.ClearForm();
          this.subjectarea = {
            UnitId: '',
          }
        }
        else {
          this.showunit = false;
          this.showunitalert = true;
        }
      }
    });
  }

  ClearForm(): any {
    this.model = {
      UnitNumber: 0,
      Unit_Id: '',
      Unit_Name: '',
      Region_Id: '',
      Shop: 1,
      ShopGoodsInBranch: 1,
      Active: 1
    }
    this.UnitNumberDisable = false;
  }
  UpdateUnits(item): void {
    this.model = {
      UnitNumber: 1,
      Unit_Id: item.Unit_Id,
      Unit_Name: item.Unit_Name,
      Region_Id: item.Region_Id,
      Shop: item.Shop == true ? 1 : 0,
      ShopGoodsInBranch: item.ShopGoodsInBranch == true ? 1 : 0,
      Active: item.Active == true ? 1 : 0
    }
    this.buttonName = 'Update';
    this.UnitNumberDisable = true;
  }
  LoadUnits(): any {
    this.accountService.getAllUnits().subscribe((data: any) => {
      this.unitsList = data;
      var units = data.filter(x => x.Active == true);
      this.unitSubjectAreaList = units;
      this.LoadDataTable();
    },
      (err: HttpErrorResponse) => {

      });
  }
  onSubmit() {
    
    if (this.model.invalid) {
      return;
    }
    
    this.model['Active'] = parseInt(this.model['Active']);
    this.model['Shop'] = parseInt(this.model['Shop']);
    this.model['ShopGoodsInBranch'] = parseInt(this.model['ShopGoodsInBranch']);
    this.currencyService.SaveUnits(this.model).subscribe((data: any) => {
      this.toastr.success(data);
      this.LoadUnits();
      this.buttonName = 'Save';
      this.ClearForm();
      this.currencyService.LoadRegionsDropdown(true);
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  LoadRegions(): any {
    this.accountService.getAllRegions().subscribe((data: any) => {
      
      var regions = data.filter(x => x.Active == true);
      this.regionsList = regions;

    },
      (err: HttpErrorResponse) => {

      });
  }
  ChangeUnitForSubjectAreas(item): void {
    if (item != '') {
      this.leftArrow = false;
      this.rightArrow = false;
      this.LoadAvailableSubjectAreasList(item);
      this.LoadAssociateSubjectAreasList(item);
    }
  }
  LoadAvailableSubjectAreasList(item): void {
    this.currencyService.LoadAvailableSubjectAreasList(item).subscribe((data: any) => {
      this.AvailableSubjectAreasList = data;
    },
      (err: HttpErrorResponse) => {

      });
  }
  LoadAssociateSubjectAreasList(item): void {
    this.currencyService.LoadAssociateSubjectAreasList(item).subscribe((data: any) => {
      this.AssociateSubjectAreasList = data;
    },
      (err: HttpErrorResponse) => {

      });
  }
  AddSubjectArea(): void {
    
    this.currencyService.AddSubjectArea(this.subjectarea).subscribe((data: any) => {
      this.toastr.success(data);
      this.LoadAvailableSubjectAreasList(this.subjectarea.UnitId);
      this.LoadAssociateSubjectAreasList(this.subjectarea.UnitId);
      this.subjectarea = {
        UnitId: this.subjectarea.UnitId,
        AvailableSubjectAreas: '',
        AssociateSubjectAreas: ''
      }
    },
      (err: HttpErrorResponse) => {

      });
  }
  RemoveSubjectArea(): void {
    
    this.currencyService.RemoveSubjectArea(this.subjectarea).subscribe((data: any) => {
      this.toastr.success(data);
      this.LoadAvailableSubjectAreasList(this.subjectarea.UnitId);
      this.LoadAssociateSubjectAreasList(this.subjectarea.UnitId);
      this.subjectarea = {
        UnitId: this.subjectarea.UnitId,
        AvailableSubjectAreas: '',
        AssociateSubjectAreas: ''
      }
    },
      (err: HttpErrorResponse) => {

      });
  }
  LoadDataTable(): void {
    $("#datatable").dataTable().fnDestroy();
    setTimeout(function () {
      $('#datatable').DataTable({
        'paging': true,
        'lengthChange': true,
        'searching': true,
        'ordering': true,
        'info': true,
        'autoWidth': false,
        'bRetrieve': true
      })
    }, 0);
  }
}
