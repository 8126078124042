import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../service/user.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, Validators, FormBuilder, NgForm } from '@angular/forms';
import { GlobalService } from '../service/global.service';
import { MenuService } from '../service/menu.service';
declare var $;
@Component({
  selector: 'app-usergroups',
  templateUrl: './usergroups.component.html',
  styleUrls: ['./usergroups.component.css']
})
export class UsergroupsComponent implements OnInit {
  model: any = {};
  availableUserGroups: any;
  buttonName: string = 'Save';
  User_Grp_Id: number = 0;
  roleWrite: boolean;
  roleUpdate: boolean;
  roleDelete: boolean;
  pageName: string = 'User Groups';
  userRoles: any;
  showusergroup: boolean = true;
  showusergroupalert: boolean = false;
  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private globalService: GlobalService,
    private menuService: MenuService) { }

  ngOnInit() {
    this.menuService.HideUnitMenu(true);
    this.menuService.HideChart(false);
    this.menuService.ShowRegionMenu(true);
    this.globalService.UserClaims().subscribe((data: any) => {
      if (data != undefined) {
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globalusergroupspage);
        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {
          if (this.userRoles != '') {
            this.roleWrite = this.userRoles.Write;
            this.roleUpdate = this.userRoles.Update;
            this.roleDelete = this.userRoles.Delete;
          }
        }
        else {
          this.showusergroup = false;
          this.showusergroupalert = true;
        }
      }
     
    });

    this.LoadUserGroups();

  }
  onSubmit(): any {

    this.userService.SaveUserGroups(this.model).subscribe((data: any) => {
      this.LoadUserGroups();
      this.toastr.success(data);
      this.ClearFields();
      this.buttonName = 'Save';
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });



  }
  LoadUserGroups(): any {
    this.userService.getAvailableGroups().subscribe((data: any) => {
      this.availableUserGroups = [];
      this.availableUserGroups = data;
      this.LoadDataTable();
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  LoadDataTable(): void {

    $("#datatable").dataTable().fnDestroy();
    setTimeout(function () {
      $('#datatable').DataTable({
        'paging': true,
        'lengthChange': true,
        'searching': true,
        'ordering': true,
        'info': true,
        'autoWidth': false,
        'bRetrieve': true
      })
    }, 0);
  }
  UpdateUserGroups(items, event, i): void {
    this.model = {
      User_Grp_Id: items.User_Grp_Id,
      User_Grp_Name: items.User_Grp_Name,
      User_Grp_Description: items.User_Grp_Description,
      User_Grp_Hierarchy: items.User_Grp_Hierarchy
    };
    this.buttonName = 'Update';
  }
  ClearFields(): void {
    this.model = {
      User_Grp_Id: 0,
      User_Grp_Name: '',
      User_Grp_Description: '',
      User_Grp_Hierarchy: ''
    };
    this.buttonName = 'Save';
  }
}
