import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../service/global.service';
import { MenuService } from '../service/menu.service';
import { ReportService } from '../service/report.service';
import { EmployeeService } from '../service/employee.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PurchaseregisterService } from '../service/purchaseregister.service';
import { PoRegistrationModel, PoShopModel } from '../model/po-registration.model';
import { ColDef, GridApi, GridOptions, GridSizeChangedEvent } from 'ag-grid-community';
import { PoUserLockedCheckboxComponent } from '../components/po-user-locked-checkbox/po-user-locked-checkbox.component';
import { FilterserviceService } from '../service/filterservice.service';
import { filter } from 'underscore';
import { concat } from 'rxjs';

@Component({
  selector: 'app-poregistration-report-withcontact',
  templateUrl: './poregistration-report-withcontact.component.html',
  styleUrls: ['./poregistration-report-withcontact.component.css']
})
export class PoregistrationReportWithcontactComponent implements OnInit {
  userRoles: any;
  supplierList: any = [];
  statisticalPeriodList: any = [];
  goodsInBranchList: any = [];
  showporeportview: boolean = true;
  showporeportviewalert: boolean = false;

  report: any = [];
  FromDate = new Date();
  ToDate = new Date();
  FilterFromDate: string;
  FilterToDate: string;

  orderTypeList: any = [];
  newFilters: any = {};

  shopList = [];
  userList: any = [];
  selectedShops = [];
  shopDropdownSettings = {
    singleSelection: false,
    primaryKey: 'ShopId',
    labelKey: 'ShopName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    searchPlaceholderText: 'Search'
  };

  // ag-grid Settings
  private gridApi !: GridApi;
  private gridColumnApi;
  public quickFilterText: string = '';
  purchaseOrder: PoRegistrationModel[];
  purchaseOrderShops: PoShopModel[] = [];
  gridOptions: GridOptions;
  rowData!: any[];
  columnDefs = [
    { field: "OrderIndex", headerName: "OrderIndex", sort: 'desc', hide: true },
    { field: "OrderNo", headerName: "OrderNr" },
    { field: "InternalPONumber", headerName: "Internal PO" },
    { field: "BrandName", headerName: "BrandName" },
    { field: "SeasonId", headerName: "SeasonId" },
    { field: "BranchName", headerName: "BranchName" },
    { field: "OrderTypeName", headerName: "OrderTypeName" },
    { field: "ContactName", headerName: "ContactName" },
    { field: "ContactEmail", headerName: "ContactEmail" },
    { field: "PhoneNumber", headerName: "Contact PhoneNumber" },    
    { field: "Incoterms", headerName: "Incoterms" },
    { field: "UserName", headerName: "OrderLockedBy" },
    { field: "DeliveryFromDate", headerName: "DeliveryFromDate" },
    { field: "DeliveryToDate", headerName: "DeliveryToDate" },
    { field: "SKU", headerName: "TotalSKUs" },
    { field: "Currency", headerName: "Currency", },
    { field: "TotalValue", headerName: "NetValue" },
    { field: "TotalPCS", headerName: "PCS" },
    { field: "Comment", headerName: "Comment" },
    { field: "OrderCreatedDate", headerName: "CreatedDate" },
   // { field: "OrderUpdatedDate", headername: "Updated Date" },
    {
      headerName: "FileName1",
      cellRenderer: function (params) {
        if (params.data.FileName1) {
          return '<a style="font-size:x-small" href="' + params.data.FilePath +encodeURIComponent(params.data.FileName1) + '"  target="_blank">' +
            params.data.FileName1 + '</a>';
        }
        else {
          return '';
        }
      }
    },
    {
      headerName: "FileName2",
      cellRenderer: function (params) {
        if (params.data.FileName2) {
          return '<a style="font-size:x-small" href="' + params.data.FilePath + encodeURIComponent(params.data.FileName2) + '" target="_blank">' +
            params.data.FileName2 + '</a>';
        }
        else {
          return '';
        }
      }
    },
    {
      headerName: "FileName3",
      cellRenderer: function (params) {
        if (params.data.FileName3) {
          return '<a style="font-size:x-small" href="' + params.data.FilePath + encodeURIComponent(params.data.FileName3) + '" target="_blank">' +
            params.data.FileName3 + '</a>';
        }
        else {
          return '';
        }
      }
    },
    {
      headerName: "FileName4",
      cellRenderer: function (params) {
        if (params.data.FileName4) {
          return '<a style="font-size:x-small" href="' + params.data.FilePath + encodeURIComponent(params.data.FileName4) + '" target="_blank">' +
            params.data.FileName4 + '</a>';
        }
        else {
          return '';
        }
      }
    },
    {
      headerName: "FileName5",
      cellRenderer: function (params) {
        if (params.data.FileName5) {
          return '<a style="font-size:x-small" href="' + params.data.FilePath + encodeURIComponent(params.data.FileName5) + '" target="_blank">' +
            params.data.FileName5 + '</a>';
        }
        else {
          return '';
        }
      }
    },
    //{
    //  headerName: "HasFile", valueGetter: function (params) {
    //    if (params.data.FilePath) {
    //      return "Yes"
    //    } else {
    //      return "No";
    //    }

    //  }
    //}
  ];
  defaultColDef: ColDef = {
    // set every column width
    // width: 100,
    minWidth: 80,
    maxWidth: 150,
    editable: false,
    sortable: true,
    filter: true,
    resizable: true,
    tooltipValueGetter: (params) => {
      return params.value;
    }
  };

  frameworkComponents = {
    PoUserLockedCheckboxComponent: PoUserLockedCheckboxComponent
  };

  PoReportRowDoubleClicked = (row): void => {
    console.log('row is being edited', { row })
    this.router.navigate(['poregistrationwithcontact', { id: row.data.OrderIndex }], { relativeTo: this.route.parent });
  };

  constructor(
    private toastr: ToastrService,
    private globalService: GlobalService,
    private menuService: MenuService,
    private localeService: BsLocaleService,
    private reportService: ReportService,
    private employeeService: EmployeeService,
    private purchaseregisterservice: PurchaseregisterService,
    private route: ActivatedRoute,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private filterService: FilterserviceService
  ) { }

  ngOnInit() {
    this.menuService.HideUnitMenu(false);
    this.menuService.HideChart(false);
    this.menuService.ShowRegionMenu(false);

    // Get filters when component initializes
    debugger;
    const filters = this.filterService.getDSNFilters();

    this.globalService.getUserInfo().subscribe((data: any) => {
      if (data != undefined) {
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globalporeportpage);

        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {

          //this.FromDate.setMonth(new Date().getMonth() - 3);
          this.FromDate = new Date(2024, 0, 1);
          this.ToDate.setMonth(new Date().getMonth() + 1);
          this.report.FromDate = this.FromDate

          this.report.ToDate = this.ToDate;
          this.FilterFromDate = this.ConvertDateTime(this.report.FromDate.toString());
          this.FilterToDate = this.ConvertDateTime(this.report.ToDate.toString());

          this.LoadSupplier();
          this.LoadStatisticalPeriod();
          this.LoadGoodsInBranch(null);
          this.LoadUserNames();
          this.LoadOrderType();

          if (Object.keys(filters).length < 1) {
            this.report.BrandId = "";
            this.report.SeasonId = "";
            this.report.BranchId = "";
            this.report.UserId = "";
            this.report.UserLocked = "";
            this.report.InternalPONumber = "";
            this.report.OrderTypeId = "";
            this.LoadPurchaseOrders(this.FilterFromDate, this.FilterToDate, null, null, null, null, null, null, null, null);
          }
          else {
            this.report.FromDate = filters.filterFromDate;
            this.report.ToDate = filters.filterToDate;
            this.FilterFromDate = filters.filterFromDate;
            this.FilterToDate = filters.filterToDate;
            this.report.BrandId = filters.brandId;
            this.report.SeasonId = filters.seasonId;
            this.report.BranchId = filters.branchId;
            this.report.UserId = filters.userId;
            this.report.UserLocked = filters.userLocked;
            this.report.InternalPONumber = filters.internalPONumber;
            this.report.OrderTypeId = filters.orderTypeId;

            this.LoadPurchaseOrders(this.FilterFromDate, this.FilterToDate, this.report.BrandId, this.report.SeasonId, this.report.BranchId, null, this.report.UserId, this.report.UserLocked, this.report.InternalPONumber, this.report.OrderTypeId);
          }         
          //this.LoadShop(null);      


        }
        else {
          this.showporeportview = false;
          this.showporeportviewalert = true;
        }
      }
    });
    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      defaultColDef: this.defaultColDef,
      rowSelection: 'single',
      //enableCellNavigation: true,
      columnTypes: {
        numberColumn: {
          width: 83,
          filter: "agNumberColumnFilter"
        },
        nonEditableColumn: { editable: false },
      },
      context: { componentParent: this },
      suppressScrollOnNewData: true,
      onRowDoubleClicked: this.PoReportRowDoubleClicked,

    }
  }

 


  onGridReady(params) {
    this.gridApi = params.api;
  }
  //this make grid responsive to div size
  onGridSizeChanged(params: GridSizeChangedEvent) {

  }

  

  ClearSearchFields(): void {
    this.report = {
      FromDate: this.FromDate,
      ToDate: this.ToDate,
      checked: false,
      BrandId: "",
      SeasonId: "",
      BranchId: "",
      UserId: "",
      UserLocked: "",
      InternalPONumber: "",
      OrderTypeId:""

    };
    this.selectedShops = [];
    this.shopList = [];
    this.filterService.clearDSNFilters();
  }

  OnChangeRemoveDateFilter(): void {

    if (this.report.checked == true) {
      this.report.FromDate = "";
      this.report.ToDate = "";
      this.FilterFromDate = "";
      this.FilterToDate = "";
    }
    else {
      this.report.FromDate = this.FromDate;
      this.report.ToDate = this.ToDate
      this.FilterFromDate = this.ConvertDateTime(this.report.FromDate.toString());
      this.FilterToDate = this.ConvertDateTime(this.report.ToDate.toString());
    }
  }

  ConvertDateTime(str) {
    var mnths = {
      Jan: "01", Feb: "02", Mar: "03", Apr: "04", May: "05", Jun: "06",
      Jul: "07", Aug: "08", Sep: "09", Oct: "10", Nov: "11", Dec: "12"
    },
      date = str.split(" ");
    return [date[3], mnths[date[1]], date[2]].join("-");
  }

  ChangeFromDate(fromdate): void {
    debugger;
    if (fromdate != null) {
      this.report.FromDate = fromdate;
      this.FilterFromDate = this.ConvertDateTime(fromdate.toString());
    }
  }
  ChangeToDate(todate): void {
    if (todate != null) {
      this.report.ToDate = todate;
      this.FilterToDate = this.ConvertDateTime(todate.toString());
    }
  }

  ChangeUser(event): void {
    debugger;
    this.report.UserId = event.target.value;
  }

  ChangeUserLocked(event): void {
    debugger;
    this.report.UserLocked = event.target.value;
  }

  ChangeInternalPONumber(event): void {
    debugger;
    this.report.InternalPONumber = event.target.value;
  }

  LoadUserNames(): any {
    this.purchaseregisterservice.getUserNames().subscribe((data: any) => {
      debugger;
      this.userList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }

  LoadSupplier(): any {
    this.purchaseregisterservice.getSupplier().subscribe((data: any) => {
      this.supplierList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadStatisticalPeriod(): any {
    this.purchaseregisterservice.getStatisticalPeriod().subscribe((data: any) => {
      this.statisticalPeriodList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  LoadGoodsInBranch(regionId): any {
    this.purchaseregisterservice.getGoodsInBranch(regionId).subscribe((data: any) => {
      debugger;
      this.goodsInBranchList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  LoadShop(branchId): any {
    this.purchaseregisterservice.GetUnitShop(branchId).subscribe((data: any) => {
      this.shopList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  LoadPurchaseOrders(deliveryFromDate, deliveryToDate, brandId, seasonId, branchId, shopSelected, userId, userLocked, internalPONumber,orderTypeId): any {
    this.ngxService.start();
    var searchPurchaseOrders = {
      OrderIndex: "",
      DeliveryFromDate: deliveryFromDate,
      DeliveryToDate: deliveryToDate,
      BrandId: brandId,
      SeasonId: seasonId,
      BranchId: branchId,
      ShopNo: shopSelected,
      UserId: userId,
      UserLocked: userLocked,
      InternalPONumber: internalPONumber,
      OrderTypeId: orderTypeId

    };
    this.reportService.GetReportPurchaseOrders(searchPurchaseOrders).subscribe((data: any) => {
      debugger;
      this.purchaseOrder = [];
      this.purchaseOrderShops = [];
      this.purchaseOrder = data.map(d => {
        return {
          OrderIndex: d.OrderIndex,
          OrderNo: d.OrderNo,
          BrandId: d.BrandId,
          BrandName: d.BrandName,
          SeasonId: d.SeasonId,
          POSupplierId:d.POSupplierId,
          POSupplierContactId: d.POSupplierContactId,
          ContactName: d.ContactName,
          PhoneNumber: d.PhoneNumber,
          OrderTypeName:d.OrderTypeName,
          ContactEmail: d.ContactEmail,
          BranchId: d.BranchId,
          BranchName: d.BranchName,
          SKU: d.SKU,
          InternalPONumber: d.InternalPONumber,
          Currency: d.Currency,
          TotalPCS: d.TotalPCS,
          TotalValue: d.TotalValue,
          // UnitNo: d.UnitNo,
          Incoterms: d.Incoterms,
          DeliveryFromDate: d.DeliveryFromDate,
          DeliveryToDate: d.DeliveryToDate,
          OrderDiscount: d.OrderDiscount,
          PaymentTerm: d.PaymentTerm,
          Comment: d.Comment,
          FilePath: d.FilePath,
          FileName1: d.FileName1,
          FileName2: d.FileName2,
          FileName3: d.FileName3,
          FileName4: d.FileName4,
          FileName5: d.FileName5,
          IsUserLocked: d.IsUserLocked,
          OrderCreatedDate: d.OrderCreatedDate,
          OrderUpdatedDate: d.OrderUpdatedDate,
          CreatedBy: d.CreatedBY,
          UpdatedBy: d.UpdatedBY,
          UserName: d.UserName
        }
      });
      //if (this.purchaseOrder.length > 0) {
      //  this.register.SeasonId = Math.max.apply(null, this.purchaseOrder.map(function (o) { return o.SeasonId; }));  // to set default for stat peiod on page load
      //  this.register.BrandId = Math.max.apply(null, this.purchaseOrder.map(function (o) { return o.BrandId; }));  // to set default for stat peiod on page load
      //}
      this.purchaseOrder = Object.values(this.purchaseOrder.reduce((acc, obj) => ({ ...acc, [obj.OrderIndex]: obj }), {}));
      this.rowData = this.purchaseOrder;

      /* Map Purchase Order Shops*/
      data.map(val => {
        val.PurchaseOrderShops.map(d => {
          if (d != null) {
            let poShop: PoShopModel = {
              ShopNo: d.ShopNo,
              OrderIndex: d.OrderIndex
            }
            if (!this.purchaseOrderShops.some(e => e.OrderIndex === poShop.OrderIndex && e.ShopNo === poShop.ShopNo)) {
              this.purchaseOrderShops.push(poShop);
            }
          }

        });
      });
      this.ngxService.stop();
    },
      error => {
        this.ngxService.stop();
        this.toastr.error('Some Error on Fetching Data!', 'Error');
      });

  }

  onBrandChange(event) {
    this.report.BrandId = event.target.value;
  }
  onStatChange(event) {
    this.report.SeasonId = event.target.value;
  }

  OnGoodsInBranchChanges(event): any {
    this.report.BranchId = event.target.value;
    this.LoadShop(event.target.value);
  }

  LoadOrderType(): any {
    this.purchaseregisterservice.getOrderType().subscribe((data: any) => {
      this.orderTypeList = [];
      this.orderTypeList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  OnChangeOrderType(selectedvalue): any {
    this.report.OrderTypeId = selectedvalue;
  }

  onSubmit(form: NgForm): any {
    debugger;
    var shopSelected = this.selectedShops.map(function (e) {
      return e.ShopId;
    }).join(', ');
    //apply filters
    this.newFilters = {
      filterFromDate: this.FilterFromDate,
      filterToDate: this.FilterToDate,
      brandId: this.report.BrandId,
      seasonId: this.report.SeasonId,
      branchId: this.report.BranchId,
      userId: this.report.UserId,
      userLocked: this.report.UserLocked,
      internalPONumber: this.report.InternalPONumber,
      orderTypeId:this.report.OrderTypeId
      
    };
    // Save filters
    this.filterService.setDSNFilters(this.newFilters);

    this.LoadPurchaseOrders(this.FilterFromDate, this.FilterToDate, this.report.BrandId, this.report.SeasonId, this.report.BranchId, shopSelected, this.report.UserId, this.report.UserLocked, this.report.InternalPONumber,this.report.OrderTypeId);
  }
}
