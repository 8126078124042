import { Component, OnInit, ViewChild } from '@angular/core';
import { PurchaseregisterService } from '../service/purchaseregister.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ReportService } from '../service/report.service';
import { GlobalService } from '../service/global.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import * as _ from 'underscore';
import { MenuService } from '../service/menu.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
declare var $;
declare var bootbox: any;
@Component({
  selector: 'app-purchasedit',
  templateUrl: './purchasedit.component.html',
  styleUrls: ['./purchasedit.component.css']
})
export class PurchaseditComponent implements OnInit {
  bsValue = new Date();
  NetValue: string = "";
  DeliveryFromTo: Date[];
  maxDate = new Date();
  register: any = {};
  report: any = {};
  buyers: any = {};
  contacts: any = {};
  regionId: number;
  unitId: number;
  FilePath: string;
  FileName1: string;
  FileName2: string;
  FileName3: string;
  FileName4: string;
  FileName5: string;
  ShowFileName1: boolean = true;
  ShowFileName2: boolean = true;
  ShowFileName3: boolean = true;
  ShowFileName4: boolean = true;
  ShowFileName5: boolean = true;
  FromDate = new Date();
  CurrentDate = new Date();
  ConvertedFromDate: string;
  ConvertedToDate: string;
  buyerList: any;
  reportList: any;
  orderTypeList: any = [];
  statisticalPeriodList: any = [];
  supplierList: any = [];
  productgroupList: any = [];
  incotermList: any = [];
  currencyList: any = [];
  countryDeliveryList = [];
  goodsInBranchList: any = [];
  shopList: any = [];
  subordertypeList = [];
  contactList: any = [];
  posupplierList: any = [];
  FilterFromDate: string;
  FilterToDate: string;
  createpurchaseregister: boolean = false;
  filterAttribute: boolean = true;
  createbutton: boolean = true;
  btnsubmit: string = "Update";
  selectedFileNames: string[] = [];
  @ViewChild('fileUpload') fileUploadVar: any;
  uploadResult: any;
  res: Array<string>;
  usergroupname: string = "User Wise Purchase";
  userFilter: boolean = true;
  filesToUpload: Array<File>;
  reportBuyerList: any[];
  reportOrderTypeList: any[];
  reportStatisticalPeriodList: any[];
  reportPOsupplierList: any[];
  reportContactList: any[];
  reportSubordertypeList: any[];
  reportCurrencyList: any[];
  reportIncotermList: any[];
  reportGoodsInBranchList: any[];
  reportShopList: any[];
  reportSupplierList: any[];
  reportUserList: any[];
  reportTempBuyerList: any[];
  reportTempOrderTypeList: any[];
  reportTempStatisticalPeriodList: any[];
  reportTempPOsupplierList: any[];
  reportTempContactList: any[];
  reportTempSubordertypeList: any[];
  reportTempCurrencyList: any[];
  reportTempIncotermList: any[];
  reportTempGoodsInBranchList: any[];
  reportTempShopList: any[];
  reportTempSupplierList: any[];
  reportTempUserList: any[];
  shopTempList = [];
  contactTempList = [];
  showpurchaseedit: boolean = true;
  showpurchaseeditalert: boolean = false;
  userRoles: any;
  filteredContact = [];
  isChecked: boolean;
  valueLabel: string = "Gross Value";
  buyerSortedList = [];
  shopSortedList = [];
  currencySortedList = [];
  incotermSortedList = [];
  statperiodSortedList = [];
  userSortedList = [];
  posupplierSortedList = [];
  supplierSortedList = [];
  goodsInBranchSortedList = [];
  DisableFromDate: boolean = false;
  DisableToDate: boolean = false;
  RemoveDateInfo: string;
  FilterCount: number = 0;
  tempBuyerId: string;
  tempOrderTypeId: string;
  tempStatPeriodId: string;
  tempPOSupplierId: string;
  tempContactId: string;
  tempSubOrderTypeId: string;
  tempCountryId: string;
  tempCurrencyId: string;
  tempGoodsInBranchId: string;
  tempShopId: string;
  tempIncoTermId: string;
  tempSupplierId: string;
  tempUserId: string;
  tempCurrencyValueId: string;
  tempIncotermId: string;
  tempStatperiodId: string;
  constructor(
    private purchaseregisterservice: PurchaseregisterService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private reportService: ReportService,
    private globalService: GlobalService,
    private menuService: MenuService,
    private localeService: BsLocaleService
  ) {
    this.filesToUpload = [];
    defineLocale('en-gb', enGbLocale);
    this.localeService.use('en-gb');
    this.FilterCount = this.globalService.globalFilterCount;
  }

  ngOnInit() {

    this.menuService.HideChart(false);
    this.menuService.HideUnitMenu(true);
    this.menuService.ShowRegionMenu(true);
    this.purchaseregisterservice.regionchangesubject.subscribe((data: any) => {
      this.globalService.globalRegionId = data;
      this.regionId = data;
      this.report['RegionId'] = this.regionId;
      this.LoadGoodsInBranch(data);
      this.report['UnitId'] = "";
      this.LoadPurchaseReport(this.report);
    });
    this.reportService.unitChangeOnReportSubject.subscribe((data: any) => {
      this.globalService.globalUnitId = data;
      this.unitId = data;
      this.report['UnitId'] = this.unitId;
      this.LoadPurchaseReport(this.report);
    });
    this.globalService.getUserInfo().subscribe((data: any) => {

      if (data != undefined) {
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globalpurchaseeditpage);
        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {
          var status = data.Roles.find(x => x.User_Grp_Name == this.usergroupname);
          if (status != null) {
            this.userFilter = false;
          }

          //region management

          if (this.globalService.globalRegionId != undefined) {
            this.regionId = parseInt(this.globalService.globalRegionId);
          }
          else {
            this.regionId = data.Region_Id;
          }

          //unit management
          if (this.globalService.globalUnitId != undefined) {
            this.unitId = parseInt(this.globalService.globalUnitId);
            this.report['UnitId'] = this.unitId;
          }
          else {
            this.unitId = data.Unit_Id;
          }
          this.report['RegionId'] = this.regionId;
          this.LoadBuyer();
          this.LoadOrderType();
          this.LoadSupplier();
          this.LoadPoSupplier();
          this.LoadProductGroup();
          this.LoadCurrency();
          this.LoadStatisticalPeriod();
          this.LoadSubOrderType();
          this.LoadIncoterm();
          this.LoadCountryOfDelivery();
          this.ClearRegisterFields();
          this.ClearBuyersFields();
          this.ClearContactsFields();
          this.LoadGoodsInBranch(this.regionId);
          this.LoadPurchaseReport(this.report);
          this.LoadFilterBuyer();
          this.LoadFilterOrderType();
          this.LoadFilterStatisticalPeriod();
          this.LoadFilterPoSupplier();
          this.LoadFilterSubOrderType();
          this.LoadFilterCurrency();
          this.LoadFilterIncoterm();
          this.LoadFilterGoodsInBranch(this.regionId);
          this.LoadFilterSupplier();
          this.LoadFilterUserNames();
        }
        else {
          this.showpurchaseedit = false;
          this.showpurchaseeditalert = true;
        }
      }
    });
    this.FromDate.setDate(this.CurrentDate.getDate() - 90);
    this.ClearFields();
    $('#DeliveryFromTo').daterangepicker();
  }

  ClearFields(): void {
    
    this.ManageGlobalVariablesForFilter();
    this.report = {
      FromDate: this.globalService.globalFilterFromDate,
      ToDate: this.globalService.globalFilterToDate,
      BuyerId: this.globalService.globalFilterBuyerId,
      OrderTypeId: this.globalService.globalFilterOrderTypeId,
      StatPeriodId: this.globalService.globalFilterStatPeriodId,
      POSupplierId: this.globalService.globalFilterPOSupplierId,
      ContactId: this.globalService.globalFilterContactId,
      RegionId: this.regionId,
      UnitId: this.unitId,
      SubOrderTypeId: this.globalService.globalFilterSubOrderTypeId,
      CountryId: "",
      CurrencyId: this.globalService.globalFilterCurrencyId,
      GoodsInBranchId: this.globalService.globalFilterGoodsInBranchId,
      ShopId: this.globalService.globalFilterShopId,
      IncoTermId: this.globalService.globalFilterIncoTermId,
      SupplierId: this.globalService.globalFilterSupplierId,
      AlterationNecessary: "",
      OrderConfirmation: "",
      UserId: this.globalService.globalFilterUserId,
      CurrencyValueId: "",
      InternalPONo: "",
      checked: false
    }
    if (this.globalService.globalFilterContactId != "") {
      this.LoadContactForReport(this.globalService.globalFilterPOSupplierId);
    }
    if (this.globalService.globalFilterShopId != "") {
      this.LoadFilterShop(this.globalService.globalFilterGoodsInBranchId);
    }
    if (this.globalService.globalFilterRemoveFilterChecked == true) {
      this.RemoveDateInfo = "If no other filters are selected loadingtime may increase.";
      this.report['FromDate'] = "";
      this.report['ToDate'] = "";
      this.DisableFromDate = true;
      this.DisableToDate = true;
      this.FilterFromDate = "";
      this.FilterToDate = "";
      this.globalService.globalFilterFromDate = "";
      this.globalService.globalFilterToDate = "";
      this.report['checked'] = true;
    }
    else {
      if (this.globalService.globalFilterFromDate == undefined || this.globalService.globalFilterFromDate == "" || this.globalService.globalFilterFromDate == null)
        this.report['FromDate'] = this.FromDate;
      if (this.globalService.globalFilterToDate == undefined || this.globalService.globalFilterToDate == "" || this.globalService.globalFilterToDate == null)
        this.report['ToDate'] = new Date();
    }
  }

  ClearRegisterFields(): void {
    this.register = {
      BuyerId: "",
      OrderIndex: 0,
      OrderTypeId: "",
      GoodsInBranchId: "",
      ShopId: "",
      SupplierId: "",
      POSupplierId: "",
      ContactId: "",
      ProductGroupId: "",
      CurrencyId: "",
      StatPeriodId: "",
      SubOrderTypeId: "",
      IncoTermId: "",
      CountryId: "",
      ContactPhone: "",
      Pieces: "",
      OrderNumber: "",
      AlterationNecessary: 0,
      OrderConfirmation: 0,
      ArticleTemplate: 0,
      DeliveryFromTo: [this.bsValue, this.maxDate],
      DeliveryFrom: new Date(),
      DeliveryTo: new Date(),
      FromDate: this.FromDate,
      ToDate: new Date(),
    };
    this.filesToUpload = [];

    this.NetValue = "";

  }
  ClearBuyersFields(): void {
    this.buyers = {
      BuyerFirstName: "",
      BuyerLastName: "",
    };
  }
  ClearContactsFields(): void {
    this.contacts = {
      ContactName: "",
      PhoneNumber: "",
      ContactEmail: "",
      ContactPOSupplier: "",
    };
  }

  LoadContactForReport(posupplierid): any {
    
    this.purchaseregisterservice.getContact(posupplierid).subscribe((data: any) => {
      
      this.contactList = [];
      this.reportContactList = data;
      this.report['ContactId'] = this.globalService.globalFilterContactId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  LoadFilterShop(goodsinBranchId?): any {
    
    this.purchaseregisterservice.getShopFilter(this.regionId, goodsinBranchId).subscribe((data: any) => {
      
      this.shopTempList = [];
      for (let item of data) {

        this.shopList = [];
        this.shopList['ShopId'] = item.Unit_Id;
        this.shopList['ShopName'] = item.Unit_Name;
        this.shopTempList.push(this.shopList);
      }
      this.reportShopList = this.shopTempList;
      this.report['ShopId'] = this.globalService.globalFilterShopId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }


  LoadBuyer(): any {
    this.purchaseregisterservice.getBuyer().subscribe((data: any) => {
      this.buyerList = [];
      this.buyerList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadOrderType(): any {
    this.purchaseregisterservice.getOrderType().subscribe((data: any) => {
      this.orderTypeList = [];
      this.orderTypeList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadGoodsInBranch(regionId): any {
    this.purchaseregisterservice.getGoodsInBranch(regionId).subscribe((data: any) => {
      this.goodsInBranchList = [];
      this.goodsInBranchList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadSupplier(): any {
    this.purchaseregisterservice.getSupplier().subscribe((data: any) => {

      this.supplierList = [];
      this.supplierList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadPoSupplier(): any {

    this.purchaseregisterservice.getPOSupplier().subscribe((data: any) => {
      this.posupplierList = [];
      this.posupplierList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadContact(posupplierid): any {

    this.purchaseregisterservice.getContact(posupplierid).subscribe((data: any) => {
      this.contactList = [];
      if (data.length == 0) {
        this.register.ContactId = "";
        this.register.ContactPhone = "";
        this.register.ContactEmail = "";
      }
      else {
        this.contactList = data;
      }

    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadProductGroup(): any {
    this.purchaseregisterservice.getProductGroup().subscribe((data: any) => {
      this.productgroupList = [];
      this.productgroupList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadCurrency(): any {
    this.purchaseregisterservice.getCurrency().subscribe((data: any) => {
      this.currencyList = [];
      this.currencyList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadSubOrderType(): any {
    this.purchaseregisterservice.getSubOrderType().subscribe((data: any) => {
      this.subordertypeList = [];
      this.subordertypeList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadStatisticalPeriod(): any {
    this.purchaseregisterservice.getStatisticalPeriod().subscribe((data: any) => {
      this.statisticalPeriodList = [];
      this.statisticalPeriodList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadIncoterm(): any {
    this.purchaseregisterservice.getIncoTerm().subscribe((data: any) => {
      this.incotermList = [];
      this.incotermList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadCountryOfDelivery(): any {
    this.purchaseregisterservice.getCountryDelivery().subscribe((data: any) => {
      this.countryDeliveryList = [];
      this.countryDeliveryList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  ChangeGoodsInBranchEdit(selectedvalue): any {

    if (selectedvalue.target.value != "")
      this.LoadShopFromDb(this.regionId, selectedvalue.target.value);
    else
      this.shopList = [];
  }

  ChangePOSupplierRegister(selectedvalue): any {

    if (selectedvalue != undefined) {
      if (selectedvalue != "") {
        this.register.ContactId = "";
        this.LoadContact(selectedvalue);
      }
      else
        this.contactList = [];
    }
  }
  ChangePOSupplierReport(selectedvalue): any {
    if (selectedvalue.target.value != "") {
      this.globalService.globalFilterPOSupplierId = selectedvalue.target.value;
      this.LoadContactForReport(selectedvalue.target.value);
      if (this.globalService.globalposupplierinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalposupplierinfo = true;
    }
    else {
      this.globalService.globalFilterPOSupplierId = selectedvalue.target.value;
      this.globalService.globalFilterContactId = "";
      if (this.globalService.globalposupplierinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalposupplierinfo = false;
      this.contactList = [];
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }

  LoadShopForReportFilter(result?): any {

    this.shopList = [];
    this.shopSortedList = [];
    this.shopTempList = _.uniq(result, 'ShopId');
    this.shopSortedList = _.map(this.shopTempList, i => _.pick(i, 'ShopId', 'ShopName')).filter(auction => auction.ShopId != 0);
    this.reportShopList = _.sortBy(this.shopSortedList, 'ShopName');
    this.report['ShopId'] = this.globalService.globalFilterShopId;
  }
  LoadShop(goodsinBranchId?): any {

    this.shopList = [];
    this.shopTempList = _.uniq(this.reportList, 'ShopId');
    this.reportShopList = _.map(this.shopTempList, i => _.pick(i, 'ShopId', 'ShopName', 'GoodsInBranchId')).filter(auction => auction.ShopId != 0).filter(auction => auction.GoodsInBranchId == goodsinBranchId);
  }

  LoadShopFromDb(regionId, goodsInBranchId): any {

    this.purchaseregisterservice.getShop(regionId, goodsInBranchId).subscribe((data: any) => {

      this.shopList = [];
      this.shopList = data;
      this.register.ShopId = "";
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  OnChangeRemoveDateFilter(): void {

    if (this.report['checked'] == true) {
      this.RemoveDateInfo = "If no other filters are selected loadingtime may increase.";
      this.report['FromDate'] = "";
      this.report['ToDate'] = "";
      this.DisableFromDate = true;
      this.DisableToDate = true;
      this.FilterFromDate = "";
      this.FilterToDate = "";
      this.globalService.globalFilterFromDate = "";
      this.globalService.globalFilterToDate = "";
      this.globalService.globalFilterRemoveFilterChecked = true;
    }
    else {
      this.RemoveDateInfo = "";
      this.report['FromDate'] = this.FromDate;
      this.report['ToDate'] = new Date();
      this.DisableFromDate = false;
      this.DisableToDate = false;
      this.FilterFromDate = 'Date From: ' + this.ConvertDateTime(this.report['FromDate'].toString());
      this.FilterToDate = ' to ' + this.ConvertDateTime(this.report['ToDate'].toString());
      this.globalService.globalFilterRemoveFilterChecked = false;
    }
  }

  LoadFilterUserNames(result?): any {
    this.purchaseregisterservice.getUserNames().subscribe((data: any) => {
      this.reportUserList = data;
      this.report['UserId'] = this.globalService.globalFilterUserId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }

  LoadFilterSupplier(): any {
    this.reportService.GetReportFilterSupplier().subscribe((data: any) => {
      this.reportSupplierList = data;
      this.report['SupplierId'] = this.globalService.globalFilterSupplierId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }

  LoadFilterGoodsInBranch(regionId): any {
    this.purchaseregisterservice.getGoodsInBranch(regionId).subscribe((data: any) => {
      
      this.reportTempGoodsInBranchList = [];
      for (let item of data) {
        
        this.goodsInBranchList = [];
        this.goodsInBranchList['GoodsInBranchId'] = item.Unit_Id;
        this.goodsInBranchList['GoodsInBranchName'] = item.Unit_Name;
        this.reportTempGoodsInBranchList.push(this.goodsInBranchList);
        
      }
      this.reportGoodsInBranchList = this.reportTempGoodsInBranchList;
      this.report['GoodsInBranchId'] = this.globalService.globalFilterGoodsInBranchId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }

  LoadFilterIncoterm(): any {
    this.purchaseregisterservice.getIncoTerm().subscribe((data: any) => {
      this.reportIncotermList = data;
      this.report['IncotermId'] = this.globalService.globalFilterSupplierId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }

  LoadFilterCurrency(): any {
    this.purchaseregisterservice.getCurrency().subscribe((data: any) => {
      this.reportCurrencyList = data;
      this.report['CurrencyId'] = this.globalService.globalFilterCurrencyId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }

  LoadFilterSubOrderType(): any {
    this.purchaseregisterservice.getSubOrderType().subscribe((data: any) => {
      this.reportSubordertypeList = data;
      this.report['SubOrderTypeId'] = this.globalService.globalFilterSubOrderTypeId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }

  LoadFilterPoSupplier(): any {
    this.reportService.GetReportFilterPOSupplier().subscribe((data: any) => {
      this.reportPOsupplierList = data;
      this.report['POSupplierId'] = this.globalService.globalFilterPOSupplierId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }
  LoadFilterOrderType(): any {
    this.purchaseregisterservice.getOrderType().subscribe((data: any) => {
      this.reportOrderTypeList = data;
      this.report['OrderTypeId'] = this.globalService.globalFilterOrderTypeId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }

  LoadFilterBuyer(): any {
    this.purchaseregisterservice.getBuyer().subscribe((data: any) => {
      this.reportBuyerList = data;
      this.report['BuyerId'] = this.globalService.globalFilterBuyerId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }

  LoadFilterStatisticalPeriod(): any {
    this.purchaseregisterservice.getStatisticalPeriod().subscribe((data: any) => {
      this.reportStatisticalPeriodList = data;
      this.report['StatPeriodId'] = this.globalService.globalFilterStatPeriodId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }

  ChangeContacts(selectedvalue): any {

    if (selectedvalue.target.value != "") {
      this.filteredContact = Object.assign([], this.contactList);
      var phoneNo = this.filteredContact.find(x => x.ContactId == selectedvalue.target.value)
      this.register.ContactPhone = phoneNo.PhoneNumber;
      //this.register.ContactEmail
    }
    else {
      this.register.ContactPhone = "";
    }

  }
  //ClearFields(): void {
  //  this.report = {
  //    FromDate: this.FromDate,
  //    ToDate: new Date(),
  //  }
  //  this.globalService.getUserInfo().subscribe((data: any) => {

  //    this.regionId = data.Region_Id;
  //    this.register['RegionId'] = this.regionId;
  //    this.LoadPurchaseReport(this.register);
  //  });

  //}
  onBuyersSubmit(form: NgForm): any {
    this.purchaseregisterservice.SaveBuyers(form).subscribe((data: any) => {
      this.toastr.success("Successfully Saved Buyer");

      this.ClearBuyersFields();
      this.LoadBuyer();
      this.register.BuyerId = data;
      $('#modal-buyer').modal('hide');
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  onContactsSubmit(form: NgForm): any {
    this.purchaseregisterservice.SaveContacts(form).subscribe((data: any) => {
      this.toastr.success("Successfully Saved Contact");

      this.ClearContactsFields();
      this.contactList = [];
      $('#modal-contact').modal('hide');
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  ClearBuyers(): any {
    this.ClearBuyersFields();
  }
  ClearContacts(): any {
    this.ClearContactsFields();
  };
  ConvertDateTime(str) {
    var mnths = {
      Jan: "01", Feb: "02", Mar: "03", Apr: "04", May: "05", Jun: "06",
      Jul: "07", Aug: "08", Sep: "09", Oct: "10", Nov: "11", Dec: "12"
    },
      date = str.split(" ");
    return [date[3], mnths[date[1]], date[2]].join("/");
  }
  OnFilterSubmit(form: NgForm): any {

    if (this.report['AlterationNecessary'] == "")
      this.report['AlterationNecessary'] = null;
    else
      this.report['AlterationNecessary'] = parseInt(this.report['AlterationNecessary']);
    if (this.report['OrderConfirmation'] == "")
      this.report['OrderConfirmation'] = null;
    else
      this.report['OrderConfirmation'] = parseInt(this.report['OrderConfirmation']);

    if (this.report['InternalPONo'] == "")
      this.report['InternalPONo'] = null;
    else
      this.report['InternalPONo'] = parseInt(this.report['InternalPONo']);
    this.report['RegionId'] = this.regionId;
    //this.report['UnitId'] = this.unitId;
    this.LoadPurchaseReport(this.report);
  }

  ClearFilterFields(): void {
    this.contactList = [];
    this.report = {
      FromDate: "",
      ToDate: "",
      BuyerId: "",
      OrderTypeId: "",
      StatPeriodId: "",
      POSupplierId: "",
      ContactId: "",
      RegionId: this.regionId,
      UnitId: this.unitId,
      SubOrderTypeId: "",
      CountryId: "",
      CurrencyId: "",
      GoodsInBranchId: "",
      ShopId: "",
      IncoTermId: "",
      SupplierId: "",
      AlterationNecessary: "",
      OrderConfirmation: "",
      UserId: "",
      CurrencyValueId: "",
      InternalPONo: ""
    }
    this.globalService.globalFilterBuyerId = "";
    this.globalService.globalFilterSupplierId = "";
    this.globalService.globalFilterPOSupplierId = "";
    this.globalService.globalFilterContactId = "";
    this.globalService.globalFilterGoodsInBranchId = "";
    this.globalService.globalFilterShopId = "";
    this.globalService.globalFilterOrderTypeId = "";
    this.globalService.globalFilterSubOrderTypeId = "";
    this.globalService.globalFilterCurrencyId = "";
    this.globalService.globalFilterIncoTermId = "";
    this.globalService.globalFilterStatPeriodId = "";
    this.globalService.globalFilterUserId = "";
    this.globalService.globalFilterAlterationNecessary = "";
    this.globalService.globalFilterOrderConfirmation = "";
    this.globalService.globalFilterInternalPONo = "";
    this.globalService.globalbuyerinfo = false;
    this.globalService.globalsupplierinfo = false;
    this.globalService.globalposupplierinfo = false;
    this.globalService.globalcontactinfo = false;
    this.globalService.globalgoodsinbranchinfo = false;
    this.globalService.globalshopinfo = false;
    this.globalService.globalordertypeinfo = false;
    this.globalService.globalsubordertypeinfo = false;
    this.globalService.globalcurrencyinfo = false;
    this.globalService.globalincoterminfo = false;
    this.globalService.globalstatperiodinfo = false;
    this.globalService.globalalterationnecessaryinfo = false;
    this.globalService.globalorderconfirmationinfo = false;
    this.globalService.globalinternalponoinfo = false;
    this.globalService.globalusernameinfo = false;
    this.DisableFromDate = false;
    this.DisableToDate = false;
    this.RemoveDateInfo = "";
    this.report['FromDate'] = this.FromDate;
    this.report['ToDate'] = new Date();
    this.FilterFromDate = 'Date From: ' + this.ConvertDateTime(this.report['FromDate'].toString());
    this.FilterToDate = ' to ' + this.ConvertDateTime(this.report['ToDate'].toString());
    //this.LoadFilterShop(this.reportList);
    this.globalService.globalFilterCount = 0;
    this.FilterCount = 0;
    this.LoadPurchaseReport(this.report);
  }

  onSubmit(form: NgForm): any {

    if (this.filesToUpload.length < 6) {
      this.uploadResult = "";
      let formData: FormData = new FormData();
      for (var i = 0; i < this.filesToUpload.length; i++) {
        formData.append('uploadedFiles', this.filesToUpload[i], this.filesToUpload[i].name);
      }
      if (form['OrderConfirmation'] != null)
        form['OrderConfirmation'] = parseInt(form['OrderConfirmation']);
      if (form['ArticleTemplate'] != null)
        form['ArticleTemplate'] = parseInt(form['ArticleTemplate']);
      if (form['AlterationNecessary'] != null)
        form['AlterationNecessary'] = parseInt(form['AlterationNecessary']);
      this.purchaseregisterservice.SavePurchaseRegister(form).subscribe((data: any) => {
        this.toastr.success("Successfully updated Purchase Report.");
        formData.append('orderid', data);
        this.purchaseregisterservice.SaveFiles(formData).subscribe((data: any) => {

          this.ClearRegisterFields();
          this.filesToUpload = [];
          this.ngxService.start();
          this.LoadPurchaseReport(this.report);
          this.ngxService.stop();
          this.createpurchaseregister = false;
          this.filterAttribute = true;
        },
          (err: HttpErrorResponse) => {
            this.toastr.error(err.error);
          });
      },
        (err: HttpErrorResponse) => {
          this.toastr.error(err.error);
        });
    }
    else {
      this.toastr.warning("File upload exceeded more then 5.");
    }
  }
  CreateReport(): void {
    this.btnsubmit = "Update";
    this.ngxService.start();
    this.createpurchaseregister = true;
    this.filterAttribute = false;
    this.createbutton = false;
    this.ClearRegisterFields();

    this.ngxService.stop();
  }
  UpdateReport(items, event, i): void {

    this.ngxService.start();
    if (items.FileName1 == "" || items.FileName1 == null)
      this.ShowFileName1 = false;
    else {
      this.ShowFileName1 = true;
      this.FileName1 = items.FileName1;
    }
    if (items.FileName2 == "" || items.FileName2 == null)
      this.ShowFileName2 = false;
    else {
      this.ShowFileName2 = true;
      this.FileName2 = items.FileName2;
    }
    if (items.FileName3 == "" || items.FileName3 == null)
      this.ShowFileName3 = false;
    else {
      this.ShowFileName3 = true;
      this.FileName3 = items.FileName3;
    }
    if (items.FileName4 == "" || items.FileName4 == null)
      this.ShowFileName4 = false;
    else {
      this.ShowFileName4 = true;
      this.FileName4 = items.FileName4;
    }
    if (items.FileName5 == "" || items.FileName5 == null)
      this.ShowFileName5 = false;
    else {
      this.ShowFileName5 = true;
      this.FileName5 = items.FileName5;
    }
    this.LoadGoodsInBranch(this.regionId);
    this.createpurchaseregister = true;
    this.LoadContact(items.POSupplierId)
    this.FilePath = items.FilePath;
    var deliverydatefrom = new Date(items.DelivFromDate);
    var deliverydateto = new Date(items.DelivToDate);
    items.IncotermId = items.IncotermId == null ? "" : items.IncotermId;
    items.CountryDeliveryId = items.CountryDeliveryId == null || items.CountryDeliveryId == 0 ? "" : items.CountryDeliveryId;
    items.ArticleTemplate = items.ArticleTemplate == true ? 1 : 0;
    items.OrderConfirmation = items.OrderConfirmation == true ? 1 : 0;

    items.AlterationNecessary = items.AlterationNecessary == true ? 1 : 0;
    this.purchaseregisterservice.getShop(this.regionId, items.GoodsInBranchId).subscribe((data: any) => {

      this.shopList = [];
      this.shopList = data;
      this.register = {
        OrderIndex: items.OrderIndex,
        BuyerId: items.BuyerId,
        OrderTypeId: items.OrderTypeId,
        GoodsInBranchId: items.GoodsInBranchId,
        ShopId: items.ShopId,
        SupplierId: items.SupplierId,
        POSupplierId: items.POSupplierId,
        ContactId: items.ContactId,
        ProductGroupId: items.ProductGroupId,
        CurrencyId: items.CurrencyId,
        StatPeriodId: items.StatPeriodId,
        SubOrderTypeId: items.SubOrderTypeId,
        IncoTermId: items.IncotermId,
        CountryId: items.CountryDeliveryId,
        AlterationNecessary: items.AlterationNecessary,
        DeliveryFromTo: [deliverydatefrom, deliverydateto],
        ContactPhone: items.PhoneNumber,
        OrderNumber: items.OrderNumber,
        Pieces: items.Pieces,
        Value: items.Value,
        Discount: items.Discount,
        ContactEmail: items.ContactEmail,
        Markup: items.Markup,
        FrieghtCost: items.FrieghtCost,
        Comments: items.Comment,
        ArticleTemplate: items.ArticleTemplate,
        OrderConfirmation: items.OrderConfirmation,
        InternalPOnr: items.InternalPOnr,
      };
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
    this.buyers = {
      BuyerFirstName: "",
      BuyerLastName: "",
    };
    this.contacts = {
      ContactName: "",
      PhoneNumber: "",
      ContactEmail: "",
      ContactPOSupplier: "",
    };
    this.btnsubmit = "Update";
    this.filterAttribute = false;
    this.createbutton = false;
    this.ngxService.stop();
  }

  RemoveImage(filename, file): void {

    bootbox.confirm("Do you want to delete the file?",
      (result) => {
        if (result) {
          this.purchaseregisterservice.RemoveImage(file, this.register.OrderIndex).subscribe((data: any) => {

            if (file == "FileName1")
              this.ShowFileName1 = false;
            else if (file == "FileName2")
              this.ShowFileName2 = false;
            else if (file == "FileName2")
              this.ShowFileName3 = false;
            else if (file == "FileName3")
              this.ShowFileName2 = false;
            else if (file == "FileName4")
              this.ShowFileName4 = false;
            else if (file == "FileName5")
              this.ShowFileName5 = false;
            this.LoadPurchaseReport(this.report);
            this.toastr.success("Successfully deleted data");
          },
            (err: HttpErrorResponse) => {
              this.toastr.error(err.error);
            });
        }
      });
  }

  DeleteReport(items, event, i): void {
    bootbox.confirm("Do you want to delete this data?",
      (result) => {
        if (result) {
          this.purchaseregisterservice.DeletePurchaseReport(items.OrderIndex).subscribe((data: any) => {
            this.LoadPurchaseReport(this.report);
            this.toastr.success("Successfully deleted Purchase report");
          },
            (err: HttpErrorResponse) => {
              this.toastr.error(err.error);
            });
        }
      });
  }

  CancelCreate(): void {
    this.ngxService.start();
    this.createpurchaseregister = false;
    this.filterAttribute = true;
    this.createbutton = true;
    this.NetValue = "";
    this.ngxService.stop();
  }
  LoadPurchaseReport(report?): any {

    this.ngxService.start();
    this.ManageGlobalVariablesForFilter();
    if (report.FromDate != null)
      this.FilterFromDate = 'Date From: ' + this.ConvertDateTime(this.report['FromDate'].toString());
    if (report.ToDate != null)
      this.FilterToDate = ' to ' + this.ConvertDateTime(this.report['ToDate'].toString())
    this.reportService.GetPurchaseEditReport(report).subscribe((data: any) => {

      this.reportList = [];
      this.reportList = data;
      if (this.report['checked'] == true) {
        this.FilterFromDate = "";
        this.FilterToDate = "";
      }
      this.report['AlterationNecessary'] = this.globalService.globalFilterAlterationNecessary.toString();
      this.report['OrderConfirmation'] = this.globalService.globalFilterOrderConfirmation.toString();
      this.report['InternalPONo'] = this.globalService.globalFilterInternalPONo.toString();
      if (report['AlterationNecessary'] == null)
        report['AlterationNecessary'] = "";
      if (report['InternalPONo'] == null)
        report['InternalPONo'] = "";
      if (report['OrderConfirmation'] == null)
        report['OrderConfirmation'] = "";
      this.LoadDataTable();
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });

  }


  ManageGlobalVariablesForFilter(): void {
    if (this.globalService.globalFilterBuyerId == undefined)
      this.globalService.globalFilterBuyerId = "";
    if (this.globalService.globalFilterSupplierId == undefined)
      this.globalService.globalFilterSupplierId = "";
    if (this.globalService.globalFilterPOSupplierId == undefined)
      this.globalService.globalFilterPOSupplierId = "";
    if (this.globalService.globalFilterContactId == undefined)
      this.globalService.globalFilterContactId = "";
    if (this.globalService.globalFilterGoodsInBranchId == undefined)
      this.globalService.globalFilterGoodsInBranchId = "";
    if (this.globalService.globalFilterShopId == undefined)
      this.globalService.globalFilterShopId = "";
    if (this.globalService.globalFilterOrderTypeId == undefined)
      this.globalService.globalFilterOrderTypeId = "";
    if (this.globalService.globalFilterSubOrderTypeId == undefined)
      this.globalService.globalFilterSubOrderTypeId = "";
    if (this.globalService.globalFilterCurrencyId == undefined)
      this.globalService.globalFilterCurrencyId = "";
    if (this.globalService.globalFilterIncoTermId == undefined)
      this.globalService.globalFilterIncoTermId = "";
    if (this.globalService.globalFilterStatPeriodId == undefined)
      this.globalService.globalFilterStatPeriodId = "";
    if (this.globalService.globalFilterUserId == undefined)
      this.globalService.globalFilterUserId = "";
    if (this.globalService.globalFilterAlterationNecessary == undefined)
      this.globalService.globalFilterAlterationNecessary = "";
    if (this.globalService.globalFilterOrderConfirmation == undefined)
      this.globalService.globalFilterOrderConfirmation = "";
    if (this.globalService.globalFilterInternalPONo == undefined)
      this.globalService.globalFilterInternalPONo = "";
    if (this.globalService.globalFilterFromDate == undefined)
      this.globalService.globalFilterFromDate = "";
    if (this.globalService.globalFilterToDate == undefined)
      this.globalService.globalFilterToDate = "";
  }

  LoadDataTable(): void {
    $("#datatable").dataTable().fnDestroy();
    setTimeout(function () {
      $('#datatable').DataTable({
        'paging': true,
        'lengthChange': true,
        'searching': true,
        'ordering': true,
        'info': true,
        'autoWidth': false,
        'bRetrieve': true,
        "scrollX": true,
        "aaSorting": []
      }).columns.adjust();
    }, 0);
    this.ngxService.stop();
  }
  upload() {
    if (this.filesToUpload.length > 5) {
      this.toastr.warning('Please select a maximum of 5 PDF files to upload!');
    }
  }

  fileChangeEvent(fileInput: any) {
    this.uploadResult = "";
    this.filesToUpload = <Array<File>>fileInput.target.files;
    for (let i = 0; i < this.filesToUpload.length; i++) {
      this.selectedFileNames.push(this.filesToUpload[i].name);
    }
  }
  checkValue(isChecked) {

    if (this.isChecked) {
      this.valueLabel = "Net Value";
      this.OnChangeDiscount();
      this.OnChangeValue();
    }
    else {
      this.valueLabel = "Gross Value";
      this.OnChangeDiscount();
      this.OnChangeValue();
    }
  }

  ChangeBuyer(buyerid): void {

    this.globalService.globalFilterBuyerId = buyerid;
    if (buyerid != "") {
      if (this.globalService.globalbuyerinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalbuyerinfo = true;
    }
    else {
      if (this.globalService.globalbuyerinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalbuyerinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeSupplier(supplierid): void {
    this.globalService.globalFilterSupplierId = supplierid;
    if (supplierid != "") {
      if (this.globalService.globalsupplierinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalsupplierinfo = true;
    }
    else {
      if (this.globalService.globalsupplierinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalsupplierinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangePOSupplier(selectedvalue): any {

    if (selectedvalue.target.value != "") {
      this.globalService.globalFilterPOSupplierId = selectedvalue.target.value;
      this.LoadContactForReport(selectedvalue.target.value);
      if (this.globalService.globalposupplierinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalposupplierinfo = true;
    }
    else {
      this.globalService.globalFilterPOSupplierId = selectedvalue.target.value;
      this.globalService.globalFilterContactId = "";
      if (this.globalService.globalposupplierinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalposupplierinfo = false;
      this.contactList = [];
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeContact(contactid): void {
    this.globalService.globalFilterContactId = contactid;
    if (contactid != "") {
      if (this.globalService.globalcontactinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalcontactinfo = true;
    }
    else {
      if (this.globalService.globalcontactinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalcontactinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }

  ChangeGoodsInBranch(selectedvalue): any {
    if (selectedvalue.target.value != "") {
      this.globalService.globalFilterGoodsInBranchId = selectedvalue.target.value;
      this.LoadShop(selectedvalue.target.value);
      if (this.globalService.globalgoodsinbranchinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalgoodsinbranchinfo = true;
    }
    else {
      this.globalService.globalFilterGoodsInBranchId = selectedvalue.target.value;
      if (this.globalService.globalgoodsinbranchinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalgoodsinbranchinfo = false;
      this.shopList = [];
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeShop(shopid): void {
    this.globalService.globalFilterShopId = shopid;
    if (shopid != "") {
      if (this.globalService.globalshopinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalshopinfo = true;
    }
    else {
      if (this.globalService.globalshopinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalshopinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeOrderType(ordertypeid): void {
    this.globalService.globalFilterOrderTypeId = ordertypeid;
    if (ordertypeid != "") {
      if (this.globalService.globalordertypeinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalordertypeinfo = true;
    }
    else {
      if (this.globalService.globalordertypeinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalordertypeinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeSubOrderType(subordertypeid): void {
    this.globalService.globalFilterSubOrderTypeId = subordertypeid;
    if (subordertypeid != "") {
      if (this.globalService.globalsubordertypeinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalsubordertypeinfo = true;
    }
    else {
      if (this.globalService.globalsubordertypeinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalsubordertypeinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeCurrency(currencyid): void {
    this.globalService.globalFilterCurrencyId = currencyid;
    if (currencyid != "") {
      if (this.globalService.globalcurrencyinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalcurrencyinfo = true;
    }
    else {
      if (this.globalService.globalcurrencyinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalcurrencyinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeIncoterm(incotermid): void {
    this.globalService.globalFilterIncoTermId = incotermid;
    if (incotermid != "") {
      if (this.globalService.globalincoterminfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalincoterminfo = true;
    }
    else {
      if (this.globalService.globalincoterminfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalincoterminfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeStatperiod(statperiodid): void {
    this.globalService.globalFilterStatPeriodId = statperiodid;
    if (statperiodid != "") {
      if (this.globalService.globalstatperiodinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalstatperiodinfo = true;
    }
    else {
      if (this.globalService.globalstatperiodinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalstatperiodinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeUser(userid): void {
    this.globalService.globalFilterUserId = userid;
    if (userid != "") {
      if (this.globalService.globalusernameinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalusernameinfo = true;
    }
    else {
      if (this.globalService.globalusernameinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalusernameinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeAlterationNecessary(alterationnecessary): void {
    this.globalService.globalFilterAlterationNecessary = alterationnecessary;
    if (alterationnecessary != "") {
      if (this.globalService.globalalterationnecessaryinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalalterationnecessaryinfo = true;
    }
    else {
      if (this.globalService.globalalterationnecessaryinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalalterationnecessaryinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeOrderConfirmation(orderconfirmation): void {
    this.globalService.globalFilterOrderConfirmation = orderconfirmation;
    if (orderconfirmation != "") {
      if (this.globalService.globalorderconfirmationinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalorderconfirmationinfo = true;
    }
    else {
      if (this.globalService.globalorderconfirmationinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalorderconfirmationinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeInternalPONo(internalpono): void {
    this.globalService.globalFilterInternalPONo = internalpono;
    if (internalpono != "") {
      if (this.globalService.globalinternalponoinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalinternalponoinfo = true;
    }
    else {
      if (this.globalService.globalinternalponoinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalinternalponoinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeFromDate(fromdate): void {
    if (fromdate != null)
      this.globalService.globalFilterFromDate = fromdate;
  }
  ChangeToDate(todate): void {
    if (todate != null)
      this.globalService.globalFilterToDate = todate;
  }

  OnChangeDiscount(): void {
    var replacedvalue: number;
    var replaceddiscount: number;
    if (!this.isChecked) {
      if (this.register.Value && this.register.Discount) {
        if (this.register.Value.includes(',')) {
          replacedvalue = this.register.Value.replace(',', '.');
        }
        else {
          replacedvalue = this.register.Value;
        }
        if (this.register.Discount.includes(',')) {
          replaceddiscount = this.register.Discount.replace(',', '.');
        }
        else {
          replaceddiscount = this.register.Discount;
        }
        var netvalue = ((replacedvalue * replaceddiscount) / 100);
        this.NetValue = "Net Value: " + (replacedvalue - netvalue).toFixed(2).toString();
      }
      else
        this.NetValue = "";
    }
    else {
      if (this.register.Value && this.register.Discount) {
        if (this.register.Value.includes(',')) {
          replacedvalue = this.register.Value.replace(',', '.');
        }
        else {
          replacedvalue = this.register.Value;
        }
        if (this.register.Discount.includes(',')) {
          replaceddiscount = this.register.Discount.replace(',', '.');
        }
        else {
          replaceddiscount = this.register.Discount;
        }
        var netvalue = ((replacedvalue * replaceddiscount) / 100);
        this.NetValue = "Gross Value: " + (replacedvalue + netvalue).toFixed(2).toString();
      }
      else
        this.NetValue = "";
    }

  }
  OnChangeValue(): void {
    var replacedvalue: number;
    var replaceddiscount: number;
    if (!this.isChecked) {
      if (this.register.Value && this.register.Discount) {
        if (this.register.Value.includes(',')) {
          replacedvalue = this.register.Value.replace(',', '.');
        }
        else {
          replacedvalue = this.register.Value;
        }
        if (this.register.Discount.includes(',')) {
          replaceddiscount = this.register.Discount.replace(',', '.');
        }
        else {
          replaceddiscount = this.register.Discount;
        }
        var netvalue = ((replacedvalue * replaceddiscount) / 100);
        this.NetValue = "Net Value: " + (replacedvalue - netvalue).toFixed(2).toString();
      }
      else
        this.NetValue = "";
    }
    else {
      if (this.register.Value && this.register.Discount) {
        if (this.register.Value.includes(',')) {
          replacedvalue = this.register.Value.replace(',', '.');
        }
        else {
          replacedvalue = this.register.Value;
        }
        if (this.register.Discount.includes(',')) {
          replaceddiscount = this.register.Discount.replace(',', '.');
        }
        else {
          replaceddiscount = this.register.Discount;
        }
        var netvalue = ((replacedvalue * replaceddiscount) / 100);
        this.NetValue = "Gross Value: " + (replacedvalue + netvalue).toFixed(2).toString();
      }
      else
        this.NetValue = "";
    }
  }
}
