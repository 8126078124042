import { Component, OnInit } from '@angular/core';
import { UserService } from '../service/user.service';
import { GlobalService } from '../service/global.service';
import { AccountService } from '../service/account.service';
import { CurrencyService } from '../service/currency.service';
import { MenuService } from '../service/menu.service';
import { PurchaseregisterService } from '../service/purchaseregister.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ColDef, GridOptions, GridSizeChangedEvent } from 'ag-grid-community';

@Component({
  selector: 'app-shopmaintenance',
  templateUrl: './shopmaintenance.component.html',
  styleUrls: ['./shopmaintenance.component.css']
})
export class ShopmaintenanceComponent implements OnInit {

  buttonName: string = 'Save';
  userRoles: any;
  model: any = {};
  submitted = false;
  showShop: boolean = false;
  goodsInBranchList: any[] = [];

  // ag-grid Settings
  private gridApi;
  private gridColumnApi;
  gridOptions: GridOptions;
  rowData = [];
  columnDefs = [
    { field: "Id", headerName: "Id", sort: 'desc', hide: true },
    { field: "ShopId", headerName: "ShopId" },
    { field: "ShopName", headerName: "ShopName" },
    { field: "UnitName", headerName: "BranchName" },
    { field: "IsStockRoom", headerName: "Is StockRoom" },
    { field: "Active", headerName: "IsActive" }

  ];
  defaultColDef: ColDef = {
    // set every column width
    // width: 100,
    minWidth: 80,
    //maxWidth: 150,
    editable: false,
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1
  };

  constructor(
    private menuService: MenuService,
    private globalService: GlobalService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private purchaseregisterservice: PurchaseregisterService,
    private currencyService: CurrencyService
  ) {


  }

  ngOnInit() {
    this.menuService.HideUnitMenu(false);
    this.menuService.HideChart(false);
    this.menuService.ShowRegionMenu(false);
    this.globalService.UserClaims().subscribe((data: any) => {
      if (data != undefined) {
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globalshopmaintenancepage);
        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {
          this.LoadGoodsInBranch(null);
          this.LoadShop(null);
          this.ClearForm();
        }
        else {
          this.showShop = true;
        }
      }
    });



    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      defaultColDef: this.defaultColDef,
      //editType : 'fullRow',
      rowSelection: 'single',
      columnTypes: {
        numberColumn: {
          width: 83,
          filter: "agNumberColumnFilter"
        },
        nonEditableColumn: { editable: false },
      },
      context: { componentParent: this },
      stopEditingWhenGridLosesFocus: true,
      suppressScrollOnNewData: true,
    }
  }

  OnChangeActive(event): any {
    this.model.Active = event;

  } 


  OnChangeIsStockRoom(event): any {
    this.model.IsStockRoom = event;

  }

  ClearForm(): any {
    this.submitted = false;
    this.model = {
      ShopId: '',
      ShopName: '',
      UnitId: '',
      IsStockRoom: 'False',
      Active: 'true'
    }
    this.buttonName = 'Save';
    this.showShop = false;
  }

  LoadGoodsInBranch(regionId): any {
    this.purchaseregisterservice.getGoodsInBranch(regionId).subscribe((data: any) => {
      this.goodsInBranchList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  OnChangeBranch(event): any {
    this.model.UnitId = event;
  }

  LoadShop(branchId): any {
    this.purchaseregisterservice.GetUnitShop(branchId).subscribe((data: any) => {
      this.rowData = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  onRowDoubleClicked(params) {
    debugger;
    this.buttonName = 'Update';
    var selectedRows = params.api.getSelectedRows();
    this.model.Id = selectedRows[0].Id;
    this.model.ShopId = selectedRows[0].ShopId;
    this.model.ShopName = selectedRows[0].ShopName;
    this.model.UnitId = selectedRows[0].UnitId;
    this.model.IsStockRoom = String(selectedRows[0].IsStockRoom);
    this.model.Active = String(selectedRows[0].Active);
  }

 
  onSubmit() {
    //debugger;
    //this.submitted = true;
    this.ngxService.start();
    this.currencyService.SaveUnitShop(this.model).subscribe((data: any) => {
      this.toastr.success(data);
      this.ClearForm();
      this.LoadShop(null);
      this.ngxService.stop();
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }

}
