import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { MenuService } from 'src/app/service/menu.service';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/service/account.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Chart } from 'chart.js';
import { PurchaseregisterService } from 'src/app/service/purchaseregister.service';
import { GlobalService } from 'src/app/service/global.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-appdashboard',
  templateUrl: './appdashboard.component.html',
  styleUrls: ['./appdashboard.component.css']
})
export class AppdashboardComponent implements OnInit {
  regionId: number;
  canvas: any;
  ctx: any;
  chart: boolean = false;
  labels: string = "";
  datas: string = "";
  totalOrderCount: number;
  @ViewChild('mychart') mychart;
  constructor(private router: Router,
    private accountService: AccountService,
    private purchaseregisterservice: PurchaseregisterService,
    private globalService: GlobalService,
    private ngxService: NgxUiLoaderService) { }

 

  ngOnInit() {
    let myChart;
    this.accountService.GetReportInfoForChart(this.regionId).subscribe((data: any) => {
      data.forEach(childObj => {
        this.labels += childObj.YearMonthName;
        this.labels += ',';
        this.datas += childObj.TotalOrderPerMonth;
        this.datas += ',';
      })
      var str = this.labels.replace(/,\s*$/, "");
      var datastr = this.datas.replace(/,\s*$/, "");
      var array = str.split(',');
      var dataarray = datastr.split(',');
      var strings = JSON.parse(JSON.stringify(array));
      var datastring = JSON.parse(JSON.stringify(dataarray));
      this.canvas = this.mychart.nativeElement;
      this.ctx = this.canvas.getContext('2d');

      myChart = new Chart(this.ctx, {
        type: 'line',
        data: {
          labels: strings,
          datasets: [{
            label: 'No. of items ordered per month.',
            backgroundColor: "rgb(60,141,188)",
            borderColor: "rgb(34,45,50)",
            fill: true,
            data: datastring,
          }]
        },
        options: {
          responsive: true,
          title: {
            display: true,
            text: 'Purchase Order'
          },
        },
      });
     
    }, (err: HttpErrorResponse) => {
      
    
    });

    this.purchaseregisterservice.regionchangesubject.subscribe((data: any) => {
      this.ngxService.start();
      this.globalService.globalRegionId = data;
      this.regionId = data;
      this.datas = "";
      this.labels = "";
      this.LoadTotalPurchaseOrderCount();
      myChart.destroy();
      this.accountService.GetReportInfoForChart(this.regionId).subscribe((data: any) => {
        data.forEach(childObj => {
          this.labels += childObj.YearMonthName;
          this.labels += ',';
          this.datas += childObj.TotalOrderPerMonth;
          this.datas += ',';
        })
        var str = this.labels.replace(/,\s*$/, "");
        var datastr = this.datas.replace(/,\s*$/, "");
        var array = str.split(',');
        var dataarray = datastr.split(',');
        var strings = JSON.parse(JSON.stringify(array));
        var datastring = JSON.parse(JSON.stringify(dataarray));
        this.canvas = this.mychart.nativeElement;
        this.ctx = this.canvas.getContext('2d');

        myChart = new Chart(this.ctx, {
          type: 'line',
          data: {
            labels: strings,
            datasets: [{
              label: 'No. of items sold per month.',
              backgroundColor: "rgb(60,141,188)",
              borderColor: "rgb(34,45,50)",
              fill: true,
              data: datastring,
            }]
          },
          options: {
            responsive: true,
            title: {
              display: true,
              text: 'Purchase Order'
            },
          },
        });
      }, (err: HttpErrorResponse) => {

      });
      this.ngxService.stop();
    });

    this.globalService.getUserInfo().subscribe((data: any) => {
      if (data != undefined) {


        if (data.PasswordChangedDays < this.globalService.globalPasswordChangeDays) {

          //region management

          if (this.globalService.globalRegionId != undefined) {
            this.regionId = parseInt(this.globalService.globalRegionId);
          }
          else {
            this.regionId = data.Region_Id;
          }
          this.ngxService.start();
          this.LoadTotalPurchaseOrderCount();
          this.LoadChart();
          this.ngxService.stop();
        }
      }
    });
    
  }

  LoadTotalPurchaseOrderCount(): void {
    this.accountService.GetTotalPurchaseOrderCount(this.regionId).subscribe((data: any) => {

      this.totalOrderCount = data;
    }, (err: HttpErrorResponse) => {

    });
  }

  LoadChart(): void {


  }

}
