import { Component, OnInit } from '@angular/core';
import { PurchaseregisterService } from '../service/purchaseregister.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgForm } from '@angular/forms';
import { ReportService } from '../service/report.service';
import { GlobalService } from '../service/global.service';
import { MenuService } from '../service/menu.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
declare var $;
import * as _ from 'underscore';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  report: {};
  regionId: number;
  unitId: number;
  FromDate = new Date();
  CurrentDate = new Date();
  ConvertedFromDate: string;
  ConvertedToDate: string;
  buyerList: any;
  reportList: any;
  orderTypeList: any = [];
  statisticalPeriodList: any = [];
  currencyValueList: any = [];
  supplierList: any = [];
  incotermList: any = [];
  currencyList: any = [];
  countryDeliveryList = [];
  goodsInBranchList: any = [];
  shopList: any = [];
  subordertypeList = [];
  contactList: any = [];
  posupplierList: any = [];
  userList: any = [];
  FilterFromDate: string;
  FilterToDate: string;
  buyerTempList = [];
  buyerSortedList = [];
  shopSortedList = [];
  currencySortedList = [];
  incotermSortedList = [];
  statperiodSortedList = [];
  userSortedList = [];
  posupplierSortedList = [];
  supplierSortedList = [];
  goodsInBranchSortedList = [];
  orderTypeTempList = [];
  statPeriodTempList = [];
  poSupplierTempList = [];
  subOrderTypeTempList = [];
  currencyTempList = [];
  incotermTempList = [];
  goodsInBranchTempList = [];
  shopTempList = [];
  supplierTempList = [];
  userTempList = [];
  contactTempList = [];
  showreport: boolean = true;
  showreportalert: boolean = false;
  userRoles: any;
  fileUrl;
  DisableFromDate: boolean = false;
  DisableToDate: boolean = false;
  RemoveDateInfo: string;
  FilterCount: number = 0;
  chart: boolean = false;

  http: any;
  constructor(
    private purchaseregisterservice: PurchaseregisterService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private reportService: ReportService,
    private menuService: MenuService,
    private globalService: GlobalService,
    private localeService: BsLocaleService
  ) {
    defineLocale('en-gb', enGbLocale);
    this.localeService.use('en-gb');
    this.FilterCount = this.globalService.globalFilterCount;
  }

  ngOnInit() {
    this.menuService.HideUnitMenu(true);
    this.menuService.HideChart(false);
    this.menuService.ShowRegionMenu(true);
    this.purchaseregisterservice.regionchangesubject.subscribe((data: any) => {

      this.globalService.globalRegionId = data;
      this.regionId = data;
      this.report['RegionId'] = this.regionId;
      this.report['UnitId'] = "";
      this.LoadPurchaseReport(this.report);
    });
    this.reportService.unitChangeOnReportSubject.subscribe((data: any) => {

      this.globalService.globalUnitId = data;
      this.unitId = data;
      this.report['UnitId'] = this.unitId;
      this.LoadPurchaseReport(this.report);
    });

    this.LoadCurrencyValue();
    this.FromDate.setDate(this.CurrentDate.getDate() - 90);
    this.ClearFields();
    this.LoadUserInfo();
    this.LoadBuyer();
    this.LoadOrderType();
    this.LoadStatisticalPeriod();
    this.LoadPoSupplier();
    this.LoadSubOrderType();
    this.LoadCurrency();
    this.LoadIncoterm();
    this.LoadGoodsInBranch(this.regionId);
    this.LoadSupplier();
    this.LoadUserNames();
  }
  LoadUserInfo(): any {
    this.globalService.getUserInfo().subscribe((data: any) => {
      if (data != undefined) {
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globalreportpage);

        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {

          //region management

          if (this.globalService.globalRegionId != undefined) {
            this.regionId = parseInt(this.globalService.globalRegionId);
          }
          else {
            this.regionId = data.Region_Id;
          }

          //unit management
          if (this.globalService.globalUnitId != undefined) {
            this.unitId = parseInt(this.globalService.globalUnitId);
            this.report['UnitId'] = this.unitId;
          }
          else {
            this.unitId = data.Unit_Id;
          }
          this.report['RegionId'] = this.regionId;
          //this.report['UnitId'] = this.unitId;

          this.LoadPurchaseReport(this.report);
        }
        else {
          this.showreport = false;
          this.showreportalert = true;
        }

      }
    });
  }


  DownloadFile(FilePath, FileName): any {
    this.ngxService.start();
    this.reportService.DownloadFile(FilePath, FileName).subscribe((data: any) => {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(data);
      link.download = FileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.ngxService.stop();
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.statusText);
        this.ngxService.stop();
      });
  }


  ClearFields(): void {

    this.ManageGlobalVariablesForFilter();
    this.report = {
      FromDate: this.globalService.globalFilterFromDate,
      ToDate: this.globalService.globalFilterToDate,
      BuyerId: this.globalService.globalFilterBuyerId,
      OrderTypeId: this.globalService.globalFilterOrderTypeId,
      StatPeriodId: this.globalService.globalFilterStatPeriodId,
      POSupplierId: this.globalService.globalFilterPOSupplierId,
      ContactId: this.globalService.globalFilterContactId,
      RegionId: this.regionId,
      UnitId: this.unitId,
      SubOrderTypeId: this.globalService.globalFilterSubOrderTypeId,
      CountryId: "",
      CurrencyId: this.globalService.globalFilterCurrencyId,
      GoodsInBranchId: this.globalService.globalFilterGoodsInBranchId,
      ShopId: this.globalService.globalFilterShopId,
      IncoTermId: this.globalService.globalFilterIncoTermId,
      SupplierId: this.globalService.globalFilterSupplierId,
      AlterationNecessary: "",
      OrderConfirmation: "",
      UserId: this.globalService.globalFilterUserId,
      CurrencyValueId: "",
      InternalPONo: "",
      checked: false
    }
    if (this.globalService.globalFilterContactId != "") {
      this.LoadContactForReport(this.globalService.globalFilterPOSupplierId);
    }
    if (this.globalService.globalFilterShopId != "") {
      this.LoadFilterShop(this.globalService.globalFilterGoodsInBranchId);
    }
    if (this.globalService.globalFilterRemoveFilterChecked == true) {
      this.RemoveDateInfo = "If no other filters are selected loadingtime may increase.";
      this.report['FromDate'] = "";
      this.report['ToDate'] = "";
      this.DisableFromDate = true;
      this.DisableToDate = true;
      this.FilterFromDate = "";
      this.FilterToDate = "";
      this.globalService.globalFilterFromDate = "";
      this.globalService.globalFilterToDate = "";
      this.report['checked'] = true;
    }
    else {
      if (this.globalService.globalFilterFromDate == undefined || this.globalService.globalFilterFromDate == "" || this.globalService.globalFilterFromDate == null)
        this.report['FromDate'] = this.FromDate;
      if (this.globalService.globalFilterToDate == undefined || this.globalService.globalFilterToDate == "" || this.globalService.globalFilterToDate == null)
        this.report['ToDate'] = new Date();
    }
  }


  LoadContactForReport(posupplierid): any {
    
    this.purchaseregisterservice.getContact(posupplierid).subscribe((data: any) => {
      
      this.contactList = [];
      this.contactList = data;
      this.report['ContactId'] = this.globalService.globalFilterContactId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  ConvertDateTime(str) {
    var mnths = {
      Jan: "01", Feb: "02", Mar: "03", Apr: "04", May: "05", Jun: "06",
      Jul: "07", Aug: "08", Sep: "09", Oct: "10", Nov: "11", Dec: "12"
    },
      date = str.split(" ");
    return [date[3], mnths[date[1]], date[2]].join("/");
  }
  onSubmit(form: NgForm): any {

    if (form['AlterationNecessary'] == "")
      form['AlterationNecessary'] = null;
    else
      form['AlterationNecessary'] = parseInt(form['AlterationNecessary']);
    if (form['OrderConfirmation'] == "")
      form['OrderConfirmation'] = null;
    else
      form['OrderConfirmation'] = parseInt(form['OrderConfirmation']);
    if (form['InternalPONo'] == "")
      form['InternalPONo'] = null;
    else
      form['InternalPONo'] = parseInt(form['InternalPONo']);
    this.LoadPurchaseReport(form);
  }


  OnChangeCurrencyValue(selectedvalue): void {
    if (selectedvalue.target.value != "") {
      this.LoadPurchaseReport(this.report);
    }
  }

  LoadCurrencyValue(): any {
    this.purchaseregisterservice.getCurrencyValue().subscribe((data: any) => {
      this.currencyValueList = [];
      this.currencyValueList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  LoadShop(goodsinBranchId?): any {
    
    this.purchaseregisterservice.getShop(this.regionId, goodsinBranchId).subscribe((data: any) => {
      for (let item of data) {
        
        this.shopList = [];
        this.shopList['ShopId'] = item.Unit_Id;
        this.shopList['ShopName'] = item.Unit_Name;
        this.shopTempList.push(this.shopList);
      }
      this.shopList = this.shopTempList;
      this.report['ShopId'] = this.globalService.globalFilterShopId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  LoadFilterShop(goodsinBranchId?): any {
    
    this.purchaseregisterservice.getShopFilter(this.regionId, goodsinBranchId).subscribe((data: any) => {
      this.shopTempList = [];
      for (let item of data) {
        this.shopList = [];
        this.shopList['ShopId'] = item.Unit_Id;
        this.shopList['ShopName'] = item.Unit_Name;
        this.shopTempList.push(this.shopList);
      }
      this.shopList = this.shopTempList;
      this.report['ShopId'] = this.globalService.globalFilterShopId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  LoadContact(posupplierid): any {
    this.purchaseregisterservice.getContact(posupplierid).subscribe((data: any) => {
      this.contactList = [];
      this.contactList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  ClearFilterFields(): void {
    this.contactList = [];
    this.report = {
      FromDate: this.FromDate,
      ToDate: new Date(),
      BuyerId: "",
      OrderTypeId: "",
      StatPeriodId: "",
      POSupplierId: "",
      ContactId: "",
      RegionId: this.regionId,
      SubOrderTypeId: "",
      CountryId: "",
      CurrencyId: "",
      GoodsInBranchId: "",
      ShopId: "",
      IncotermId: "",
      SupplierId: "",
      AlterationNecessary: "",
      UserId: "",
      OrderConfirmation: "",
      CurrencyValueId: "",
      InternalPONo: "",
      checked: false
    }
    this.globalService.globalFilterBuyerId = "";
    this.globalService.globalFilterSupplierId = "";
    this.globalService.globalFilterPOSupplierId = "";
    this.globalService.globalFilterContactId = "";
    this.globalService.globalFilterGoodsInBranchId = "";
    this.globalService.globalFilterShopId = "";
    this.globalService.globalFilterOrderTypeId = "";
    this.globalService.globalFilterSubOrderTypeId = "";
    this.globalService.globalFilterCurrencyId = "";
    this.globalService.globalFilterIncoTermId = "";
    this.globalService.globalFilterStatPeriodId = "";
    this.globalService.globalFilterUserId = "";
    this.globalService.globalFilterAlterationNecessary = "";
    this.globalService.globalFilterOrderConfirmation = "";
    this.globalService.globalFilterInternalPONo = "";
    this.globalService.globalbuyerinfo = false;
    this.globalService.globalsupplierinfo = false;
    this.globalService.globalposupplierinfo = false;
    this.globalService.globalcontactinfo = false;
    this.globalService.globalgoodsinbranchinfo = false;
    this.globalService.globalshopinfo = false;
    this.globalService.globalordertypeinfo = false;
    this.globalService.globalsubordertypeinfo = false;
    this.globalService.globalcurrencyinfo = false;
    this.globalService.globalincoterminfo = false;
    this.globalService.globalstatperiodinfo = false;
    this.globalService.globalalterationnecessaryinfo = false;
    this.globalService.globalorderconfirmationinfo = false;
    this.globalService.globalinternalponoinfo = false;
    this.globalService.globalusernameinfo = false;
    this.DisableFromDate = false;
    this.DisableToDate = false;
    this.RemoveDateInfo = "";
    this.report['FromDate'] = this.FromDate;
    this.report['ToDate'] = new Date();
    this.FilterFromDate = 'Date From: ' + this.ConvertDateTime(this.report['FromDate'].toString());
    this.FilterToDate = ' to ' + this.ConvertDateTime(this.report['ToDate'].toString());
    this.globalService.globalFilterCount = 0;
    this.FilterCount = 0;
    this.LoadPurchaseReport(this.report);
  }
  LoadUserNames(): any {
    this.purchaseregisterservice.getUserNames().subscribe((data: any) => {
      this.userList = data;
      this.report['UserId'] = this.globalService.globalFilterUserId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }

  LoadSupplier(): any {
    this.reportService.GetReportFilterSupplier().subscribe((data: any) => {
      this.supplierList = data;
      this.report['SupplierId'] = this.globalService.globalFilterSupplierId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }

  LoadGoodsInBranch(regionId): any {
    this.purchaseregisterservice.getGoodsInBranch(regionId).subscribe((data: any) => {
      for (let item of data) {
        this.goodsInBranchList = [];
        this.goodsInBranchList['GoodsInBranchId'] = item.Unit_Id;
        this.goodsInBranchList['GoodsInBranchName'] = item.Unit_Name;
        this.goodsInBranchTempList.push(this.goodsInBranchList);
      }
      this.goodsInBranchList = this.goodsInBranchTempList;
      this.report['GoodsInBranchId'] = this.globalService.globalFilterGoodsInBranchId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }

  LoadIncoterm(): any {
    this.purchaseregisterservice.getIncoTerm().subscribe((data: any) => {
      this.incotermList = data;
      this.report['IncotermId'] = this.globalService.globalFilterIncoTermId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }

  LoadCurrency(): any {
    this.purchaseregisterservice.getCurrency().subscribe((data: any) => {
      this.currencyList = data;
      this.report['CurrencyId'] = this.globalService.globalFilterCurrencyId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }

  LoadSubOrderType(): any {
    this.purchaseregisterservice.getSubOrderType().subscribe((data: any) => {
      this.subordertypeList = data;
      this.report['SubOrderTypeId'] = this.globalService.globalFilterSubOrderTypeId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }

  LoadPoSupplier(): any {
    this.reportService.GetReportFilterPOSupplier().subscribe((data: any) => {
      this.posupplierList = data;
      this.report['POSupplierId'] = this.globalService.globalFilterPOSupplierId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }
  LoadOrderType(): any {
    this.purchaseregisterservice.getOrderType().subscribe((data: any) => {
      this.orderTypeList = data;
      this.report['OrderTypeId'] = this.globalService.globalFilterOrderTypeId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }

  LoadBuyer(): any {
    this.purchaseregisterservice.getBuyer().subscribe((data: any) => {
      this.buyerList = data;
      this.report['BuyerId'] = this.globalService.globalFilterBuyerId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }

  LoadStatisticalPeriod(): any {
    this.purchaseregisterservice.getStatisticalPeriod().subscribe((data: any) => {
      this.statisticalPeriodList = data;
      this.report['StatPeriodId'] = this.globalService.globalFilterStatPeriodId;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }




  LoadPurchaseReport(report?): any {
    this.ngxService.start();
    debugger;
    //this.LoadShop();
    this.ManageGlobalVariablesForFilter();
    if (report.FromDate != null)
      this.FilterFromDate = 'Date From: ' + this.ConvertDateTime(this.report['FromDate'].toString());
    if (report.ToDate != null)
      this.FilterToDate = ' to ' + this.ConvertDateTime(this.report['ToDate'].toString());
    this.reportService.GetReport(report).subscribe((data: any) => {
      this.reportList = [];
      this.reportList = data;
      if (this.report['checked'] == true) {
        this.FilterFromDate = "";
        this.FilterToDate = "";
      }
      this.report['AlterationNecessary'] = this.globalService.globalFilterAlterationNecessary.toString();
      this.report['OrderConfirmation'] = this.globalService.globalFilterOrderConfirmation.toString();
      this.report['InternalPONo'] = this.globalService.globalFilterInternalPONo.toString();
      if (report['AlterationNecessary'] == null)
        report['AlterationNecessary'] = "";
      if (report['OrderConfirmation'] == null)
        report['OrderConfirmation'] = "";
      if (report['InternalPONo'] == null)
        report['InternalPONo'] = "";
      //}
      this.LoadDataTable();
      this.ngxService.stop();
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });

  }


  ManageGlobalVariablesForFilter(): void {

    if (this.globalService.globalFilterBuyerId == undefined)
      this.globalService.globalFilterBuyerId = "";
    if (this.globalService.globalFilterSupplierId == undefined)
      this.globalService.globalFilterSupplierId = "";
    if (this.globalService.globalFilterPOSupplierId == undefined)
      this.globalService.globalFilterPOSupplierId = "";
    if (this.globalService.globalFilterContactId == undefined)
      this.globalService.globalFilterContactId = "";
    if (this.globalService.globalFilterGoodsInBranchId == undefined)
      this.globalService.globalFilterGoodsInBranchId = "";
    if (this.globalService.globalFilterShopId == undefined)
      this.globalService.globalFilterShopId = "";
    if (this.globalService.globalFilterOrderTypeId == undefined)
      this.globalService.globalFilterOrderTypeId = "";
    if (this.globalService.globalFilterSubOrderTypeId == undefined)
      this.globalService.globalFilterSubOrderTypeId = "";
    if (this.globalService.globalFilterCurrencyId == undefined)
      this.globalService.globalFilterCurrencyId = "";
    if (this.globalService.globalFilterIncoTermId == undefined)
      this.globalService.globalFilterIncoTermId = "";
    if (this.globalService.globalFilterStatPeriodId == undefined)
      this.globalService.globalFilterStatPeriodId = "";
    if (this.globalService.globalFilterUserId == undefined)
      this.globalService.globalFilterUserId = "";
    if (this.globalService.globalFilterAlterationNecessary == undefined)
      this.globalService.globalFilterAlterationNecessary = "";
    if (this.globalService.globalFilterOrderConfirmation == undefined)
      this.globalService.globalFilterOrderConfirmation = "";
    if (this.globalService.globalFilterInternalPONo == undefined)
      this.globalService.globalFilterInternalPONo = "";
    if (this.globalService.globalFilterFromDate == undefined)
      this.globalService.globalFilterFromDate = "";
    if (this.globalService.globalFilterToDate == undefined)
      this.globalService.globalFilterToDate = "";
    //this.report['FromDate'] = this.globalService.globalFilterFromDate;
    //this.report['ToDate'] = this.globalService.globalFilterToDate;
  }

  OnChangeRemoveDateFilter(): void {

    if (this.report['checked'] == true) {
      this.RemoveDateInfo = "If no other filters are selected loadingtime may increase.";
      this.report['FromDate'] = "";
      this.report['ToDate'] = "";
      this.DisableFromDate = true;
      this.DisableToDate = true;
      this.FilterFromDate = "";
      this.FilterToDate = "";
      this.globalService.globalFilterFromDate = "";
      this.globalService.globalFilterToDate = "";
      this.globalService.globalFilterRemoveFilterChecked = true;
    }
    else {
      this.RemoveDateInfo = "";
      this.report['FromDate'] = this.FromDate;
      this.report['ToDate'] = new Date();
      this.DisableFromDate = false;
      this.DisableToDate = false;
      this.FilterFromDate = 'Date From: ' + this.ConvertDateTime(this.report['FromDate'].toString());
      this.FilterToDate = ' to ' + this.ConvertDateTime(this.report['ToDate'].toString());
      this.globalService.globalFilterRemoveFilterChecked = false;
    }
  }

  ChangeBuyer(buyerid): void {

    this.globalService.globalFilterBuyerId = buyerid;
    if (buyerid != "") {
      if (this.globalService.globalbuyerinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalbuyerinfo = true;
    }
    else {
      if (this.globalService.globalbuyerinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalbuyerinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeSupplier(supplierid): void {
    this.globalService.globalFilterSupplierId = supplierid;
    if (supplierid != "") {
      if (this.globalService.globalsupplierinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalsupplierinfo = true;
    }
    else {
      if (this.globalService.globalsupplierinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalsupplierinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangePOSupplier(selectedvalue): any {
    
    if (selectedvalue.target.value != "") {
      
      this.globalService.globalFilterPOSupplierId = selectedvalue.target.value;
      this.LoadContactForReport(selectedvalue.target.value);
      if (this.globalService.globalposupplierinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalposupplierinfo = true;
    }
    else {
      this.globalService.globalFilterPOSupplierId = selectedvalue.target.value;
      this.globalService.globalFilterContactId = "";
      if (this.globalService.globalposupplierinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalposupplierinfo = false;
      this.contactList = [];
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeContact(contactid): void {
    this.globalService.globalFilterContactId = contactid;
    if (contactid != "") {
      if (this.globalService.globalcontactinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalcontactinfo = true;
    }
    else {
      if (this.globalService.globalcontactinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalcontactinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }

  ChangeGoodsInBranch(selectedvalue): any {
    
    if (selectedvalue.target.value != "") {
      
      this.globalService.globalFilterGoodsInBranchId = selectedvalue.target.value;
      this.LoadFilterShop(selectedvalue.target.value);
      if (this.globalService.globalgoodsinbranchinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalgoodsinbranchinfo = true;
    }
    else {
      this.globalService.globalFilterGoodsInBranchId = selectedvalue.target.value;
      if (this.globalService.globalgoodsinbranchinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalgoodsinbranchinfo = false;
      this.shopList = [];
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeShop(shopid): void {
    this.globalService.globalFilterShopId = shopid;
    if (shopid != "") {
      if (this.globalService.globalshopinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalshopinfo = true;
    }
    else {
      if (this.globalService.globalshopinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalshopinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeOrderType(ordertypeid): void {
    this.globalService.globalFilterOrderTypeId = ordertypeid;
    if (ordertypeid != "") {
      if (this.globalService.globalordertypeinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalordertypeinfo = true;
    }
    else {
      if (this.globalService.globalordertypeinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalordertypeinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeSubOrderType(subordertypeid): void {
    this.globalService.globalFilterSubOrderTypeId = subordertypeid;
    if (subordertypeid != "") {
      if (this.globalService.globalsubordertypeinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalsubordertypeinfo = true;
    }
    else {
      if (this.globalService.globalsubordertypeinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalsubordertypeinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeCurrency(currencyid): void {
    this.globalService.globalFilterCurrencyId = currencyid;
    if (currencyid != "") {
      if (this.globalService.globalcurrencyinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalcurrencyinfo = true;
    }
    else {
      if (this.globalService.globalcurrencyinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalcurrencyinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeIncoterm(incotermid): void {
    this.globalService.globalFilterIncoTermId = incotermid;
    if (incotermid != "") {
      if (this.globalService.globalincoterminfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalincoterminfo = true;
    }
    else {
      if (this.globalService.globalincoterminfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalincoterminfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeStatperiod(statperiodid): void {
    this.globalService.globalFilterStatPeriodId = statperiodid;
    if (statperiodid != "") {
      if (this.globalService.globalstatperiodinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalstatperiodinfo = true;
    }
    else {
      if (this.globalService.globalstatperiodinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalstatperiodinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeUser(userid): void {
    debugger;
    this.globalService.globalFilterUserId = userid;
    if (userid != "") {
      if (this.globalService.globalusernameinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalusernameinfo = true;
    }
    else {
      if (this.globalService.globalusernameinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalusernameinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeAlterationNecessary(alterationnecessary): void {
    this.globalService.globalFilterAlterationNecessary = alterationnecessary;
    if (alterationnecessary != "") {
      if (this.globalService.globalalterationnecessaryinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalalterationnecessaryinfo = true;
    }
    else {
      if (this.globalService.globalalterationnecessaryinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalalterationnecessaryinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeOrderConfirmation(orderconfirmation): void {
    this.globalService.globalFilterOrderConfirmation = orderconfirmation;
    if (orderconfirmation != "") {
      if (this.globalService.globalorderconfirmationinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalorderconfirmationinfo = true;
    }
    else {
      if (this.globalService.globalorderconfirmationinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalorderconfirmationinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeInternalPONo(internalpono): void {
    this.globalService.globalFilterInternalPONo = internalpono;
    if (internalpono != "") {
      if (this.globalService.globalinternalponoinfo == false)
        this.globalService.globalFilterCount++;
      this.globalService.globalinternalponoinfo = true;
    }
    else {
      if (this.globalService.globalinternalponoinfo == true)
        this.globalService.globalFilterCount--;
      this.globalService.globalinternalponoinfo = false;
    }
    this.FilterCount = this.globalService.globalFilterCount;
  }
  ChangeFromDate(fromdate): void {
    if (fromdate != null)
      this.globalService.globalFilterFromDate = fromdate;
  }
  ChangeToDate(todate): void {
    if (todate != null)
      this.globalService.globalFilterToDate = todate;
  }
  LoadDataTable(): void {
    /* Create an array with the values of all the input boxes in a column */

    $("#datatable").dataTable().fnDestroy();
    setTimeout(function () {

      $('#datatable').DataTable({
        "fnInitComplete": function () {
          // Disable TBODY scoll bars
          //$('.dataTables_scrollBody').css({
          //  'overflow': 'hidden',
          //  'border': '0'
          //});

          // Enable TFOOT scoll bars
          //$('.dataTables_scrollFoot').css('overflow', 'auto');

          //$('.dataTables_scrollHead').css('overflow', 'auto');

          // Sync TFOOT scrolling with TBODY
          //$('.dataTables_scrollFoot').on('scroll', function () {
          //  $('.dataTables_scrollBody').scrollLeft($(this).scrollLeft());
          //});

          //$('.dataTables_scrollHead').on('scroll', function () {
          //  $('.dataTables_scrollBody').scrollLeft($(this).scrollLeft());
          //});
        },
        'paging': true,
        'lengthChange': true,
        'searching': true,
        'ordering': true,
        'info': true,
        'autoWidth': false,
        'bRetrieve': true,
        "scrollX": true,
        "searchDelay": 350,
        "aaSorting": []
      }).columns.adjust();
    }, 0);
  }

}

