import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../service/user.service';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../service/global.service';
import { MenuService } from '../service/menu.service';

@Component({
  selector: 'app-usergroupaccess',
  templateUrl: './usergroupaccess.component.html',
  styleUrls: ['./usergroupaccess.component.css']
})
export class UsergroupaccessComponent implements OnInit {
  model: any = {};
  availableUserGroups: any = [];
  availableObjects: any = [];
  associatedObjects: any = [];
  User_Group_Id: string = '';
  usergroupdescription: string = '';
  rightArrow: boolean = true;
  leftArrow: boolean = true;
  accessLists: any = [];
  showAccessGrid: boolean = false;
  isDisable = false;
  disableread: boolean[] = [];
  disablewrite: boolean[] = [];
  disableupdate: boolean[] = [];
  disabledelete: boolean[] = [];
  buttonName: string = 'Edit';
  showCancelButton: boolean[] = [];
  optionalButtonName: string = '';
  ReadCheckbox: boolean[] = [];
  WriteCheckbox: boolean[] = [];
  UpdateCheckbox: boolean[] = [];
  DeleteCheckbox: boolean[] = [];
  roleWrite: boolean;
  roleUpdate: boolean;
  roleDelete: boolean;
  pageName: string = 'Usergroup Access';
  userRoles: any;
  showusergroupaccess: boolean = true;
  showusergroupaccessalert: boolean = false;
  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private globalService: GlobalService,
    private menuService: MenuService) { }

  ngOnInit() {
    this.menuService.HideUnitMenu(true);
    this.menuService.HideChart(false);
    this.menuService.ShowRegionMenu(true);
    this.globalService.UserClaims().subscribe((data: any) => {
      if (data != undefined) {
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globalusergroupaccesspage);
        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {
          if (this.userRoles != '') {
            this.roleWrite = this.userRoles.Write;
            this.roleUpdate = this.userRoles.Update;
            this.roleDelete = this.userRoles.Delete;
          }
          this.LoadUserGroups();
        }
        else {
          this.showusergroupaccess = false;
          this.showusergroupaccessalert = true;
        }
      }
    });

  }
  LoadUserGroups(): any {
    this.userService.getAvailableGroups().subscribe((data: any) => {
      this.availableUserGroups = [];
      this.availableUserGroups = data;
      this.model.User_Group_Id = '';
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  UserGroupChange(): any {

    if (this.model.User_Group_Id != "") {
      var usergroup = this.availableUserGroups.find(record => record.User_Grp_Id === parseInt(this.model.User_Group_Id));
      this.usergroupdescription = usergroup.User_Grp_Description;
      this.LoadObjects();
      this.LoadAccessTables();
      this.leftArrow = false;
      this.rightArrow = false;
    }
    else {
      this.leftArrow = true;
      this.rightArrow = true;
      this.showAccessGrid = false;
      this.availableObjects = [];
      this.associatedObjects = [];
    }

  }
  LoadAccessTables(): any {
    this.userService.LoadAccessTables(this.model.User_Group_Id).subscribe((data: any) => {

      this.accessLists = [];
      if (data.length > 0) {
        this.accessLists = data;
        this.showAccessGrid = true;

        for (let i = 0; i < this.accessLists.length; i++) {
          this.disableread[i] = true;
          this.disablewrite[i] = true;
          this.disableupdate[i] = true;
          this.disabledelete[i] = true;
        }

      }
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadAssociatedObjects(): any {
    this.userService.getAssociatedObjects(this.model.User_Group_Id).subscribe((data: any) => {

      this.associatedObjects = [];
      if (data.length > 0) {
        this.associatedObjects = data;
        this.associatedObjects.forEach(user => {

          var index = this.availableObjects.findIndex(record => record.Object_Id === user.Object_Id);
          if (index >= 0) {
            this.availableObjects.splice(index, 1);
          }
        });
      }
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadObjects(): any {
    this.userService.getObjects().subscribe((data: any) => {

      this.availableObjects = [];
      this.availableObjects = data;
      this.LoadAssociatedObjects();
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  AddAccess(): any {

    if (this.model.AvailableObjects_Id.length > 0) {

      this.userService.AddAccessOnUserGroups(this.model).subscribe((data: any) => {
        this.toastr.success(data);

        this.model.AvailableObjects_Id.forEach(user => {

          var index = this.availableObjects.findIndex(record => record.Object_Id === user);
          if (index >= 0) {
            this.availableObjects.splice(index, 1);
          }
        });
        this.model.AvailableObjects_Id = [];
        this.LoadAssociatedObjects();
        this.LoadAccessTables();
      },
        (err: HttpErrorResponse) => {
          this.toastr.error(err.error);
        });
    }
  }
  RemoveAccess(): any {
    if (this.model.AssociatedObjects_Id.length > 0) {
      this.userService.RemoveAccessOnUserGroups(this.model).subscribe((data: any) => {

        this.toastr.success(data);
        this.model.AssociatedObjects_Id.forEach(user => {

          var index = this.associatedObjects.findIndex(record => record.Object_Id === parseInt(user));
          if (index >= 0) {
            this.availableObjects.push({ Object_Id: this.associatedObjects[index].Object_Id, Object_Name: this.associatedObjects[index].Object_Name, Object_Url: this.associatedObjects[index].Object_Url });
            this.associatedObjects.splice(index, 1);
          }
        });
        this.model.AssociatedObjects_Id = [];
        this.LoadAccessTables();
      },
        (err: HttpErrorResponse) => {
          this.toastr.error(err.error);
        });
    }
  }
  UpdateAccess(item, event, index): any {

    this.disableread[index] = !this.disableread[index];
    this.disableupdate[index] = !this.disableupdate[index];
    this.disablewrite[index] = !this.disablewrite[index];
    this.disabledelete[index] = !this.disabledelete[index];
    if (this.buttonName === 'Update') {
      this.userService.UpdateAccess(item).subscribe((data: any) => {
        this.toastr.success(data);
        this.LoadAccessTables();
        this.showCancelButton[index] = false;
        this.buttonName = 'Edit';
      },
        (err: HttpErrorResponse) => {
          this.toastr.error(err.error);
        });
    }

    this.buttonName = 'Update';
    this.showCancelButton[index] = true;
    this.optionalButtonName = 'Cancel';

  }
  CancelUpdate(item, event, index): any {

    this.LoadAccessTables();
    this.buttonName = 'Edit';
    this.showCancelButton[index] = false;
  }
  UpdateUserGroups(item, event, index): any {



  }

}
