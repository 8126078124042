import { Component, OnInit } from '@angular/core';
import { UserService } from '../service/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { MenuService } from '../service/menu.service';
import { GlobalService } from '../service/global.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {
  descriptionList: any;
  showinfo: boolean = true;
  showinfoalert: boolean = false;
  userRoles: any;
  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private menuService: MenuService,
    private globalService: GlobalService) { }

  ngOnInit() {
    this.menuService.HideUnitMenu(true);
    this.menuService.HideChart(false);
    this.menuService.ShowRegionMenu(true);
    this.globalService.UserClaims().subscribe((data: any) => {
      
      if (data != undefined) {
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globalinfopage);
        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {
          this.userService.GetDescriptionInfo().subscribe((data: any) => {
            this.descriptionList = [];
            this.descriptionList = data;
          },
            (err: HttpErrorResponse) => {
              this.toastr.error(err.error);
            });
        }
        else {
          this.showinfo = false;
          this.showinfoalert = true;
        }
      }
    });

  }

}
