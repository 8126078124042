import { Injectable } from '@angular/core';
import { UserGroup } from '../model/account.model';
import { GlobalService } from './global.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

 
  constructor(private http: HttpClient, private globalService: GlobalService) { }
  public LoadRegionDropdownOnRegionSaveSubject = new Subject<any>();

  LoadRegionsDropdown(showregionaccess) {
    this.LoadRegionDropdownOnRegionSaveSubject.next(showregionaccess);
  }

  SaveCurrency(currency): any {
    return this.http.post(this.globalService.baseUrl + '/api/SaveCurrency', currency);
  }

  GetCurrencyList(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetCurrencyList');
  }

  SaveRegions(model: any): any {
    return this.http.post(this.globalService.baseUrl + '/api/SaveRegions', model);
  }

  SaveUnits(model: any): any {
    return this.http.post(this.globalService.baseUrl + '/api/SaveUnits', model);
  }
  LoadAssociateSubjectAreasList(UnitId): any {
    let params = new HttpParams().set("UnitId", UnitId);
    return this.http.get(this.globalService.baseUrl + '/api/GetAssociateSubjectArea', { params: params });
  }
  LoadAvailableSubjectAreasList(UnitId): any {
    let params = new HttpParams().set("UnitId", UnitId);
    return this.http.get(this.globalService.baseUrl + '/api/GetAvailableSubjectArea', { params: params });
  }
  RemoveSubjectArea(subjectarea: any): any {
    return this.http.post(this.globalService.baseUrl + '/api/RemoveSubjectArea', subjectarea);
  }
  AddSubjectArea(subjectarea: any): any {
    return this.http.post(this.globalService.baseUrl + '/api/AddSubjectArea', subjectarea);
  } 

  SaveUnitShop(model: any): any {
    return this.http.post(this.globalService.baseUrl + '/api/SaveUnitShop', model);
  }

  SaveSupplierContact(model: any): any {
    return this.http.post(this.globalService.baseUrl + '/api/SaveSupplierContact', model);
  }

}
