import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../../service/account.service';
import { GlobalService } from '../../service/global.service';
import { UserService } from 'src/app/service/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IdentityClaims } from '../../model/account.model';
import { EmployeeService } from 'src/app/service/employee.service';
import { PurchaseregisterComponent } from 'src/app/purchaseregister/purchaseregister.component';
import { PurchaseregisterService } from 'src/app/service/purchaseregister.service';
import { ReportService } from 'src/app/service/report.service';
import { MenuService } from 'src/app/service/menu.service';
import { CurrencyService } from 'src/app/service/currency.service';
import { ToastrService } from 'ngx-toastr';
import { SessionTimerService } from 'session-expiration-alert';

@Component({
  selector: 'app-appheader',
  templateUrl: './appheader.component.html',
  styleUrls: ['./appheader.component.css']
})
export class AppheaderComponent implements OnInit {
  alertAt = 120;
  startTimer = true;
  userClaims: any;
  showunit: boolean = true;
  showregion: boolean = true;
  regionsList: any;
  unitList: any;
  unitListOnLoad: any;
  selectedRegion: any;
  unitname: string;
  regionname: string;
  usergroupname: string = 'All Units';
  allregionsaccess: boolean = true;
  regionid: string;
  unitid: string;
  disableRegion: boolean = false;
  disableUnit: boolean = false;
  constructor(
    private router: Router,
    private accountService: AccountService,
    private globalService: GlobalService,
    private employeeService: EmployeeService,
    private userService: UserService,
    private purchaseRegisterService: PurchaseregisterService,
    private reportService: ReportService,
    private menuService: MenuService,
    private currencyService: CurrencyService,
    private toastr: ToastrService,
    public sessionTimer: SessionTimerService) { }

  increase() {
    
    this.alertAt++;

  }
  toggletimer() {
    
    this.startTimer = !this.startTimer;
  }

  ngOnInit() {

    this.employeeService.regionSubject.subscribe((data: any) => {
      this.GetUserIdentitiyClaims();
    });
    this.menuService.hideunitmenusubject.subscribe((data: any) => {
      this.showunit = data;
    });
    this.menuService.showregionmenusubject.subscribe((data: any) => {
      this.showregion = data;
    });
    this.currencyService.LoadRegionDropdownOnRegionSaveSubject.subscribe((data: any) => {
      this.regionItems();
    });
    this.GetUserIdentitiyClaims();
   
  }
  GetUserIdentitiyClaims(): any {

    this.globalService.getUserInfo().subscribe((data: any) => {

      if (data.Region_Id == 0 || data.Unit_Id == 0) {
        this.regionname = "";
        this.regionid = "";
        this.unitname = "";
        this.unitid = "";
      }
      else {
        this.regionid = data.Region_Id;
        this.unitid = data.Unit_Id;
      }
      var AllUnitsAccess = data.Roles.find(record => record.User_Grp_Name == this.usergroupname)
      if (AllUnitsAccess == undefined) {
        this.allregionsaccess = false;
        this.regionItems();
        this.accountService.storeUnitId(data.Unit_Id);
        this.unitListOnLogin(this.allregionsaccess);
      }
      else {
        this.allregionsaccess = true;
        this.regionItems();
        this.unitListOnLogin(this.allregionsaccess);
      }
      this.userService.LoadEmployeeOnUnitChange(this.unitid);
    });
  }

  unitListOnLogin(allregionsaccess): any {

    this.accountService.getUnit(this.regionid, allregionsaccess).subscribe((dataunit: IdentityClaims) => {
      this.unitList = dataunit;
      //this.unitname = this.unitid;

      this.accountService.GetUnitsForEmployee().subscribe((units: any) => {

        if (this.allregionsaccess == false) {
          if (units.length == 1) {
            this.disableRegion = true;
            this.disableUnit = true;
            this.unitname = this.unitid;
          }
        }

      }, (err: HttpErrorResponse) => {

      });
    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.error);
    });
  }
  Logout() {

    localStorage.removeItem('userToken');
    this.globalService.globalRegionId = undefined;
    this.globalService.globalUnitId = "";
    this.router.navigate(['/']);
  }

  OnHeadingClick() {
    
    this.router.navigate(['/dashboard']);
    this.menuService.HideChart(true);
    this.menuService.HideUnitMenu(true);
    this.menuService.ShowRegionMenu(true);
  }
  regionItems() {

    this.unitname = "";
    this.accountService.getRegion().subscribe((data: any) => {

      this.regionsList = data;
      this.regionname = this.regionid;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error)
      });
  }
  onRegionsChange(selectedRegion) {

    this.accountService.getUnit(selectedRegion, this.allregionsaccess).subscribe((data: any) => {
      this.unitList = data;
      this.globalService.globalRegionId = selectedRegion;
      this.globalService.globalUnitId = "";
      this.unitname = "";
      this.purchaseRegisterService.LoadGoodsInBranchInRegionChange(selectedRegion);
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error)
      });
  }
  onUnitChange(selectedUnit) {
    this.accountService.globalUnitId = selectedUnit;
    this.userService.LoadEmployeeOnUnitChange(selectedUnit);
    this.reportService.LoadUnitsOnReport(selectedUnit);
  }
}
