import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder, NgForm, FormGroupDirective, FormControl } from '@angular/forms';
import { UserService } from '../service/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../service/global.service';
import { AccountService } from '../service/account.service';
import { CurrencyService } from '../service/currency.service';
import { MenuService } from '../service/menu.service';
declare var $;

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.css']
})

export class CurrencyComponent implements OnInit {
  model: any = {};
  closeResult: string;
  employeeList: any;
  region: any;
  UnitId: number;
  currencyList: any = [];
  employeeid: number;
  roleWrite: boolean;
  roleUpdate: boolean;
  roleDelete: boolean;
  userRoles: any;
  buttonName: string = 'Save';
  showcurrency: boolean = true;
  showcurrencyalert: boolean = false;
  yearList: any = [];
  constructor(
    private accountService: AccountService,
    private menuService: MenuService,
    private globalService: GlobalService,
    private toastr: ToastrService,
    private currencyService: CurrencyService
  ) {

  }
  ngOnInit() {
    this.menuService.HideUnitMenu(true);
    this.menuService.HideChart(false);
    this.menuService.ShowRegionMenu(true);
    this.globalService.UserClaims().subscribe((data: any) => {
      
      if (data != undefined) {
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globalcurrencypage);
        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {
          this.UnitId = this.accountService.globalUnitId;
          if (this.userRoles != '') {
            this.roleWrite = this.userRoles.Write;
            this.roleUpdate = this.userRoles.Update;
            this.roleDelete = this.userRoles.Delete;
          }
          if (this.UnitId == undefined)
            this.UnitId = data.Unit_Id;
          this.LoadTable();
        }
        else {
          this.showcurrency = false;
          this.showcurrencyalert = true;
        }
        this.model.Year = "";
      }
    });
  }

  LoadTable(): any {
    this.currencyService.GetCurrencyList().subscribe((data: any) => {
      this.currencyList = [];
      this.currencyList = data;
     
      this.LoadDataTable();
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });

  }

  //LoadYear(): any {
  //  this.currencyService.GetYearList().subscribe((data: any) => {
  //    this.currencyList = [];
  //    this.currencyList = data;
  //    this.LoadDataTable();
  //  },
  //    (err: HttpErrorResponse) => {
  //      this.toastr.error(err.error);
  //    });

  //}

  onSubmit() {

    if (this.model.invalid) {
      return;
    }
    this.currencyService.SaveCurrency(this.model).subscribe((data: any) => {
      this.toastr.success("Successfully saved userinfo.");
      this.LoadTable()
      this.buttonName = 'Save';
      this.ClearForm();
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  ClearForm(): void {
    this.model = {
      CurrencyValueId: 0,
      CurrencyName: "",
      Currency:"",
      ExchangeRate: "",
      ExchangeAmount: "",
      Year:""
    }
    this.buttonName = "Save";
  }
  UpdateCurrency(items): void {
    this.model = {
      CurrencyValueId: items.CurrencyValueId,
      CurrencyName: items.CurrencyName,
      ExchangeRate: items.ExchangeRate,
      ExchangeAmount: items.ExchangeAmount,
      Year: items.Year,
      Currency:items.Currency
    }
    this.buttonName = "Update";
  }
  LoadDataTable(): void {
    $("#datatable").dataTable().fnDestroy();
    setTimeout(function () {
      $('#datatable').DataTable({
        'paging': true,
        'lengthChange': true,
        'searching': true,
        'ordering': true,
        'info': true,
        'autoWidth': false,
        'bRetrieve': true
      })
    }, 0);
  }
}


