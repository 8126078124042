import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterserviceService {

  private filters: any = {};
  private dsnfilters: any = {};

  constructor() { }

  setDSNFilters(newDSNFilters: any) {
    this.dsnfilters = { ...newDSNFilters };
  }

  getDSNFilters() {
    return { ...this.dsnfilters };
  }

  clearDSNFilters() {
    this.dsnfilters = {};
  }

  setFilters(newFilters: any) {
    this.filters = { ...newFilters };
  }

  getFilters() {
    return { ...this.filters };
  }

  clearFilters() {
    this.filters = {};
  }
}
