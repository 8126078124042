import { Component, OnInit } from '@angular/core';
import { MenuService } from '../service/menu.service';

@Component({
  selector: 'app-smallreportsheet',
  templateUrl: './smallreportsheet.component.html',
  styleUrls: ['./smallreportsheet.component.css']
})
export class SmallreportsheetComponent implements OnInit {

  constructor(private menuService: MenuService) { }

  ngOnInit() {
    this.menuService.HideUnitMenu(true);
  }

}
