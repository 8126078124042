import { Component, OnInit } from '@angular/core';
import { UserService } from '../service/user.service';
import { GlobalService } from '../service/global.service';
import { AccountService } from '../service/account.service';
import { ToastrService } from 'ngx-toastr';
import { EmployeeService } from '../service/employee.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Employee } from '../model/account.model';
import { MenuService } from '../service/menu.service';

@Component({
  selector: 'app-assignemployee',
  templateUrl: './assignemployee.component.html',
  styleUrls: ['./assignemployee.component.css']
})
export class AssignemployeeComponent implements OnInit {
  unitId: number;
  Employee_Id: number = 0;
  changedUnitId: number;
  selectedUnitId: number;
  changeunitbutton: string = 'Change Main Unit';
  employeeList: any;
  employeeInfo: any = [];
  unitList: any = [];
  availableUnitsList: any;
  associatedUnitsList: any;
  availableUnits: any;
  associatedUnits: any;
  userRoles: any;
  clonedAvailableUnits: any = [];
  rightArrow: boolean = true;
  leftArrow: boolean = true;
  showCancelButton: boolean = false;
  showUnitNameLabel: boolean = true;
  showUnitNameDropdown: boolean = false;
  show: boolean;
  roleWrite: boolean;
  roleUpdate: boolean;
  roleDelete: boolean;
  usergroupname: string = 'All Units';
  allregionsaccess: boolean = true;
  showassignemployee: boolean = true;
  showassignemployeealert: boolean = false;
  constructor(
    private userService: UserService,
    private globalService: GlobalService,
    private accountService: AccountService,
    private employeeService: EmployeeService,
    private toastr: ToastrService,
    private menuService: MenuService
  ) { }

  ngOnInit() {
    this.menuService.HideUnitMenu(true);
    this.menuService.HideChart(false);
    this.menuService.ShowRegionMenu(true);
    this.userService.newEmployeeSubject.subscribe((data: any) => {
      this.unitId = data;
      this.LoadEmployee(this.unitId);
      this.employeeInfo = [];
      this.show = false;
      this.associatedUnitsList = [];
      this.LoadAvailableUnits();
    });

    this.globalService.UserClaims().subscribe((data: any) => {
      
      if (data != undefined) {
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globalassignemployeepage);
        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {
          var AllUnitsAccess = data.Roles.find(record => record.User_Grp_Name == this.usergroupname)
          if (AllUnitsAccess == undefined)
            this.allregionsaccess = false;
          else
            this.allregionsaccess = true;
          this.unitId = this.accountService.globalUnitId;
          if (this.userRoles != '') {
            this.roleWrite = this.userRoles.Write;
            this.roleUpdate = this.userRoles.Update;
            this.roleDelete = this.userRoles.Delete;
          }
          if (this.unitId == undefined)
            this.unitId = data.Unit_Id;
          this.LoadEmployee(this.unitId);
          this.LoadAvailableUnits();
        }
        else {
          this.showassignemployee = false;
          this.showassignemployeealert = true;
        }
      }
    });
  }
  LoadAvailableUnits(): any {
    this.employeeService.AvailableUnits().subscribe((data: any) => {
      this.availableUnitsList = data;
      this.clonedAvailableUnits = Object.assign([], this.availableUnitsList);
    });
  }
  LoadEmployee(selectedunitid): any {
    this.userService.getEmployeeByUnitId(selectedunitid).subscribe((data: any) => {
      this.employeeList = [];
      this.employeeList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  ChangeEmployee(Employee_Id): any {
    if (Employee_Id > 0) {
      this.show = true;
      this.employeeInfo = [];
      this.employeeInfo.push(this.employeeList.find(record => record.Employee_Id == Employee_Id));
      this.changedUnitId = this.employeeInfo[0].Unit_Id;
      this.selectedUnitId = this.employeeInfo[0].Unit_Id;
      this.rightArrow = false;
      this.leftArrow = false;
      this.LoadAvailableUnits();
      this.LoadAssociatedEmployeeId(Employee_Id);
    }
    else {
      this.employeeInfo = [];
      this.show = false;
    }
  }
  LoadAssociatedEmployeeId(Employee_Id): any {
    this.employeeService.LoadUnitsByEmployeeId(Employee_Id).subscribe((data: any) => {
      this.associatedUnitsList = data;
      this.associatedUnitsList.forEach(unit => {
        var index = this.availableUnitsList.findIndex(record => record.Unit_Id === unit.Unit_Id);
        if (index >= 0) {
          this.availableUnitsList.splice(index, 1);
        }
        this.associatedUnits = [];
      });
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  ChangeMainUnit(items): any {
    this.showUnitNameDropdown = true;
    this.showUnitNameLabel = false;
    this.showCancelButton = true;
    this.unitList = this.clonedAvailableUnits;
    //this.Unit_Id = this.changedUnitId;
    if (this.changeunitbutton === 'Update') {
      this.employeeService.UpdateMainUnit(items, this.selectedUnitId).subscribe((data: any) => {
        this.toastr.success(data);
        this.LoadEmployee(this.unitId);
        this.employeeInfo = [];
        this.changeunitbutton = 'Change Main Unit';
        this.show = false;
        this.availableUnits = [];
        this.associatedUnits = [];
        this.availableUnitsList = [];
        this.associatedUnitsList = [];
        this.employeeService.LoadRegionsOnChange(this.allregionsaccess);
      },
        (err: HttpErrorResponse) => {
          this.toastr.error(err.error);
        });
    }
    this.changeunitbutton = 'Update';
  }
  CancelMainUnit(): any {
    this.changeunitbutton = 'Change Main Unit';
    this.showCancelButton = false;
    this.showUnitNameDropdown = false;
    this.showUnitNameLabel = true;
    this.selectedUnitId = this.changedUnitId;
  }
  AddUnits(): any {

    if (this.availableUnits.length > 0) {
      this.employeeService.AssignAddUnits(this.availableUnits, this.Employee_Id).subscribe((data: any) => {
        this.toastr.success(data);
        //this.LoadAssociatedEmployeeId(this.Employee_Id);
        this.availableUnits.forEach(unit => {

          var index = this.availableUnitsList.findIndex(record => record.Unit_Id == unit);
          var items = this.availableUnitsList.find(record => record.Unit_Id == unit);
          if (index >= 0) {
            this.associatedUnitsList.push({ Unit_Id: items.Unit_Id, Unit_Name: items.Unit_Name });
            this.availableUnitsList.splice(index, 1);
          }
        });

        this.availableUnits = [];
      },
        (err: HttpErrorResponse) => {
          this.toastr.error(err.error);
        });
    }
  }
  RemoveUnits(): any {

    if (this.associatedUnits.length > 0) {
      this.employeeService.AssignRemoveUnits(this.associatedUnits, this.Employee_Id).subscribe((data: any) => {
        this.toastr.success(data);
        this.associatedUnits.forEach(unit => {
          var items = this.associatedUnitsList.find(record => record.Unit_Id == unit);
          var index = this.associatedUnitsList.findIndex(record => record.Unit_Id == unit);
          if (index >= 0) {
            this.availableUnitsList.push({ Unit_Id: items.Unit_Id, Unit_Name: items.Unit_Name });
            this.associatedUnitsList.splice(index, 1);
          }
        });
        //this.LoadAssociatedEmployeeId(this.Employee_Id);
        this.associatedUnits = [];
      },
        (err: HttpErrorResponse) => {
          this.toastr.error(err.error);
        });
    }
  }
}
