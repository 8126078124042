import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../service/global.service';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MenuService {
  showunit: boolean;
  showregion: boolean;
  public hideunitmenusubject = new Subject<any>();
  public hidechartsubject = new Subject<any>();
  public showregionmenusubject = new Subject<any>();
  constructor(private http: HttpClient, private globalService: GlobalService) { }
  HideUnitMenu(showunit) {
    this.hideunitmenusubject.next(showunit);
  }
  HideChart(showchart) {
    this.hidechartsubject.next(showchart);
  }
  ShowRegionMenu(showregion) {
    this.showregionmenusubject.next(showregion);
  }
  getParentMenu() {
    return this.http.get(this.globalService.baseUrl + '/api/GetMenuItems');
  }
}
