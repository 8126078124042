import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalService } from './global.service';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService { 


  constructor(private http: HttpClient, private globalService: GlobalService) { }

  public unitChangeOnReportSubject = new Subject<any>();

  LoadUnitsOnReport(selectedUnit) {
    this.unitChangeOnReportSubject.next(selectedUnit);
  }
  GetReport(reports): any {
    return this.http.post(this.globalService.baseUrl + '/api/GetReport', reports);
  }
  GetPurchaseEditReport(reports): any {
    return this.http.post(this.globalService.baseUrl + '/api/GetPurchaseEditReport', reports);
  }
  GetReportFilterSupplier(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetReportFilterSupplier');
  }
  GetReportFilterPOSupplier(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetReportFilterPOSupplier');
  }

  GetPurchaseOrders(purchaseOrderSearch): any {
    return this.http.post(this.globalService.baseUrl + '/api/GetReportPurchaseOrders', purchaseOrderSearch );
  }
  

  GetReportPurchaseOrders(purchaseOrderSearch): any {
    return this.http.post(this.globalService.baseUrl + '/api/GetPurchaseOrders', purchaseOrderSearch);
  }

  DownloadFile(filePath: string, fileName: string): any {
    
    let params = new HttpParams().set("filePath", filePath).set("fileName", fileName);
    return this.http.get(this.globalService.baseUrl + '/api/downloadfile', { params: params, responseType: 'blob' });
  }
}
