import { Component, OnInit } from '@angular/core';
import { AccountService } from '../service/account.service';
import { UserService } from '../service/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from '../service/global.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { MenuService } from '../service/menu.service';

@Component({
  selector: 'app-userpreferences',
  templateUrl: './userpreferences.component.html',
  styleUrls: ['./userpreferences.component.css']
})
export class UserpreferencesComponent implements OnInit {
  userPreferenceForm: FormGroup;
  userRoles: any;
  showuserpreferences: boolean = true;
  showuserpreferencesalert: boolean = false;
  constructor(private accountService: AccountService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private menuService: MenuService,
    private toastr: ToastrService,
    private globalService: GlobalService) {
    this.userPreferenceForm = this.formBuilder.group({
      changepassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmpassword: ['', Validators.required],
      
    }, {
        validator: RegistrationValidator.validate.bind(this)
      });
  }

  ngOnInit() {
    this.menuService.HideUnitMenu(true);
    this.menuService.HideChart(false);
    this.menuService.ShowRegionMenu(true);
    this.globalService.UserClaims().subscribe((data: any) => {
      if (data != undefined) {
        
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globaluserpreferencespage);
        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {
          this.showuserpreferences = true;
          this.showuserpreferencesalert = false;
        }
        else {
          this.showuserpreferences = true;
          this.showuserpreferencesalert = false;
        }
      }
    });
  }

  get changepassword() {
    return this.userPreferenceForm.get('changepassword');
  } 

  onSubmit(): void {
    this.userService.SavePassword(this.userPreferenceForm.value).subscribe((data: any) => {
      this.toastr.success(data);
      this.userPreferenceForm.controls.changepassword.setValue('');
      this.userPreferenceForm.controls.confirmpassword.setValue('');
      
      this.userPreferenceForm.markAsUntouched;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });

  }
}
export class RegistrationValidator {
  static validate(userPreferenceForm: FormGroup) {
    let password = userPreferenceForm.controls.changepassword.value;
    let repeatPassword = userPreferenceForm.controls.confirmpassword.value;
    if (repeatPassword.length <= 0) {
      return null;
    }
    if (repeatPassword !== password) {
      return {
        doesMatchPassword: true
      };
    }
    return null;

  }
}
