import { Component, OnInit } from '@angular/core';
import { UserService } from '../service/user.service';
import { GlobalService } from '../service/global.service';
import { IdentityClaims } from '../model/account.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { forEach } from '@angular/router/src/utils/collection';
import { AccountService } from '../service/account.service';
import { MenuService } from '../service/menu.service';
import { Chart } from 'chart.js';
@Component({
  selector: 'app-useraccess',
  templateUrl: './useraccess.component.html',
  styleUrls: ['./useraccess.component.css']
})
export class UseraccessComponent implements OnInit {
  userAccessForm: FormGroup;
  unitId: number;
  userList: any;
  userInfo: any;
  show: boolean = false;
  availableUserGroups: any = [];
  userGroupsByUserid: any = [];
  rightArrow: boolean = true;
  leftArrow: boolean = true;
  userid: string = "";
  availableGroups = [];
  associatedGroups = [];
  globalUserId: number = 0;
  roleWrite: boolean;
  roleUpdate: boolean;
  roleDelete: boolean;
  userRoles: any;
  showuseraccess: boolean = true;
  showuseraccessalert: boolean = false;
  LineChart = [];
  constructor(
    private userService: UserService,
    private globalService: GlobalService,
    private accountService: AccountService,
    private menuService: MenuService,
    private toastr: ToastrService) {
  }
  ngOnInit() {
    this.menuService.HideUnitMenu(true);
    this.menuService.HideChart(false);
    this.menuService.ShowRegionMenu(true);
    this.userService.newEmployeeSubject.subscribe((data: any) => {
      this.unitId = data;
      this.show = false;
      this.LoadUsers(data);
    });
    this.globalService.UserClaims().subscribe((data: any) => {
      if (data != undefined) {
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globaluseraccesspage);
        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {
          this.unitId = this.accountService.globalUnitId;
          if (this.userRoles != '') {
            this.roleWrite = this.userRoles.Write;
            this.roleUpdate = this.userRoles.Update;
            this.roleDelete = this.userRoles.Delete;
          }
          if (this.unitId == undefined) {
            this.unitId = data.Unit_Id;
          }
          this.LoadUsers(this.unitId);
          this.LoadAvailableGroups();
        }
        else {
          this.showuseraccess = false;
          this.showuseraccessalert = true;
        }
      }
    });
    this.LineChart = new Chart('lineChart', {
      type: 'line',
      data: {
        labels: ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [{
          label: 'Number of Items Sold in Months',
          data: [9, 7, 3, 5, 2, 10, 15, 16, 19, 3, 1, 9],
          fill: false,
          lineTension: 0.2,
          borderColor: "red",
          borderWidth: 1
        }]
      },
      options: {
        title: {
          text: "Line Chart",
          display: true
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }
  LoadGroupsByUserId(User_Id: number): any {
    this.userService.getAvailableGroupsByUserId(User_Id).subscribe((data: any) => {
      this.userGroupsByUserid = [];
      if (data.length > 0) {

        this.userGroupsByUserid = data;
        this.userGroupsByUserid.forEach(user => {

          var index = this.availableUserGroups.findIndex(record => record.User_Grp_Id === user.User_Grp_Id);
          if (index >= 0) {
            this.availableUserGroups.splice(index, 1);
          }
        });
      }
      else
        this.LoadAvailableGroups();
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadAvailableGroups(): any {
    this.userService.getAvailableGroups().subscribe((data: any) => {
      this.availableUserGroups = [];
      this.availableUserGroups = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadUsers(unitId): any {
    this.userService.getUserList(unitId).subscribe((data: any) => {

      this.userList = [];
      this.userList = data;

    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  ChangeUsers(userid): any {

    this.availableGroups = [];
    this.globalUserId = userid;
    if (userid != "") {
      this.userInfo = this.userList.filter(x => x.User_Id === parseInt(userid));
      this.LoadGroupsByUserId(parseInt(userid));
    }
    if (this.userInfo.length > 0) {
      this.show = true;
      this.rightArrow = false;
      this.leftArrow = false;
    }
    else
      this.show = false;
  }
  AddGroups(): any {

    if (this.availableGroups.length > 0) {
      if (this.availableUserGroups.findIndex(record => record.User_Grp_Id === parseInt(this.availableGroups[0])) != -1) {
        this.userService.addGroupsForUser(this.availableGroups, this.globalUserId).subscribe((data: any) => {

          this.LoadGroupsByUserId(this.globalUserId);
          this.toastr.success(data);
        },
          (err: HttpErrorResponse) => {
            this.toastr.error(err.error);
          });
      }
    }

  }
  RemoveGroups(): any {
    if (this.associatedGroups.length > 0) {
      this.userService.removeGroupsForUser(this.associatedGroups, this.globalUserId).subscribe((data: any) => {

        this.associatedGroups.forEach(user => {

          var index = this.userGroupsByUserid.findIndex(record => record.User_Grp_Id === parseInt(user));
          if (index >= 0) {
            this.availableUserGroups.push({ User_Grp_Id: this.userGroupsByUserid[index].User_Grp_Id, User_Grp_Name: this.userGroupsByUserid[index].User_Grp_Name, User_Grp_Description: this.userGroupsByUserid[index].User_Grp_Description });
            this.userGroupsByUserid.splice(index, 1);
          }
        });
        this.toastr.success(data);
      },
        (err: HttpErrorResponse) => {
          this.toastr.error(err.error);
        });
    }
  }
}

