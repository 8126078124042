import { Injectable } from '@angular/core';
import { SessionInteruptService } from 'session-expiration-alert';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class AppSessionInteruptService extends SessionInteruptService {
  constructor(private readonly httpClient: HttpClient,
    private router: Router) {
    super();
  }
  continueSession() {
  
  }
  stopSession() {
    localStorage.removeItem('userToken');
    this.router.navigate(['/']);
  }
}
