import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../service/account.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../service/global.service';
import { IdentityClaims } from '../model/account.model';
import { UserService } from '../service/user.service';
import { MenuService } from '../service/menu.service';
import { Chart } from 'chart.js';
declare var $;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})

export class DashboardComponent implements OnInit {
  userClaims: any;
  regionsList: any;
  unitList: any;
  unitListOnLoad: any;
  selectedRegion: any;
  unitname: number;
  regionname: number;
  userName: string;
  canvas: any;
  ctx: any;
  chart: boolean = true;
  labels: string = "";
  datas: string = "";
  @ViewChild('mychart') mychart;
  constructor(
    private router: Router,
    private accountService: AccountService,
    private menuService: MenuService,
    private userService: UserService,
    private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    
    this.menuService.HideUnitMenu(true);
    this.menuService.ShowRegionMenu(true);

    this.menuService.hidechartsubject.subscribe((data: any) => {
      
      this.chart = data;
    });

  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  //this.regionItems();
  //this.unitListOnLogin();

  unitListOnLogin(): any {

    this.accountService.getUnitsOnLoad().subscribe((data: IdentityClaims) => {
      this.unitList = data;
      this.regionname = data[0].Region_Id;
      this.accountService.getUserClaims().subscribe((data: IdentityClaims) => {

        this.unitname = data.Unit_Id;
        this.accountService.storeUnitId(data.Unit_Id);
      });
    }, (err: HttpErrorResponse) => {

    });
  }

  Logout() {
    localStorage.removeItem('userToken');
    this.router.navigate(['/']);
  }

  //regionItems() {
  //  this.unitname = 0;
  //  this.accountService.getRegion().subscribe((data: any) => {
  //    this.regionsList = data;
  //  },
  //    (err: HttpErrorResponse) => {

  //    });
  //}

  //onRegionsChange(selectedRegion) {

  //  this.accountService.getUnit(selectedRegion).subscribe((data: any) => {
  //    this.unitList = data;

  //  },
  //    (err: HttpErrorResponse) => {

  //    });
  //}
  onUnitChange(selectedUnit) {
    this.accountService.globalUnitId = selectedUnit;
    this.userService.LoadEmployeeOnUnitChange(selectedUnit);
  }
}
