import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../service/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { PurchaseregisterService } from '../service/purchaseregister.service';
import { AccountService } from '../service/account.service';
import { NgForm } from '@angular/forms';
import { GlobalService } from '../service/global.service';
import { MenuService } from '../service/menu.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridReadyEvent, GridOptions, GridSizeChangedEvent } from 'ag-grid-community';
import { ReportService } from '../service/report.service';
import { PurchaseOrderHeaderModel, PurchaseOrderShopModel, PurchaseOrderLineModel } from '../model/purchase-register-bulk.model';
import { PurchaseregistergridbuttonComponent } from '../components/purchaseregistergridbutton/purchaseregistergridbutton.component';
import { object } from 'underscore';
declare var $;

@Component({
  selector: 'app-purchaseregisterbulk',
  templateUrl: './purchaseregisterbulk.component.html',
  styleUrls: ['./purchaseregisterbulk.component.css']
})
export class PurchaseregisterbulkComponent implements OnInit {
  defaultbrand = 2581; //set default brand on page load
  bsValue = new Date();
  maxDate = new Date();
  DeliveryFromTo: Date[];  
  register: any = {};
  pageName: string = 'Registration';
  goodsInBranchList: any = [];
  shopList: any = [];
  selectedShops: any = [];
  supplierList: any = [];
  paymenttermList: any = [];
  statisticalPeriodList: any = [];
  incotermList: any = [];
  errorMessage: string;
  filesToUpload: Array<File>;
  selectedFileNames: string[] = [];
  public isLoadingData: Boolean = false;
  @ViewChild('fileUpload') fileUploadVar: any;
  userRoles: any;
  uploadResult: any;
  res: Array<string>;
  showpurchaseregister: boolean = true;
  showpurchaseregisteralert: boolean = false;
  isChecked: boolean;

  // ag-grid Settings
  purchaseOrder: any;
  purchaseOrderLines: PurchaseOrderLineModel[]=[];
  purchaseOrderHeaders: PurchaseOrderHeaderModel[]=[];
  purchaseOrderShops: PurchaseOrderShopModel[] = [];

  gridOptions: GridOptions;
  rowData = [];
  columnDefs = [    
    { field: "Currency" },
    { field: "Value" },
    { field: "PCS" },
    { field: "SKU" },
    { field: "OrderNo", headerName:"OrderNr" },    
    { field: "InternalPONumber", headerName: "Internal PO" },
    {
      headerName: 'Action',
      cellRendererFramework: PurchaseregistergridbuttonComponent,
      editable: false
      //width:50
      //cellRendererParams: {
      //  onClick: this.onEditButtonClick.bind(this),
      //  label: 'Edit'
      //}
    }
    ];

  defaultColDef: ColDef = {
    // set every column width
    width: 100,
    editable: true,
    sortable: true,
    filter: true,
  };


  constructor(
    private purchaseregisterservice: PurchaseregisterService,
    private toastr: ToastrService,
    private globalService: GlobalService,
    private menuService: MenuService,
    private localeService: BsLocaleService,
    private reportService: ReportService
  ) {
    this.errorMessage = "";
    this.filesToUpload = [];
    this.selectedFileNames = [];
    this.uploadResult = "";
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    defineLocale('en-gb', enGbLocale);
    this.localeService.use('en-gb');
  }

  ngOnInit() {
   
    this.globalService.getUserInfo().subscribe((data: any) => {
      if (data != undefined) {
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globalpurchaseregisterpage);

        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {          
          this.LoadSupplier();
          this.LoadStatisticalPeriod();
          this.LoadGoodsInBranch(null);
          this.LoadShop(null, null);
          this.LoadPaymentTerm();
          this.LoadIncoterm();
          this.LoadPurchaseOrders();

          this.ClearRegisterFields();
        }
        else {
          this.showpurchaseregister = false;
          this.showpurchaseregisteralert = true;
        }

      }
    });
    this.menuService.HideUnitMenu(false);
    this.menuService.HideChart(false);
    this.menuService.ShowRegionMenu(false);
    $('#DeliveryFromTo').daterangepicker(); 

    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      defaultColDef: this.defaultColDef,
      //editType : 'fullRow',
      enableColResize: true,
      enableFilter: true,
      rowSelection: 'single',
      columnTypes: {
        numberColumn: {
          width: 83,
          filter: "agNumberColumnFilter"
        },
        nonEditableColumn: { editable: false },
      }
    }   

  }




  onGridReady(params) {
    //params.api.applyTransaction({ add: [{}] });
  }

  //this make grid responsive to div size
  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }

  onSelectionChanged(params) {
    debugger;
    var selectedRows = params.api.getSelectedRows();
    if (selectedRows[0].OrderNo) {
      let selectedPurchaseOrderHeader = this.purchaseOrderHeaders.find(i => i.OrderNo === selectedRows[0].OrderNo);
      this.selectedShops = this.purchaseOrderShops.filter(i => i.OrderNo === selectedRows[0].OrderNo).map(obj => obj);
      this.register.GoodsInBranchId = selectedPurchaseOrderHeader.BranchId;
      this.register.IncoTermId = selectedPurchaseOrderHeader.Incoterms;
      this.register.Discount = selectedPurchaseOrderHeader.OrderDiscount;
      this.register.Comments = selectedPurchaseOrderHeader.Comment;
      this.register.PaymentTermId = selectedPurchaseOrderHeader.PaymentTermId;
      this.bsValue = new Date(selectedPurchaseOrderHeader.DeliveryFromDate);
      this.maxDate = new Date(selectedPurchaseOrderHeader.DeliveryToDate);
      this.register.DeliveryFromTo = [this.bsValue, this.maxDate];
      debugger;
      // Get all shopCheckboxes
      const shopCheckboxes = Object.values(document.querySelectorAll<HTMLInputElement>('input[type="checkbox"]'));
      shopCheckboxes.map(c => c.checked = false);
      shopCheckboxes.map(c => {
        if (this.selectedShops.find(s => s.UnitNo === c.defaultValue)) {
          c.checked = true;
        }
      });

      setTimeout(() => {
        this.ScrollToSelectedRadio();

      });

      setTimeout(() => {
        this.ScrollToSelectedShopCheckbox();

      }, 1000);
    }
    else {
      this.ClearRegisterFields();
      this.selectedShops = [];
      const shopCheckboxes = Object.values(document.querySelectorAll<HTMLInputElement>('input[type="checkbox"]'));
      shopCheckboxes.map(c => c.checked = false);
    }

   

    
    //(document.querySelector('#selectedRows') as any).innerHTML =
    //  selectedRows.length === 1 ? selectedRows[0].athlete : '';
  }

  onRowEditingStopped(params) {
    debugger;
  }

  onEditButtonClick(params) {
    params.api.startEditingCell({
      rowIndex: params.rowIndex,
      colKey: 'make'
    });
  }


  LoadPurchaseOrders(): any {
    var searchPurchaseOrders = {
      BrandId: this.register.SupplierId == null ? this.defaultbrand : this.register.SupplierId,
      SeasonId: this.register.StatPeriodId
    };
    this.reportService.GetPurchaseOrders(searchPurchaseOrders).subscribe((data: any) => {
      this.purchaseOrder = data;
      this.purchaseOrderHeaders = data.map(d => {
        return {
          OrderNo: d.OrderNo,
          BrandId: d.BrandId,
          SeasonId: d.SeasonId,
          BranchId: d.BranchId,
          Incoterms: d.Incoterms,
          DeliveryFromDate: d.DeliveryFromDate,
          DeliveryToDate: d.DeliveryToDate,
          OrderDiscount: d.OrderDiscount,
          PaymentTermId: d.PaymentTermId,
          Comment: d.Comment,
          FilePate: d.FilePath,
          FileName1: d.FileName1,
          FileName2: d.FileName2,
          FileName3: d.FileName3,
          FileName4: d.FileName4,
          FileName5: d.FileName5
        }
      });
      this.register.StatPeriodId = Math.max.apply(null, this.purchaseOrderHeaders.map(function (o) { return o.SeasonId; }));  // to set default for stat peiod on page load

      /* Map Purchase Order Lines*/
      data.map(val => {
        val.PurchaseOrderLines.map(d => {
          let poLine: PurchaseOrderLineModel = {
            OrderNo: d.OrderNo,
            SKU: d.SKU,
            InternalPONumber: d.InternalPONumber,
            Currency: d.Currency,
            PCS: d.PCS,
            Value: d.Value
          }
          if (!this.purchaseOrderLines.some(e => e.OrderNo === poLine.OrderNo && e.SKU === poLine.SKU && e.InternalPONumber === poLine.InternalPONumber && e.Value === poLine.Value)) {
            this.purchaseOrderLines.push(poLine);
          }

        });
      });      
      this.rowData = this.purchaseOrderLines;
      this.rowData.push({});
      

      /* Map Purchase Order Shops*/
     data.map(val => {
        val.PurchaseOrderShops.map(d => {
          let poShop: PurchaseOrderShopModel = {
            OrderNo:val.OrderNo,
            POHeaderNo: d.POHeaderNo,
            UnitNo: d.UnitNO
          }
          if (!this.purchaseOrderShops.some(e => e.OrderNo === poShop.OrderNo && e.UnitNo === poShop.UnitNo)) {
            this.purchaseOrderShops.push(poShop);
          }
        });
      });

    });
  }
  LoadGoodsInBranch(regionId): any {
    this.purchaseregisterservice.getGoodsInBranch(regionId).subscribe((data: any) => {
      this.goodsInBranchList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadShop(regionId, goodsInBranchId): any {
    this.purchaseregisterservice.getShop(regionId, goodsInBranchId).subscribe((data: any) => {
      this.shopList = data;
      //this.register['ShopId'] = "";
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadSupplier(): any {
    this.purchaseregisterservice.getSupplier().subscribe((data: any) => {
      this.supplierList = data;
      this.register.SupplierId = this.defaultbrand;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadStatisticalPeriod(): any {
    this.purchaseregisterservice.getStatisticalPeriod().subscribe((data: any) => {
      this.statisticalPeriodList = [];
      this.statisticalPeriodList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadIncoterm(): any {
    this.purchaseregisterservice.getIncoTerm().subscribe((data: any) => {
      this.incotermList = [];
      this.incotermList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadPaymentTerm(): any {
    this.purchaseregisterservice.getPaymentTerm().subscribe((data: any) => {
      this.paymenttermList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  ClearRegisterFields(): void {
    this.register.Discount = "";
    this.register.Comment = "";
    this.register.GoodsInBranchId = "";
    this.register.ShopId = "";
    this.register.IncoTermId = "";
    this.register.PaymentTermId = "";
    this.register.DeliveryFromTo=[this.bsValue, this.maxDate];
  }
 

  
  OnGoodsInBranchChanges(event, index): any {
    //alert(this.register.GoodsInBranchId);
  }

  OnShopCheckboxChanged(event, index): any {
    if (this.selectedShops.indexOf(event) == -1) {
      this.selectedShops.push(event);
    } else {
      this.selectedShops.splice(this.selectedShops.indexOf(event), 1);
    }
  }

  ScrollToSelectedRadio() {
    // Get all radio buttons
    const radios = Object.values(document.querySelectorAll < HTMLInputElement>('input[type="radio"]'));
    // Find the checked radio button
    const checkedRadio = [...radios].find(radio => radio.checked);
  if (checkedRadio) {
    // Scroll to the checked radio button
    checkedRadio.parentElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }

  }

  ScrollToSelectedShopCheckbox() {
    // Get all radio buttons
    const shopCheckboxes = Object.values(document.querySelectorAll<HTMLInputElement>('input[type="checkbox"]'));
    // Find the checked radio button
    const checkedShops = [...shopCheckboxes].find(checkbox => checkbox.checked);
    if (checkedShops) {
      // Scroll to the checked radio button
      checkedShops.parentElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }

  }


  

  onSubmit(form: NgForm): any {

    if (this.filesToUpload.length < 6) {
      this.uploadResult = "";
      let formData: FormData = new FormData();
      for (var i = 0; i < this.filesToUpload.length; i++) {
        formData.append('uploadedFiles', this.filesToUpload[i], this.filesToUpload[i].name);
      }
      form['AlterationNecessary'] = parseInt(form['AlterationNecessary']);
      this.purchaseregisterservice.SavePurchaseRegister(form).subscribe((data: any) => {

        formData.append('orderid', data);
        this.purchaseregisterservice.SaveFiles(formData).subscribe((data: any) => {
          this.toastr.success(data);
          //this.showinternalpo = false;
          this.register = {
            BuyerId: form['BuyerId'],
            OrderTypeId: form['OrderTypeId'],
            GoodsInBranchId: form['GoodsInBranchId'],
            ShopId: form['ShopId'],
            SupplierId: form['SupplierId'],
            POSupplierId: form['POSupplierId'],
            ContactId: form['ContactId'],
            SubOrderTypeId: form['SubOrderTypeId'],
            IncoTermId: form['IncoTermId'],
            CountryId: form["CountryId"],
            AlterationNecessary: parseInt(form['AlterationNecessary']),
            ContactEmail: form['ContactEmail'],
            InternalPOnr: "",
            DeliveryFromTo: [form['DeliveryFromTo'][0], form['DeliveryFromTo'][1]],
            ContactPhone: form['ContactPhone'],
            Markup: form['Markup'],
            FrieghtCost: form['FrieghtCost'],
            OrderNumber: "",
            Pieces: "",
            Value: "",
            ProductGroupId: "",
            CurrencyId: "",
            Discount: "",
            StatPeriodId: "",
            Comment: "",
          };
          //this.NetValue = "";
          //this.buyers = {
          //  BuyerFirstName: "",
          //  BuyerLastName: "",
          //}
          //this.contacts = {
          //  ContactName: "",
          //  PhoneNumber: "",
          //  ContactEmail: "",
          //  ContactPOSupplier: "",
          //}
         // this.OnChangeOrderType(form['OrderTypeId']);
        },
          (err: HttpErrorResponse) => {
            this.toastr.error(err.error);
          });
      },
        (err: HttpErrorResponse) => {
          this.toastr.error(err.error);
          form['AlterationNecessary'] = 0;
          //this.ClearRegisterFields();
        });

    }
    else {
      this.toastr.warning("File upload exceeded more then 5.");
    }

  }

  //checkValue(isChecked) {

  //  if (this.isChecked) {
  //    this.valueLabel = "Net Value";
  //    this.checkboxlabel = "Gross Value";
  //    this.OnChangeDiscount();
  //    this.OnChangeValue();
  //  }
  //  else {
  //    this.valueLabel = "Gross Value";
  //    this.checkboxlabel = "Net Value";
  //    this.OnChangeDiscount();
  //    this.OnChangeValue();
  //  }

  //}

  ConvertDateTime(str) {
    var mnths = {
      Jan: "01", Feb: "02", Mar: "03", Apr: "04", May: "05", Jun: "06",
      Jul: "07", Aug: "08", Sep: "09", Oct: "10", Nov: "11", Dec: "12"
    },
      date = str.split(" ");
    return [date[3], mnths[date[1]], date[2]].join("/");
  }

  upload() {
    if (this.filesToUpload.length > 5) {
      this.toastr.warning('Please select a maximum of 5 PDF files to upload!');
    }
  }

  fileChangeEvent(fileInput: any) {
    this.uploadResult = "";
    this.filesToUpload = <Array<File>>fileInput.target.files;
    for (let i = 0; i < this.filesToUpload.length; i++) {
      this.selectedFileNames.push(this.filesToUpload[i].name);
    }
  }
  
}
