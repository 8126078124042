import { Component, OnInit, ViewChild, ElementRef, Attribute } from '@angular/core';
import { FormGroup, Validators, FormBuilder, NgForm, FormGroupDirective, FormControl, AbstractControl, Validator } from '@angular/forms';
import { UserService } from '../service/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../service/global.service';
import { AccountService } from '../service/account.service';
import { MenuService } from '../service/menu.service';
declare var bootbox: any;
declare var $;
@Component({
  selector: 'app-usermaintenance',
  templateUrl: './usermaintenance.component.html',
  styleUrls: ['./usermaintenance.component.css'],
})
export class UsermaintenanceComponent implements OnInit {
  model: any = {};
  closeResult: string;
  employeeList: any;
  region: any;
  UnitId: number;
  userList: any = [];
  employeeid: number;
  roleWrite: boolean;
  roleUpdate: boolean;
  roleDelete: boolean;
  userRoles: any;
  btnSave: string = 'Save';
  DisableUsername: boolean = false;
  DisablePassword: boolean = false;
  DisableConfirmPassword: boolean = false;
  DisableEmployee: boolean = false;
  showusermaintenance: boolean = true;
  showusermaintenancealert: boolean = false;
  constructor(
    private accountService: AccountService,
    private userService: UserService,
    private globalService: GlobalService,
    private toastr: ToastrService,
    private menuService: MenuService
  ) { }
  ngOnInit() {
    this.menuService.HideUnitMenu(true);
    this.menuService.HideChart(false);
    this.menuService.ShowRegionMenu(true);
    this.userService.newEmployeeSubject.subscribe((data: any) => {
      this.UnitId = data;
      this.EmployeesByUnitId(data);
      this.LoadTable(data);

    });
    this.globalService.UserClaims().subscribe((data: any) => {
      if (data != undefined) {
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globalusermaintenancepage);
        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {
          this.UnitId = this.accountService.globalUnitId;
          if (this.userRoles != '') {
            this.roleWrite = this.userRoles.Write;
            this.roleUpdate = this.userRoles.Update;
            this.roleDelete = this.userRoles.Delete;
          }
          if (this.UnitId == undefined) {
            this.UnitId = data.Unit_Id;
          }
          this.EmployeesByUnitId(this.UnitId);
          this.LoadTable(this.UnitId);
          this.model = {
            Username: '',
            User_Id: 0,
            Password: '',
            ConfirmPassword: '',
            Employee_Id: '',
            Active: 1
          }

        }
        else {
          this.showusermaintenance = false;
          this.showusermaintenancealert = true;
        }
      }
    });
  }

  LoadTable(unitId): any {
    this.userService.getUserList(unitId).subscribe((data: any) => {
      this.userList = [];
      
      this.userList = data;

      this.LoadDataTable();
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });

  }

  DeleteUsers(selectedItem): void {
    bootbox.confirm("Do you want to delete the data?",
      (result) => {
        if (result) {
          this.userService.deleteUserInformation(selectedItem.User_Id).subscribe((data: any) => {
            this.LoadTable(this.UnitId);
            this.toastr.success("Successfully deleted data");
          },
            (err: HttpErrorResponse) => {
              this.toastr.error(err.error);
            });
        }
      });
  }


  onSubmit(f) {
    
    if (this.model.invalid) {
      return;
    }
    this.model['Active'] = parseInt(this.model['Active']);
    this.userService.saveUserInformation(this.model).subscribe((data: any) => {
      this.toastr.success(data);
      this.LoadTable(this.UnitId);
      this.btnSave = 'Save';
      this.CancelUserForm(f);
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  EmployeesByUnitId(selectedunitid) {
    this.userService.getEmployeeByUnitId(selectedunitid).subscribe((data: any) => {
      this.employeeList = [];
      this.employeeList = data;
      this.employeeid = 0;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });

  }
  CancelUserForm(f): void {
    
    f.resetForm({
      Username: '',
      User_Id: 0,
      Password: '',
      ConfirmPassword: '',
      Employee_Id: '',
      Active: 1
    });
    this.model;
    this.btnSave = 'Save';
    this.DisableUsername = false;
    this.DisablePassword = false;
    this.DisableConfirmPassword = false;
    this.DisableEmployee = false;
  }
  UpdateUser(items): void {

    this.btnSave = 'Update';
    this.model = {
      Username: items.Username,
      User_Id: items.User_Id,
      Password: '',
      ConfirmPassword: '',
      Employee_Id: items.Employee_Id,
      Active: items.Active == true ? 1 : 0
    }
    this.DisableUsername = true;
    this.DisablePassword = true;
    this.DisableConfirmPassword = true;
    this.DisableEmployee = true;
  }
  LoadDataTable(): void {
    $("#datatable").dataTable().fnDestroy();
    setTimeout(function () {
      $('#datatable').DataTable({
        'paging': true,
        'lengthChange': true,
        'searching': true,
        'ordering': true,
        'info': true,
        'autoWidth': false,
        'bRetrieve': true
      })
    }, 0);
  }

}

