import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UserMaintenance, IdentityClaims, IUserGroups, UserGroup } from '../model/account.model';
import { Subject, Observable } from 'rxjs';
import { GlobalService } from '../service/global.service';
@Injectable({
  providedIn: 'root'
})
export class UserService {
   
  public newEmployeeSubject = new Subject<any>();
  
  constructor(private http: HttpClient, private globalService: GlobalService) { }

  getEmployeeByUnitId(unitId) {
    
    let params = new HttpParams().set("unitId", unitId);
    return this.http.get(this.globalService.baseUrl + '/api/getEmployeeByUnitId', { params: params });
  }
  getUserList(unitId) {
    let params = new HttpParams().set("unitId", unitId);
    return this.http.get(this.globalService.baseUrl +'/api/getUserList', { params: params });
  }
  saveUserInformation(user: UserMaintenance) {
    return this.http.post(this.globalService.baseUrl + '/api/saveUserInfo', user);
  }

  deleteUserInformation(userId) {
    const body: any = {
      User_Id: userId,
    }

    return this.http.post(this.globalService.baseUrl +'/api/deleteUser', body);
  }
  LoadEmployeeOnUnitChange(selectedUnit) {
    this.newEmployeeSubject.next(selectedUnit);
  }
  getAvailableGroups(): Observable<any> {
    return this.http.get<IUserGroups[]>(this.globalService.baseUrl +'/api/getAvailableGroups');
  }
  getAvailableGroupsByUserId(userId): any {
    let params = new HttpParams().set("userId", userId);
    return this.http.get<IUserGroups[]>(this.globalService.baseUrl +'/api/getAvailableGroupsByUserId', { params: params });
  }
  addGroupsForUser(availableGroups, userId): any {
    const body: UserGroup = {
      User_Group_ID: availableGroups,
      User_Id: userId
    }
    return this.http.post(this.globalService.baseUrl +'/api/addGroupsForUser', body);
  }

  removeGroupsForUser(availableGroups, userId): any {
    const body: UserGroup = {
      User_Group_ID: availableGroups,
      User_Id: userId
    }
    return this.http.post(this.globalService.baseUrl +'/api/removeGroupsForUser', body);
  }
  SaveUserGroups(model: any): any {
    return this.http.post(this.globalService.baseUrl +'/api/SaveUserGroup', model);
  }
  getObjects(): any {
    return this.http.get(this.globalService.baseUrl +'/api/getObjects');
  }
  AddAccessOnUserGroups(model: any): any {
    
    return this.http.post(this.globalService.baseUrl +'/api/SaveUserGroupAccess', model);
  }
  getAssociatedObjects(User_Group_Id: string): any {
    let params = new HttpParams().set("userGroupId", User_Group_Id);
    return this.http.get(this.globalService.baseUrl +'/api/getAssociatedAccessObjects', { params: params });
  }
  RemoveAccessOnUserGroups(model: any): any {
    return this.http.post(this.globalService.baseUrl +'/api/RemoveUserGroupAccess', model);
  }
  LoadAccessTables(User_Group_Id: any): any {
    let params = new HttpParams().set("userGroupId", User_Group_Id);
    return this.http.get(this.globalService.baseUrl +'/api/getAccessTables', { params: params });
  }
  UpdateAccess(item: any): any {
    return this.http.post(this.globalService.baseUrl +'/api/UpdateAccess', item);
  }
  SavePassword(value: any): any {
    return this.http.post(this.globalService.baseUrl +'/api/SavePassword', value);
  }
  GetAllUserList(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetAllUsersList');
  }
  SaveUserOverview(value: any): any {
    return this.http.post(this.globalService.baseUrl + '/api/SaveUserOverview', value);
  }
  GetDescriptionInfo(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetDescriptionInfo');
  }
}
