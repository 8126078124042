import { Component, OnInit } from '@angular/core';
import { MenuService } from '../service/menu.service';
import { GlobalService } from '../service/global.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountService } from '../service/account.service';
import { CurrencyService } from '../service/currency.service';
import { ToastrService } from 'ngx-toastr';
declare var $;
@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.css']
})
export class RegionComponent implements OnInit {
  model: any = {};
  userRoles: any;
  showregion: boolean = true;
  showregionalert: boolean = false;
  regionsList: any;
  buttonName: string = 'Save';
  constructor(
    private menuService: MenuService,
    private globalService: GlobalService,
    private accountService: AccountService,
    private currencyService: CurrencyService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.menuService.HideUnitMenu(true);
    this.menuService.HideChart(false);
    this.menuService.ShowRegionMenu(true);
    this.globalService.UserClaims().subscribe((data: any) => {
      if (data != undefined) {
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globalregionmaintenancepage);
        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {
          this.LoadRegions();

        }
        else {
          this.showregion = false;
          this.showregionalert = true;
        }
      }
    });
    this.model = {
      Active: 1,
      Region_Id: 0
    }
  }

  onSubmit() {
    if (this.model.invalid) {
      return;
    }
    
    this.model['Active'] = parseInt(this.model['Active']);
    this.currencyService.SaveRegions(this.model).subscribe((data: any) => {
      this.toastr.success(data);
      this.LoadRegions();
      this.buttonName = 'Save';
      this.ClearForm();
      this.currencyService.LoadRegionsDropdown(true);
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadRegions(): any {
    this.accountService.getAllRegions().subscribe((data: any) => {
      this.regionsList = data;
      this.LoadDataTable();
    },
      (err: HttpErrorResponse) => {

      });
  }
  UpdateRegions(item): any {
    
    this.model = {
      Region_Id: item.Region_Id,
      Region_Name: item.Region_Name,
      Active: item.Active = item.Active == true ? 1 : 0,
    }
    this.buttonName = "Update";
  }
  ClearForm() {
    this.model = {
      Region_Id: 0,
      Region_Name: '',
      Active: 1,
    }
    this.buttonName = "Save";
  }
  LoadDataTable(): void {
    $("#datatable").dataTable().fnDestroy();
    setTimeout(function () {
      $('#datatable').DataTable({
        'paging': true,
        'lengthChange': true,
        'searching': true,
        'ordering': true,
        'info': true,
        'autoWidth': false,
        'bRetrieve': true
      })
    }, 0);
  }

}
