import { HttpInterceptor, HttpRequest, HttpHandler, HttpUserEvent, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/internal/operators';
import { AccountService } from '../service/account.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    if (req.headers.get('No-auth') == 'True')
      return next.handle(req.clone());
   
    if (localStorage.getItem('userToken') != null) {
      const clonedreq = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + localStorage.getItem('userToken'))
      });

      return next.handle(clonedreq).pipe(tap
        (
        succ => { },
          err => {
            
            if (err.status == 401) {
              //this.router.navigateByUrl('/');
              //alert("Session Timeout");
            }
            
        }
        ));
    }
    else {
      this.router.navigateByUrl('/');
    }
  }
}

