import { Component, OnInit } from '@angular/core';
import { MenuService } from '../service/menu.service';
import { GlobalService } from '../service/global.service';
import { AccountService } from '../service/account.service';
import { CurrencyService } from '../service/currency.service';
import { PurchaseregisterService } from '../service/purchaseregister.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ColDef, GridOptions, GridSizeChangedEvent } from 'ag-grid-community';

@Component({
  selector: 'app-suppliercontact',
  templateUrl: './suppliercontact.component.html',
  styleUrls: ['./suppliercontact.component.css']
})
export class SuppliercontactComponent implements OnInit {

  buttonName: string = 'Save';
  userRoles: any;
  model: any = {};
  submitted = false;
  showSupplierContact: boolean = false;
  supplierList: any = [];

  // ag-grid Settings
  private gridApi;
  private gridColumnApi;
  gridOptions: GridOptions;
  rowData = [];
  columnDefs = [
    { field: "ContactId", headerName: "ContactId", sort: 'desc', hide: true },
    { field: "ContactName", headerName: "ContactName" },
    { field: "POSupplierId", headerName: "SupplierId" },
    { field: "ContactPOSupplier", headerName: "ContactSupplier" },
    { field: "PhoneNumber", headerName: "PhoneNumber" },
    { field: "ContactEmail", headerName: "ContactEmail" }

  ];
  defaultColDef: ColDef = {
    // set every column width
    // width: 100,
    minWidth: 80,
    //maxWidth: 150,
    editable: false,
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1
  };

  constructor(
    private menuService: MenuService,
    private globalService: GlobalService,
    private accountService: AccountService,
    private currencyService: CurrencyService,
    private purchaseregisterservice: PurchaseregisterService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService
  ) {


  }

  ngOnInit() {
    this.menuService.HideUnitMenu(false);
    this.menuService.HideChart(false);
    this.menuService.ShowRegionMenu(false);
    this.globalService.UserClaims().subscribe((data: any) => {
      if (data != undefined) {
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globalporegistrationpage);
        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {
          this.LoadSupplier();
          this.LoadSupplierContact(null);
          this.ClearForm();
        }
        else {
          this.showSupplierContact = true;
        }
      }
    });



    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      defaultColDef: this.defaultColDef,
      //editType : 'fullRow',
      rowSelection: 'single',
      columnTypes: {
        numberColumn: {
          width: 83,
          filter: "agNumberColumnFilter"
        },
        nonEditableColumn: { editable: false },
      },
      context: { componentParent: this },
      stopEditingWhenGridLosesFocus: true,
      suppressScrollOnNewData: true,


    }
  }

 
  ClearForm(): any {
    this.submitted = false;
    this.model = {
      ContactId: '',
      ContactName: '',
      PhoneNumber: '',
      ContactEmail: '',
      POSupplierId: ''
    }
    this.buttonName = 'Save';
  }

  LoadSupplier(): any {
    this.purchaseregisterservice.getPOSupplier().subscribe((data: any) => {
      this.supplierList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  onRowDoubleClicked(params) {
    debugger;
    this.buttonName = 'Update';
    var selectedRows = params.api.getSelectedRows();
    this.model.ContactId = selectedRows[0].ContactId;
    this.model.ContactName = selectedRows[0].ContactName;
    this.model.PhoneNumber = selectedRows[0].PhoneNumber;
    this.model.ContactEmail = selectedRows[0].ContactEmail;
    this.model.POSupplierId = selectedRows[0].POSupplierId;
  }

  LoadSupplierContact(poSupplierId): any {
    this.accountService.getAllSupplierContact(poSupplierId).subscribe((data: any) => {
      this.rowData = data;
    },
      (err: HttpErrorResponse) => {

      });
  }

  onSupplierBrandChange(event): any {
    this.model.POSupplierId = event;
  }

  onSubmit() {
    //debugger;
    //this.submitted = true;
    this.ngxService.start();
    this.currencyService.SaveSupplierContact(this.model).subscribe((data: any) => {
      this.toastr.success(data);
      this.ClearForm();
      this.LoadSupplierContact(null);
      this.ngxService.stop();
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
        this.ngxService.stop();
      });
  }


}
