import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MenuService } from '../../service/menu.service'
import { HttpErrorResponse } from '@angular/common/http';
import { AccountService } from '../../service/account.service';
import { IdentityClaims } from '../../model/account.model';
import { getLocaleDateTimeFormat } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare var $;
declare var bootbox: any;
@Component({
  selector: 'app-appmenu',
  templateUrl: './appmenu.component.html',
  styleUrls: ['./appmenu.component.css']
})
export class AppmenuComponent implements OnInit {
  appmenuform: FormGroup;
  itemForMenu: any;
  userClaims: any;
  userName: string;
  date: Date = new Date();
  result: number;
  FromDate = new Date();
  constructor(private fb: FormBuilder,
    private menuService: MenuService,
    private accountService: AccountService,
    private toastr: ToastrService) {

  }

  ngOnInit() {
    this.createForm();
    this.menuItems();
    this.accountService.getUserClaims().subscribe((data: IdentityClaims) => {
      this.userName = data.UserName;

    });

  }
  createForm() {
    this.appmenuform = this.fb.group({
      Search: [''],
    });
  }
  menuItems() {
    this.menuService.getParentMenu().subscribe((data: any) => {

      //this.itemForMenu = data;
      if (data.length == 1) {
        if (data[0].PasswordChangedDays > 90) {
          setTimeout(function () {
            bootbox.alert("It has been 3 months the password have been changed. Please change your password and Login.");

          }, 0);
          this.itemForMenu = data;
        }
      }
      else {
        this.itemForMenu = data;
      }

      setTimeout(function () {
        $('.sidebar-menu').tree();
      }, 0);

    },
      (err: HttpErrorResponse) => {
        
        alert(localStorage.getItem('userToken'));
        this.toastr.error(err.message);
      });
  }
}
