import { Component, OnInit } from '@angular/core';
import { AccountService } from '../service/account.service';
import { UserService } from '../service/user.service';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { GlobalService } from '../service/global.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { EmployeeService } from '../service/employee.service';
import { MenuService } from '../service/menu.service';
import { Router, ActivatedRoute } from '@angular/router';
declare var $;
@Component({
  selector: 'app-employeemaintenance',
  templateUrl: './employeemaintenance.component.html',
  styleUrls: ['./employeemaintenance.component.css']
})
export class EmployeemaintenanceComponent implements OnInit {
  employeeMaintenanceForm: FormGroup;
  buttonName: string = 'Save';
  employeeList: any;
  departmentList: any;
  UnitId: number;
  submitted = false;
  showemployeemaintenance: boolean = true;
  showemployeemaintenancealert: boolean = false;
  roleWrite: boolean;
  roleUpdate: boolean;
  roleDelete: boolean;
  
  userRoles: any;
  constructor(
    private accountService: AccountService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private toastr: ToastrService,
    private employeeService: EmployeeService,
    private menuService: MenuService,
    private router: ActivatedRoute) {
    this.employeeMaintenanceForm = this.formBuilder.group({
      Employee_Id: [],
      Employee_Name: ['', Validators.required],
      Department_Id: ['', Validators.required],
      Address: ['', Validators.required],
      Active: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.menuService.HideChart(false);
    const id = +this.router.snapshot.paramMap.get('id');
    this.menuService.HideUnitMenu(true);
    this.menuService.ShowRegionMenu(true);
    this.userService.newEmployeeSubject.subscribe((data: any) => {
      this.UnitId = data;
      this.EmployeesByUnitId(data);
      this.LoadPrimarySubjectAreaByUnitId(data);

    });
    this.globalService.UserClaims().subscribe((data: any) => {
      
      if (data != undefined) {
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globalemployeemaintenancepage);
        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {
          if (this.userRoles != '') {
            this.roleWrite = this.userRoles.Write;
            this.roleUpdate = this.userRoles.Update;
            this.roleDelete = this.userRoles.Delete;
          }
          this.UnitId = this.accountService.globalUnitId;
          if (this.UnitId == undefined)
            this.UnitId = data.Unit_Id;
          this.EmployeesByUnitId(this.UnitId);
          this.LoadPrimarySubjectAreaByUnitId(this.UnitId);
          this.employeeMaintenanceForm.controls['Active'].setValue(1);
        }
        else {
          this.showemployeemaintenance = false;
          this.showemployeemaintenancealert = true;
        }
      }
    });
  }

  EmployeesByUnitId(selectedunitid): any {
    this.userService.getEmployeeByUnitId(selectedunitid).subscribe((data: any) => {
      this.employeeList = [];
      this.employeeList = data;
      this.LoadDataTable();

    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  LoadPrimarySubjectAreaByUnitId(selectedunitid): any {
    this.employeeService.GetPrimarySubjectArea(selectedunitid).subscribe((data: any) => {
      this.departmentList = [];
      this.departmentList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  get f() { return this.employeeMaintenanceForm.controls; }
  onSubmit(form: NgForm) {
    this.submitted = true;

    if (this.employeeMaintenanceForm.invalid) {
      return;
    }

    this.employeeService.SaveEmployee(this.employeeMaintenanceForm.value, this.UnitId).subscribe((data: any) => {
      this.toastr.success(data);
      this.EmployeesByUnitId(this.UnitId);
      this.ClearFields();

    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });

  }
  ClearFields(): void {
    this.buttonName = 'Save';
    this.employeeMaintenanceForm.controls['Employee_Id'].setValue('');
    this.employeeMaintenanceForm.controls['Employee_Name'].setValue('');
    this.employeeMaintenanceForm.controls['Department_Id'].setValue('');
    this.employeeMaintenanceForm.controls['Address'].setValue('');
    this.employeeMaintenanceForm.controls['Active'].setValue(1);
  }
  CancelEmployee(): void {
    this.buttonName = 'Save';
    this.ClearFields();
  }

  UpdateEmployee(item): void {

    this.buttonName = 'Update';
    this.employeeMaintenanceForm.controls['Employee_Id'].setValue(item.Employee_Id);
    this.employeeMaintenanceForm.controls['Employee_Name'].setValue(item.Employee_Name);
    this.employeeMaintenanceForm.controls['Department_Id'].setValue(item.Department_Id);
    this.employeeMaintenanceForm.controls['Address'].setValue(item.Address);
    var value = item.Active == true ? 1 : 0;
    this.employeeMaintenanceForm.controls['Active'].setValue(value);
  }

  LoadDataTable(): void {
    $("#datatable").dataTable().fnDestroy();
    setTimeout(function () {
      $('#datatable').DataTable({
        'paging': true,
        'lengthChange': true,
        'searching': true,
        'ordering': true,
        'info': true,
        'autoWidth': false,
        'bRetrieve': true
      })
    }, 0);
  }
}
