import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './auth/auth.guard';
import { EmployeemaintenanceComponent } from './employeemaintenance/employeemaintenance.component';
import { UsermaintenanceComponent } from './usermaintenance/usermaintenance.component';
import { UsergroupsComponent } from './usergroups/usergroups.component';
import { PurchaseregisterComponent } from './purchaseregister/purchaseregister.component';
import { UseraccessComponent } from './useraccess/useraccess.component';
import { UsergroupaccessComponent } from './usergroupaccess/usergroupaccess.component';
import { UserpreferencesComponent } from './userpreferences/userpreferences.component';
import { UseroverviewComponent } from './useroverview/useroverview.component';
import { AssignemployeeComponent } from './assignemployee/assignemployee.component';
import { RegionComponent } from './region/region.component';
import { UnitComponent } from './unit/unit.component';
import { SupplierComponent } from './supplier/supplier.component';
import { CurrencyComponent } from './currency/currency.component';
import { ReportComponent } from './report/report.component';
import { InfoComponent } from './info/info.component';
import { SmallreportviewComponent } from './smallreportview/smallreportview.component';
import { SmallreportsheetComponent } from './smallreportsheet/smallreportsheet.component';
import { PurchaseditComponent } from './purchasedit/purchasedit.component';
import { PurchaseregisterbulkComponent } from './purchaseregisterbulk/purchaseregisterbulk.component';
import { PoregistrationComponent } from './poregistration/poregistration.component';
import { PoregistrationReportComponent } from './poregistration-report/poregistration-report.component';
import { ShopmaintenanceComponent } from './shopmaintenance/shopmaintenance.component';
import { PoregistrationwithcontactComponent } from './poregistrationwithcontact/poregistrationwithcontact.component';
import { PoregistrationReportWithcontactComponent } from './poregistration-report-withcontact/poregistration-report-withcontact.component';
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      { path: 'employeemaintenance', component: EmployeemaintenanceComponent, runGuardsAndResolvers: 'always'},
      { path: 'usermaintenance', component: UsermaintenanceComponent },
      { path: 'useraccess', component: UseraccessComponent },
      { path: 'usergroups', component: UsergroupsComponent },
      { path: 'purchaseregister', component: PurchaseregisterComponent },
      { path: 'usergroupaccess', component: UsergroupaccessComponent },
      { path: 'userpreferences', component: UserpreferencesComponent },
      { path: 'useroverview', component: UseroverviewComponent },
      { path: 'assignemployee', component: AssignemployeeComponent },
      { path: 'purchasedit', component: PurchaseditComponent },
      { path: 'region', component: RegionComponent },
      { path: 'unit', component: UnitComponent },
      { path: 'supplier', component: SupplierComponent },
      { path: 'currency', component: CurrencyComponent },
      { path: 'report', component: ReportComponent },
      { path: 'info', component: InfoComponent },
      { path: 'smallreportview', component: SmallreportviewComponent },
      { path: 'smallreportsheet', component: SmallreportsheetComponent },
      //{ path: 'purchaseregisterbulk', component: PurchaseregisterbulkComponent },
      { path: 'poregistration', component: PoregistrationComponent },
      { path: 'reportPO', component: PoregistrationReportComponent },
      { path: 'shopMaintenance', component: ShopmaintenanceComponent },
      { path: 'poregistrationwithcontact', component: PoregistrationwithcontactComponent },
      { path: 'reportPOWithContact', component: PoregistrationReportWithcontactComponent }
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

//imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
//{ useHash: true }
