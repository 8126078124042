import { Injectable } from '@angular/core';
import { IdentityClaims } from '../model/account.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  //baseUrl: string = 'DEV_PUR';
  baseUrl: string = '';
  //baseUrl: string = 'pur';
  globalRegionId: string;
  globalUnitId: string;
  globalPasswordChangeDays: number = 90;
  globalassignemployeepage: string = 'Assign Employee';
  globalcurrencypage: string = 'Currency Maintenance';
  globalemployeemaintenancepage: string = 'Employee Maintenance';
  globalinfopage: string = 'Description & Info';
  globalpurchaseeditpage: string = 'Purchase Edit';
  globalreportpage: string = 'Report OldBeforeMarch';
  globalpurchaseregisterpage: string = 'Registration';
  globalsmallreportpage: string = 'ReportSmall OldBeforeMarch';
  globalporegistrationpage: string = 'PORegistration';
  globalporegistrationwithcontactpage: string = 'PORegistrationWithContact';
  globalporeportpage: string = 'PO Report';
  globalshopmaintenancepage: string = 'ShopMaintenance';
  globaluseraccesspage: string = 'User Access';
  globalusergroupaccesspage: string = 'Usergroup Access';
  globalusergroupspage: string = 'User Groups';
  globalusermaintenancepage: string = 'User Maintenance';
  globalregionmaintenancepage: string = 'Region Maintenance';
  globalunitmaintenancepage: string = 'Unit Maintenance';
  globaluseroverviewpage: string = 'Overview';
  globaluserpreferencespage: string = 'Preferences';
  globalFilterFromDate: "";
  globalFilterToDate: "";
  globalFilterBuyerId: "";
  globalFilterSupplierId: "";
  globalFilterPOSupplierId: "";
  globalFilterContactId: "";
  globalFilterGoodsInBranchId: "";
  globalFilterShopId: "";
  globalFilterOrderTypeId: "";
  globalFilterSubOrderTypeId: "";
  globalFilterCurrencyId: "";
  globalFilterIncoTermId: "";
  globalFilterStatPeriodId: "";
  globalFilterAlterationNecessary: "";
  globalFilterOrderConfirmation: "";
  globalFilterInternalPONo: "";
  globalFilterUserId: "";
  globalFilterRemoveFilterChecked: boolean;
  globalFilterCount: number = 0;
  globalbuyerinfo: boolean = false;
  globalsupplierinfo: boolean = false;
  globalposupplierinfo: boolean = false;
  globalcontactinfo: boolean = false;
  globalgoodsinbranchinfo: boolean = false;
  globalshopinfo: boolean = false;
  globalordertypeinfo: boolean = false;
  globalsubordertypeinfo: boolean = false;
  globalcurrencyinfo: boolean = false;
  globalincoterminfo: boolean = false;
  globalstatperiodinfo: boolean = false;
  globalalterationnecessaryinfo: boolean = false;
  globalorderconfirmationinfo: boolean = false;
  globalinternalponoinfo: boolean = false;
  globalusernameinfo: boolean = false;
  constructor(private http: HttpClient) { }
  UserClaims(): Observable<any> {
    return this.http.get<IdentityClaims[]>(this.baseUrl + '/api/GetUserClaims');
  }

  getUserInfo(): any {
    return this.http.get(this.baseUrl + '/api/GetUserInfo');
  }

  getUserRoles(userRolesList, pageName): any {
    return userRolesList.find(record => record.Object_Name === pageName);
  }
}

