import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Employee, AssignEmployee } from '../model/account.model';
import { GlobalService } from '../service/global.service';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  public regionSubject = new Subject<any>();
  constructor(private http: HttpClient, private globalService: GlobalService) { }
  
  LoadRegionsOnChange(allregionsaccess) {
    
    this.regionSubject.next(allregionsaccess);
  }
  GetPrimarySubjectArea(unitId) {
    let params = new HttpParams().set("unitId", unitId);
    return this.http.get(this.globalService.baseUrl + '/api/GetPrimarySubjectArea', { params: params });
  }
  SaveEmployee(value: Employee, unitId) {
    const body: Employee = {
      Employee_Id: value.Employee_Id,
      Employee_Name: value.Employee_Name,
      Department_Id: value.Department_Id,
      Active: value.Active,
      Unit_Id: unitId,
      Address: value.Address
    }
    return this.http.post(this.globalService.baseUrl + '/api/SaveEmployee', body);
  }
  AvailableUnits(): any {
    return this.http.get(this.globalService.baseUrl + '/api/AvailableUnits');
  }
  UpdateMainUnit(items, selectedUnitId): any {
    const body: AssignEmployee = {
      Employee_Id: items.Employee_Id,
      Unit_Id: selectedUnitId,
    }
    return this.http.post(this.globalService.baseUrl + '/api/UpdateMainUnit', body);
  }
  LoadUnitsByEmployeeId(EmployeeId: any): any {
    let params = new HttpParams().set("EmployeeId", EmployeeId);
    return this.http.get(this.globalService.baseUrl + '/api/LoadUnitsByEmployeeId', { params: params });
  }

  AssignAddUnits(availableUnits: any, Employee_Id: number): any {
    const body: AssignEmployee = {
      Employee_Id: Employee_Id,
      Unit_Id: availableUnits,
    }
    return this.http.post(this.globalService.baseUrl + '/api/AssignAddUnits', body);
  }
  AssignRemoveUnits(associatedUnits: any, Employee_Id: number): any {
    const body: AssignEmployee = {
      Employee_Id: Employee_Id,
      Unit_Id: associatedUnits,
    }
    return this.http.post(this.globalService.baseUrl + '/api/AssignRemoveUnits', body);
  }

  GetEmployeeInfoByUserId(userId) {
    let params = new HttpParams().set("userId", userId);
    return this.http.get(this.globalService.baseUrl + '/api/getEmployeeByUserId', { params: params });
  }
}
