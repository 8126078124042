import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalService } from '../service/global.service'
import { Observable } from 'rxjs';
import { Account, UserMaintenance } from '../model/account.model';
import { Response } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  
  globalUnitId: any;
  url: string = '';
  constructor(private http: HttpClient, private globalService: GlobalService) { }

  userAuthentication(username, password) {
    var data = "username=" + username + "&password=" + password + "&grant_type=password";
    var requestHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });

    return this.http.post(this.globalService.baseUrl + '/token', data, { headers: requestHeader });

  }
  getUnitsOnLoad(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetUnitsOnLogin');
  }
  getUserClaims() {

    return this.http.get(this.globalService.baseUrl + '/api/GetUserClaims');
  }

  getRegion() {
    //let params = new HttpParams().set("allregionaccess", allregionaccess);
    return this.http.get(this.globalService.baseUrl + '/api/GetRegion');
  }
  getUnit(regionId, allregionaccess) {
    
    let params = new HttpParams().set('regionId', regionId).set('allregionaccess', allregionaccess);
    return this.http.get(this.globalService.baseUrl + '/api/GetUnit', { params: params });
  }

  storeUnitId(unitId) {
    this.globalUnitId = unitId;
  }

  retrieveUnitId() {

    return this.globalUnitId;
  }
  test(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetData');
  }
  getAllRegions(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetAllRegions');
  }
  getAllUnits(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetAllUnits');
  }
  GetUnitsForEmployee(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetUnitsForEmployee');
  }
  GetReportInfoForChart(RegionId): any {
    let params = new HttpParams().set('RegionId', RegionId);
    return this.http.get(this.globalService.baseUrl + '/api/GetReportInfoForChart', { params: params });
  }
  GetTotalPurchaseOrderCount(RegionId): any {
    let params = new HttpParams().set('RegionId', RegionId);
    return this.http.get(this.globalService.baseUrl + '/api/GetTotalPurchaseOrderCount', { params: params });
  }

  getAllSupplierContact(posupplierid): any {
    let params = new HttpParams().set("posupplierid", posupplierid);
    return this.http.get(this.globalService.baseUrl + '/api/GetAllSupplierContact', { params: params });
  }
}
