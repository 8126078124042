import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../service/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { PurchaseregisterService } from '../service/purchaseregister.service';
import { AccountService } from '../service/account.service';
import { NgForm } from '@angular/forms';
import { GlobalService } from '../service/global.service';
import { MenuService } from '../service/menu.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
declare var $;
@Component({
  selector: 'app-purchaseregister',
  templateUrl: './purchaseregister.component.html',
  styleUrls: ['./purchaseregister.component.css']
})
export class PurchaseregisterComponent implements OnInit {
  bsValue = new Date();
  DeliveryFromTo: Date[];
  maxDate = new Date();
  register: any = {};
  buyers: any = {};
  contacts: any = {};
  regionId: number;
  pageName: string = 'Registration';
  buyerList: any = [];
  orderTypeList: any = [];
  goodsInBranchList: any = [];
  shopList: any = [];
  supplierList: any = [];
  posupplierList: any = [];
  contactList: any = [];
  productgroupList: any = [];
  currencyList: any = [];
  statisticalPeriodList: any = [];
  subordertypeList: any = [];
  incotermList: any = [];
  countryDeliveryList: any = [];
  errorMessage: string;
  NetValue: string = "";
  filesToUpload: Array<File>;
  selectedFileNames: string[] = [];
  public isLoadingData: Boolean = false;
  @ViewChild('fileUpload') fileUploadVar: any;
  userRoles: any;
  uploadResult: any;
  res: Array<string>;
  showpurchaseregister: boolean = true;
  showpurchaseregisteralert: boolean = false;
  isChecked: boolean;
  valueLabel: string = "Gross Value";
  showinternalpo: boolean = false;
  filteredContact = [];
  checkboxlabel: string = "Net Value";
  phoneNumbr = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
  constructor(
    private purchaseregisterservice: PurchaseregisterService,
    private toastr: ToastrService,
    private globalService: GlobalService,
    private menuService: MenuService,
    private localeService: BsLocaleService,

  ) {
    this.errorMessage = "";
    this.filesToUpload = [];
    this.selectedFileNames = [];
    this.uploadResult = "";
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    defineLocale('en-gb', enGbLocale);
    this.localeService.use('en-gb');

  }

  ngOnInit() {
    this.menuService.ShowRegionMenu(true);
    this.purchaseregisterservice.regionchangesubject.subscribe((data: any) => {
      this.regionId = data;
      this.LoadGoodsInBranch(data);
      this.shopList = [];
    });
    this.globalService.getUserInfo().subscribe((data: any) => {
      if (data != undefined) {
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globalporegistrationpage);
        
        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {
          //region management

          if (this.globalService.globalRegionId != undefined) {
            this.regionId = parseInt(this.globalService.globalRegionId);
          }
          else {
            this.regionId = data.Region_Id;
          }
          this.LoadGoodsInBranch(this.regionId);
          this.LoadBuyer();
          this.LoadOrderType();
          this.LoadSupplier();
          this.LoadPoSupplier();
          this.LoadProductGroup();
          this.LoadCurrency();
          this.LoadStatisticalPeriod();
          this.LoadSubOrderType();
          this.LoadIncoterm();
          this.LoadCountryOfDelivery();
          this.ClearRegisterFields();
          this.ClearBuyersFields();
          this.ClearContactsFields();
          this.shopList = [];
        }
        else {
          this.showpurchaseregister = false;
          this.showpurchaseregisteralert = true;
        }

      }
    });
    this.menuService.HideUnitMenu(false);
    this.menuService.HideChart(false);
    $('#DeliveryFromTo').daterangepicker();

  }

  OnChangeOrderType(selectedvalue): any {

    if (selectedvalue != "") {
      if (selectedvalue == "2")
        this.showinternalpo = true;
      else {
        this.showinternalpo = false;
        this.register.InternalPONo = "";
      }

    }
    else {
      this.showinternalpo = false;
      this.register.InternalPONo = "";
    }

  }
  ClearFields(): void {
    this.ClearRegisterFields();
    this.ClearBuyersFields();
    this.ClearContactsFields();
  }

  ClearRegisterFields(): void {
    this.register = {
      ContactPhone: "",
      Markup: "",
      FrieghtCost: "",
      OrderNumber: "",
      Pieces: "",
      Value: "",
      ProductGroupId: "",
      CurrencyId: "",
      Discount: "",
      StatPeriodId: "",
      Comment: "",
      BuyerId: "",
      OrderTypeId: "",
      GoodsInBranchId: "",
      ShopId: "",
      SupplierId: "",
      POSupplierId: "",
      ContactId: "",
      SubOrderTypeId: 1,
      IncoTermId: "",
      CountryId: "",
      AlterationNecessary: 0,
      ContactEmail: "",
      InternalPOnr: "",
      DeliveryFromTo: [this.bsValue, this.maxDate]
    };
    this.NetValue = "";
  }
  ClearBuyersFields(): void {
    this.buyers = {
      BuyerFirstName: "",
      BuyerLastName: "",
    };
  }
  ClearContactsFields(): void {
    this.contacts = {
      ContactName: "",
      PhoneNumber: "",
      ContactEmail: "",
      ContactPOSupplier: "",
    };
  }
  LoadBuyer(): any {
    this.purchaseregisterservice.getBuyer().subscribe((data: any) => {
      this.buyerList = [];
      this.buyerList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadOrderType(): any {
    this.purchaseregisterservice.getOrderType().subscribe((data: any) => {
      this.orderTypeList = [];
      this.orderTypeList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadGoodsInBranch(regionId): any {

    this.purchaseregisterservice.getGoodsInBranch(regionId).subscribe((data: any) => {
      this.goodsInBranchList = [];
      this.goodsInBranchList = data;
      this.register['GoodsInBranchId'] = "";
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadShop(regionId, goodsInBranchId): any {

    this.purchaseregisterservice.getShop(regionId, goodsInBranchId).subscribe((data: any) => {
      this.shopList = [];
      this.shopList = data;
      this.register['ShopId'] = "";
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadSupplier(): any {
    this.purchaseregisterservice.getSupplier().subscribe((data: any) => {

      this.supplierList = [];
      this.supplierList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadPoSupplier(): any {
    this.purchaseregisterservice.getPOSupplier().subscribe((data: any) => {
      this.posupplierList = [];
      this.posupplierList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadContact(posupplierid): any {
    
    this.purchaseregisterservice.getContact(posupplierid).subscribe((data: any) => {
      this.contactList = [];
      if (data.length == 0) {
        this.register.ContactId = "";
        this.register.ContactPhone = "";
        this.register.ContactEmail = "";
      }
      else {
        this.contactList = data;
      }
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadProductGroup(): any {
    this.purchaseregisterservice.getProductGroup().subscribe((data: any) => {
      this.productgroupList = [];
      this.productgroupList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadCurrency(): any {
    this.purchaseregisterservice.getCurrency().subscribe((data: any) => {
      this.currencyList = [];
      this.currencyList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadSubOrderType(): any {
    this.purchaseregisterservice.getSubOrderType().subscribe((data: any) => {
      this.subordertypeList = [];
      this.subordertypeList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadStatisticalPeriod(): any {
    this.purchaseregisterservice.getStatisticalPeriod().subscribe((data: any) => {
      this.statisticalPeriodList = [];
      this.statisticalPeriodList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadIncoterm(): any {
    this.purchaseregisterservice.getIncoTerm().subscribe((data: any) => {
      this.incotermList = [];
      this.incotermList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadCountryOfDelivery(): any {
    this.purchaseregisterservice.getCountryDelivery().subscribe((data: any) => {
      this.countryDeliveryList = [];
      this.countryDeliveryList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  ChangeGoodsInBranch(selectedvalue): any {
    if (selectedvalue.target.value != "") {
      this.LoadShop(this.regionId, selectedvalue.target.value);
    }
    else
      this.shopList = [];
  }
  ChangePOSupplier(selectedvalue): any {
    
    if (selectedvalue != undefined) {
      if (selectedvalue != "")
        this.LoadContact(selectedvalue);
      else
        this.contactList = [];
    }
  }
  ChangeContacts(selectedvalue): any {

    if (selectedvalue != "") {

      this.filteredContact = Object.assign([], this.contactList);
      var info = this.filteredContact.find(x => x.ContactId == selectedvalue)
      this.register.ContactPhone = info.PhoneNumber;
      this.register.ContactEmail = info.ContactEmail;
    }
    else
      this.register.ContactPhone = "";
  }
  onSubmit(form: NgForm): any {

    if (this.filesToUpload.length < 6) {
      this.uploadResult = "";
      let formData: FormData = new FormData();
      for (var i = 0; i < this.filesToUpload.length; i++) {
        formData.append('uploadedFiles', this.filesToUpload[i], this.filesToUpload[i].name);
      }
      form['AlterationNecessary'] = parseInt(form['AlterationNecessary']);
      this.purchaseregisterservice.SavePurchaseRegister(form).subscribe((data: any) => {

        formData.append('orderid', data);
        this.purchaseregisterservice.SaveFiles(formData).subscribe((data: any) => {
          this.toastr.success(data);
          this.showinternalpo = false;
          this.register = {
            BuyerId: form['BuyerId'],
            OrderTypeId: form['OrderTypeId'],
            GoodsInBranchId: form['GoodsInBranchId'],
            ShopId: form['ShopId'],
            SupplierId: form['SupplierId'],
            POSupplierId: form['POSupplierId'],
            ContactId: form['ContactId'],
            SubOrderTypeId: form['SubOrderTypeId'],
            IncoTermId: form['IncoTermId'],
            CountryId: form["CountryId"],
            AlterationNecessary: parseInt(form['AlterationNecessary']),
            ContactEmail: form['ContactEmail'],
            InternalPOnr: "",
            DeliveryFromTo: [form['DeliveryFromTo'][0], form['DeliveryFromTo'][1]],
            ContactPhone: form['ContactPhone'],
            Markup: form['Markup'],
            FrieghtCost: form['FrieghtCost'],
            OrderNumber: "",
            Pieces: "",
            Value: "",
            ProductGroupId: "",
            CurrencyId: "",
            Discount: "",
            StatPeriodId: "",
            Comment: "",
          };
          this.NetValue = "";
          this.buyers = {
            BuyerFirstName: "",
            BuyerLastName: "",
          }
          this.contacts = {
            ContactName: "",
            PhoneNumber: "",
            ContactEmail: "",
            ContactPOSupplier: "",
          }
          this.OnChangeOrderType(form['OrderTypeId']);
        },
          (err: HttpErrorResponse) => {
            this.toastr.error(err.error);
          });
      },
        (err: HttpErrorResponse) => {
          this.toastr.error(err.error);
          form['AlterationNecessary'] = 0;
          //this.ClearRegisterFields();
        });

    }
    else {
      this.toastr.warning("File upload exceeded more then 5.");
    }

  }

  checkValue(isChecked) {

    if (this.isChecked) {
      this.valueLabel = "Net Value";
      this.checkboxlabel = "Gross Value";
      this.OnChangeDiscount();
      this.OnChangeValue();
    }
    else {
      this.valueLabel = "Gross Value";
      this.checkboxlabel = "Net Value";
      this.OnChangeDiscount();
      this.OnChangeValue();
    }

  }

  ConvertDateTime(str) {
    var mnths = {
      Jan: "01", Feb: "02", Mar: "03", Apr: "04", May: "05", Jun: "06",
      Jul: "07", Aug: "08", Sep: "09", Oct: "10", Nov: "11", Dec: "12"
    },
      date = str.split(" ");
    return [date[3], mnths[date[1]], date[2]].join("/");
  }


  onBuyersSubmit(form: NgForm): any {
    this.purchaseregisterservice.SaveBuyers(form).subscribe((data: any) => {
      this.toastr.success("Successfully Saved Buyer");

      this.ClearBuyersFields();
      this.LoadBuyer();
      this.register.BuyerId = data;
      $('#modal-buyer').modal('hide');
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  onContactsSubmit(form: NgForm): any {
    this.purchaseregisterservice.SaveContacts(form).subscribe((data: any) => {
      this.toastr.success("Successfully Saved Contact");

      this.register.POSupplierId = this.contacts.ContactPOSupplier;
      this.purchaseregisterservice.getContact(this.contacts.ContactPOSupplier).subscribe((result: any) => {
        this.contactList = result;

        this.register.ContactId = data;
        this.ChangeContacts(data);
      },
        (err: HttpErrorResponse) => {
          this.toastr.error(err.error);
        });


      $('#modal-contact').modal('hide');
      this.ClearContactsFields();
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  ClearBuyers(): any {
    this.ClearBuyersFields();
  }
  ClearContacts(): any {
    this.ClearContactsFields();
  };

  upload() {
    if (this.filesToUpload.length > 5) {
      this.toastr.warning('Please select a maximum of 5 PDF files to upload!');
    }
  }

  fileChangeEvent(fileInput: any) {
    this.uploadResult = "";
    this.filesToUpload = <Array<File>>fileInput.target.files;
    for (let i = 0; i < this.filesToUpload.length; i++) {
      this.selectedFileNames.push(this.filesToUpload[i].name);
    }
  }
  OnChangeDiscount(): void {

    var replacedvalue: number;
    var replaceddiscount: number;
    if (!this.isChecked) {
      if (this.register.Value && this.register.Discount) {
        if (this.register.Value.includes(',')) {
          replacedvalue = this.register.Value.replace(',', '.');
        }
        else {
          replacedvalue = this.register.Value;
        }
        if (this.register.Discount.includes(',')) {
          replaceddiscount = this.register.Discount.replace(',', '.');
        }
        else {
          replaceddiscount = this.register.Discount;
        }
        var netvalue = ((replacedvalue * replaceddiscount) / 100);
        this.NetValue = "Net Value: " + (replacedvalue - netvalue).toFixed(2).toString();
      }
      else
        this.NetValue = "";
    }
    else {
      if (this.register.Value && this.register.Discount) {
        if (this.register.Value.includes(',')) {
          replacedvalue = this.register.Value.replace(',', '.');
        }
        else {
          replacedvalue = this.register.Value;
        }
        if (this.register.Discount.includes(',')) {
          replaceddiscount = this.register.Discount.replace(',', '.');
        }
        else {
          replaceddiscount = this.register.Discount;
        }
        
        var netvalue = ((replacedvalue * replaceddiscount) / 100);
        let a = +replacedvalue + +netvalue;
        this.NetValue = "Gross Value: " + a.toFixed(2).toString();
        
      }
      else
        this.NetValue = "";
    }

  }
  OnChangeValue(): void {

    var replacedvalue: number;
    var replaceddiscount: number;
    if (!this.isChecked) {
      if (this.register.Value && this.register.Discount) {
        if (this.register.Value.includes(',')) {
          replacedvalue = this.register.Value.replace(',', '.');
        }
        else {
          replacedvalue = this.register.Value;
        }
        if (this.register.Discount.includes(',')) {
          replaceddiscount = this.register.Discount.replace(',', '.');
        }
        else {
          replaceddiscount = this.register.Discount;
        }
        var netvalue = ((replacedvalue * replaceddiscount) / 100);
        this.NetValue = "Net Value: " + (replacedvalue - netvalue).toFixed(2).toString();

      }
      else
        this.NetValue = "";
    }
    else {
      if (this.register.Value && this.register.Discount) {
        if (this.register.Value.includes(',')) {
          replacedvalue = this.register.Value.replace(',', '.');
        }
        else {
          replacedvalue = this.register.Value;
        }
        if (this.register.Discount.includes(',')) {
          replaceddiscount = this.register.Discount.replace(',', '.');
        }
        else {
          replaceddiscount = this.register.Discount;
        }
        
        //var netvalue = ((replacedvalue * replaceddiscount) / 100);
        var netvalue = ((replacedvalue * replaceddiscount) / 100);
        let a = +replacedvalue + +netvalue;
        this.NetValue = "Gross Value: " + a.toFixed(2).toString();
        
      }
      else
        this.NetValue = "";
    }
  }
}


