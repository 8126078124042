import { Component, OnInit } from '@angular/core';
import { UserService } from '../service/user.service';
import { GlobalService } from '../service/global.service';
import { AccountService } from '../service/account.service';
import { ToastrService } from 'ngx-toastr';
import { IdentityClaims } from '../model/account.model';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, Validators, FormBuilder, NgForm, FormControl } from '@angular/forms';
import { MenuService } from '../service/menu.service';
declare var $;
@Component({
  selector: 'app-useroverview',
  templateUrl: './useroverview.component.html',
  styleUrls: ['./useroverview.component.css']
})
export class UseroverviewComponent implements OnInit {
  userOverviewForm: FormGroup;
  submitted = false;
  unitId: number;
  userList: any;
  userid: string = '';
  employeeName: string = '';
  unitName: string = '';
  regionName: string = '';
  activeState: string = '';
  showuseroverview: boolean = true;
  showuseroverviewalert: boolean = false;
  userRoles: any;
  constructor(
    private userService: UserService,
    private globalService: GlobalService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private toastr: ToastrService,
    private menuService: MenuService) {
    this.userOverviewForm = this.formBuilder.group({
      User_Id: ['', Validators.required],
      NewPassword: ['', [Validators.required, Validators.minLength(6)]],
      ConfirmNewPassword: ['', Validators.required],
      UserPassword: ['', Validators.required],
    }, {
        validator: RegistrationValidator.validate.bind(this)
      });
  }

  ngOnInit() {
    this.menuService.HideUnitMenu(true);
    this.menuService.HideChart(false);
    this.menuService.ShowRegionMenu(true);
    this.globalService.UserClaims().subscribe((data: any) => {
      if (data != undefined) {
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globaluseroverviewpage);
        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {
          this.unitId = this.accountService.globalUnitId;
          if (this.unitId == undefined) {
            this.unitId = data.Unit_Id;
          }
          this.LoadUsers();
          this.userOverviewForm.controls['User_Id'].setValue(0);
        }
        else {
          this.showuseroverview = false;
          this.showuseroverviewalert = true;
        }
      }
    });
  }
  ClearFields(): void {
    this.userOverviewForm.controls['User_Id'].setValue(0);
    this.userOverviewForm.controls['NewPassword'].setValue('');
    this.userOverviewForm.controls['ConfirmNewPassword'].setValue('');
    this.userOverviewForm.controls['UserPassword'].setValue('');
    this.employeeName = '';
    this.unitName = '';
    this.regionName = '';
    this.activeState = '';
  }

  LoadUsers(): any {
    this.userService.GetAllUserList().subscribe((data: any) => {
      this.userList = [];
      this.userList = data;

    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  OnUserChange(items): any {
    
    if (parseInt(items) != 0) {
      var usergroup = this.userList.find(record => record.User_Id === parseInt(items));
      this.employeeName = 'Employee: ' + usergroup.Employee_Name;
      this.regionName = 'Region: ' + usergroup.Region_Name;
      this.unitName = 'Unit: ' + usergroup.Unit_Name;
      this.activeState = 'Active: ' + usergroup.Active;
    }
    else {
      this.employeeName = '';
      this.unitName = '';
      this.regionName = '';
      this.activeState = '';
    }
  }

  get f() { return this.userOverviewForm.controls; }

  onSubmit(form: FormGroup) {
    this.submitted = true;
    if (this.userOverviewForm.invalid) {
      return;
    }
    form = new FormGroup({
      User_Id: new FormControl(form.value.User_Id, Validators.required),
      NewPassword: new FormControl(form.value.NewPassword, Validators.required),
      ConfirmNewPassword: new FormControl(form.value.ConfirmNewPassword, Validators.required),
      UserPassword: new FormControl(form.value.UserPassword, Validators.required),
    });
    this.userService.SaveUserOverview(form.value).subscribe((data: any) => {
      this.toastr.success(data);
      this.LoadUsers();
      this.ClearFields();
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
}
export class RegistrationValidator {
  static validate(userPreferenceForm: FormGroup) {
    let password = userPreferenceForm.controls.NewPassword.value;
    let repeatPassword = userPreferenceForm.controls.ConfirmNewPassword.value;
    if (repeatPassword.length <= 0) {
      return null;
    }
    if (repeatPassword !== password) {
      return {
        doesMatchPassword: true
      };
    }
    return null;

  }
}
