import { Component, OnInit } from '@angular/core';
import { getFullYear } from 'ngx-bootstrap/chronos/public_api';

@Component({
  selector: 'app-appfooter',
  templateUrl: './appfooter.component.html',
  styleUrls: ['./appfooter.component.css']
})
export class AppfooterComponent implements OnInit {
  year: number = new Date().getFullYear();
  constructor() { }

  ngOnInit() {
  
  }

}
